import {
    Backup,
    ResponsibleState,
    ViewState
} from './manage-responsibles.componente-interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ResponsibleService} from '@services/responsible.service';
import {Component, OnInit, EventEmitter} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {ExcelService} from '@services/excel.service';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-manage-responsibles',
    templateUrl: './manage-responsibles.component.html',
    styleUrls: ['./manage-responsibles.component.scss']
})
export class ManageResponsiblesComponent implements OnInit {
    emitEvent = new EventEmitter();
    viewState: ViewState = {
        showModal: false,
        showEdit: false,
        showDelete: false,
        showEditInfo: false
    };

    backup: Backup = {
        id_responsible: '',
        name: '',
        id_type: '',
        nit: '',
        email: '',
        city: '',
        address: '',
        phone: ''
    };

    responsibleState: ResponsibleState = {
        responsibles: [],
        responsibles_count: 0,
        responsible_selected: '',
        responsiblesForm: '',
        id_responsible: ''
    };

    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    isReset = false;

    responsiblesModel: TableViewModel = {
        title: 'Responsables',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'fullname', filter: false, data: []},
            {title: 'NIT', key: 'doc_number', filter: false, data: []},
            {title: 'Direccion', key: 'address', filter: false, data: []},
            {title: 'Telefono', key: 'phone', filter: false, data: []}
        ],
        records: [],
        showFilters: false,
        enableActions: true
    };

    constructor(
        private appService: AppService,
        private responsibleService: ResponsibleService,
        private md1: SwitchService,
        private excelService: ExcelService,
        private ts: ToastrService
    ) {}

    ngOnInit(): void {
        this.getResponsibles();
        this.initForm();
        this.subscribeToModalEvents();
    }

    initForm(): void {
        this.responsibleState.responsiblesForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            id_type: new FormControl(null, Validators.required),
            nit: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required)
        });
    }

    subscribeToModalEvents(): void {
        this.md1.$modal.subscribe(
            (value) => (this.viewState.showModal = value)
        );
        this.md1.EditInfo.subscribe(
            (value) => (this.viewState.showEditInfo = value)
        );
    }

    private resetState(): void {
        this.viewState.showModal = false;
        this.viewState.showEdit = false;
        this.viewState.showDelete = false;
        this.viewState.showEditInfo = false;
        this.backup = {};
        this.responsibleState.id_responsible = null;
        this.isReset = true;
    }

    getResponsibles(currentPage: number = this.currentPage) {
        this.appService
            .getResponsiblesFuecs(currentPage, this.pageSize, this.searchby)
            .then((responsibles) => {
                if (responsibles.result) {
                    this.responsibleState.responsibles = responsibles.result;
                    this.responsibleState.responsibles_count =
                        responsibles.total_count;
                    this.responsiblesModel.records =
                        this.responsibleState.responsibles;
                    this.responsiblesModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: responsibles.count,
                        rowCount: this.responsibleState.responsibles_count
                    };
                }
            });
    }

    getTotalResponsibles(header: any) {
        this.appService.getResponsiblesFuecs().then(async (responsibles) => {
            if (responsibles.result) {
                this.responsiblesModel.totalRecords = responsibles.result;
                var data = {
                    title: header.title,
                    header: header.header,
                    data: []
                };
                for (let record of responsibles.result) {
                    const row = header.keys.map((key) => record[key]);
                    data.data.push(row);
                }
                await this.excelService.generateExcel(data);
            }
        });
    }

    // EDIT RESPONSIBLE

    showDetailInfo(info) {
        if (!this.viewState.showModal) {
            this.md1.$modal.emit(true);
        }
        this.viewState.showDelete = false;
        this.viewState.showEdit = true;
        this.responsibleState.id_responsible = info.id;
        this.responsibleState.responsible_selected = info;
        this.responsibleState.responsiblesForm.patchValue({
            name: info.fullname,
            id_type: info.id_type,
            nit: info.doc_number,
            email: info.email,
            city: info.city,
            address: info.address,
            phone: info.phone
        });
        this.backup = {
            id_responsible: info.id,
            name: info.fullname,
            id_type: info.id_type,
            nit: info.doc_number,
            email: info.email,
            city: info.city,
            address: info.address,
            phone: info.phone
        };
    }

    showDetailInfoEdit(): void {
        if (this.responsibleState.responsiblesForm.valid) {
            this.viewState.showEditInfo = !this.viewState.showEditInfo;
            this.md1.EditInfo.emit(this.viewState.showEditInfo);
        } else {
            this.ts.error('Debe llenar todos los campos');
        }
    }

    editResponsible() {
        if (this.responsibleState.responsiblesForm.valid) {
            var params = {
                id: this.responsibleState.id_responsible,
                id_type: this.responsibleState.responsiblesForm.value.id_type,
                id_document: this.responsibleState.responsiblesForm.value.nit,
                name: this.responsibleState.responsiblesForm.value.name,
                email: this.responsibleState.responsiblesForm.value.email,
                address: this.responsibleState.responsiblesForm.value.address,
                city: this.responsibleState.responsiblesForm.value.city,
                phone: this.responsibleState.responsiblesForm.value.phone
            };

            this.responsibleService
                .updateFuecBackupResponsible(this.backup)
                .then((response) => {
                    if (response.msg) {
                        console.log(response.msg);
                    } else if (response.error) {
                        console.log(response.error);
                    }
                });

            this.responsibleService
                .updateResponsible(params)
                .then((contractor) => {
                    if (contractor.msg) {
                        this.ts.success('El responsable se edito con éxito');
                        this.emitEvent.emit();
                    } else {
                        this.ts.error(
                            'Lo siento, hubo un problema en la creacion del contratista'
                        );
                    }
                    this.getResponsibles();
                    this.resetState();
                });
        }
    }

    // REMOVE RESPONSIBLE

    showDetailInfoDelete(info) {
        if (this.viewState.showModal) {
            this.md1.$modal.emit(false);
            this.viewState.showDelete = false;
            this.backup = {};
        } else {
            this.responsibleState.id_responsible = info.id;
            this.md1.$modal.emit(true);
            this.viewState.showDelete = true;
            this.viewState.showEdit = false;
            this.backup = {
                id_responsible: info.id,
                name: info.fullname,
                id_type: info.id_type,
                nit: info.doc_number,
                email: info.email,
                city: info.city,
                address: info.address,
                phone: info.phone
            };
        }
    }

    cancelDelete() {
        this.resetState();
    }

    onRemoveResponsible() {
        this.responsibleService
            .updateFuecBackupResponsible(this.backup)
            .then((response) => {
                if (response.msg) {
                    console.log(response.msg);
                } else if (response.error) {
                    console.error(response.error);
                    return Promise.reject(response.error);
                }
                return this.responsibleService.deleteResponsible(
                    this.responsibleState.id_responsible
                );
            })
            .then(() => {
                this.getResponsibles();
                this.md1.$modal.emit(false);
                this.viewState.showDelete = false;
                this.responsibleState.id_responsible = null;
                this.ts.success('El responsable fue eliminado con exito');
                this.resetState();
            })
            .catch((error) => {
                console.error('Error al eliminar responsable:', error);
            });
    }

    // FILTERS

    paginatorEvent(currentPage: number) {
        this.getResponsibles(currentPage);
        this.isReset = false;
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
}
