<!--Esta es la gradación de fondo-->
<div class="bg-gradient">
    <div class="bg-photo">
        <!--Esta es la fotografía de fondo con transparencia-->
    </div>

    <div class="bg-gradient-2">
        <!--Esta es la gradación vetical para dar legibilidad al logo-->
    </div>

    <div class="logo-motion">
        <!--Este es el logo-->
        <a href="https://www.monitoringinnovation.com" target="_blank" class="brand-image" style="opacity: 0.8">
            <span [inlineSVG]="'assets/images/motion-fuec-icon_negative.svg'"
                class="svg-icon svg-icon-md svg-icon-warning"></span>
        </a>
    </div>

    <div>
        <form method="post" [formGroup]="loginForm" class="form" (submit)="loginByAuth()">
            <!--Esta es la tarjeta para el login-->

            <div class="input-div">
                <label>Usuario*</label>
                <input maxlength="40" type="text" name="email" placeholder="Nombre de usuario"
                    formControlName="email" />
            </div>

            <div class="input-div">
                <label>Clave de acceso*</label>
                <input minlength="6" type="password" name="Contraseña" placeholder="Contraseña"
                    formControlName="password" />
            </div>

            <div class="form-selections">
                <!--Esta es la parte inferior del login-->

                <a class="red">He olvidado mi contraseña</a><br />

                <button class="btn3" type="submit" name="Ingresar">
                    Iniciar sesión</button><br />

                <a class="red">Registrarme</a>
            </div>
        </form>
    </div>

    <div class="copyright">
        <a class="dark white" href="#"><span class="white90">&copy; {{currentYear}} Copyright / </span> A.B Comercial
            LTDA</a>
    </div>
</div>