import {
    Backup,
    ContractorState,
    ViewState
} from './manage-contractors.component-interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContractorService} from '@services/contractor.service';
import {Component, OnInit, EventEmitter} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {ExcelService} from '@services/excel.service';
import {FuecService} from '@services/fuec.service';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-manage-contractors',
    templateUrl: './manage-contractors.component.html',
    styleUrls: ['./manage-contractors.component.scss']
})
export class ManageContractorsComponent implements OnInit {
    emitEvent = new EventEmitter();
    viewState: ViewState = {
        showModal: false,
        showEdit: false,
        showDelete: false,
        showEditInfo: false
    };

    backup: Backup = {
        id_contract: '',
        company_name: '',
        nit: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        city: ''
    };

    contractorState: ContractorState = {
        contractors: [],
        contractors_count: 0,
        contractorsForm: FormGroup,
        id_contractor: 0
    };

    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    isReset = false;

    contractorsModel: TableViewModel = {
        title: 'Contratistas',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'fullname', filter: false, data: []},
            {title: 'NIT', key: 'doc_number', filter: false, data: []},
            {title: 'Direccion', key: 'address', filter: false, data: []},
            {title: 'Telefono', key: 'phone', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };

    constructor(
        private appService: AppService,
        private md1: SwitchService,
        private excelService: ExcelService,
        private ts: ToastrService,
        private contractorService: ContractorService
    ) {}

    ngOnInit(): void {
        this.getContractors();
        this.initForm();
        this.subscribeToModalEvents();
    }

    initForm(): void {
        this.contractorState.contractorsForm = new FormGroup({
            company_name: new FormControl(null, Validators.required),
            nit: new FormControl(null, Validators.required),
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required)
        });
    }

    subscribeToModalEvents(): void {
        this.md1.$modal.subscribe(
            (value) => (this.viewState.showModal = value)
        );
        this.md1.EditInfo.subscribe(
            (value) => (this.viewState.showEditInfo = value)
        );
    }

    private resetState(): void {
        this.viewState.showModal = false;
        this.viewState.showEdit = false;
        this.viewState.showDelete = false;
        this.viewState.showEditInfo = false;
        this.backup = {};
        this.contractorState.id_contractor = null;
        this.isReset = true;
    }

    getContractors(currentPage: number = this.currentPage) {
        this.appService
            .getContractorsFuecs(currentPage, this.pageSize, this.searchby)
            .then((contractors) => {
                if (contractors.result) {
                    this.contractorState.contractors = contractors.result;
                    this.contractorState.contractors_count =
                        contractors.total_count;
                    this.contractorsModel.records =
                        this.contractorState.contractors;
                    this.contractorsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: contractors.count,
                        rowCount: this.contractorState.contractors_count
                    };
                }
            });
    }

    getTotalContractors(header: any) {
        this.appService.getContractorsFuecs().then(async (contractors) => {
            if (contractors.result) {
                this.contractorsModel.totalRecords = contractors.result;
                var data = {
                    title: header.title,
                    header: header.header,
                    data: []
                };
                for (let record of contractors.result) {
                    const row = header.keys.map((key) => record[key]);
                    data.data.push(row);
                }
                await this.excelService.generateExcel(data);
            }
        });
    }

    // EDIT CONTRACTOR

    showDetailInfo(info) {
        if (!this.viewState.showModal) {
            this.md1.$modal.emit(true);
        }
        this.viewState.showDelete = false;
        this.viewState.showEdit = true;
        this.contractorState.id_contractor = info.id;
        this.contractorState.contractorsForm.patchValue({
            company_name: info.company_name,
            name: info.fullname,
            email: info.email,
            phone: info.phone,
            address: info.address,
            city: info.city,
            nit: info.doc_number
        });

        this.backup = {
            id_contract: info.id,
            company_name: info.company_name,
            nit: info.doc_number,
            name: info.fullname,
            email: info.email,
            phone: info.phone,
            address: info.address,
            city: info.city
        };
    }

    showDetailInfoEdit() {
        if (this.contractorState.contractorsForm.valid) {
            this.viewState.showEditInfo = !this.viewState.showEditInfo;
            this.md1.EditInfo.emit(this.viewState.showEditInfo);
        } else {
            this.ts.error('Debe llenar todos los campos');
        }
    }

    editContract() {
        if (this.contractorState.contractorsForm.valid) {
            var params = {
                id: this.contractorState.id_contractor,
                company_name:
                    this.contractorState.contractorsForm.value.company_name,
                nit: this.contractorState.contractorsForm.value.nit,
                name: this.contractorState.contractorsForm.value.name,
                email: this.contractorState.contractorsForm.value.email,
                address: this.contractorState.contractorsForm.value.address,
                city: this.contractorState.contractorsForm.value.city,
                phone: this.contractorState.contractorsForm.value.phone
            };

            this.contractorService
                .updateFuecBackupContractor(this.backup)
                .then((response) => {
                    if (response.msg) {
                        console.log(response.msg);
                    } else if (response.error) {
                        console.log(response.error);
                    }
                });

            this.contractorService
                .updateContractor(params)
                .then((contractor) => {
                    if (contractor.msg) {
                        this.ts.success('El contratista se edito con éxito');
                    } else {
                        this.ts.error(
                            'Lo siento, hubo un problema en la creacion del contratista'
                        );
                    }
                    this.getContractors();
                    this.resetState();
                });
        }
    }

    // REMOVE CONTRACTOR

    showDetailInfoDelete(info) {
        if (this.viewState.showModal) {
            this.md1.$modal.emit(false);
            this.viewState.showDelete = false;
            this.backup = {};
        } else {
            this.contractorState.id_contractor = info.id;
            this.md1.$modal.emit(true);
            this.viewState.showDelete = true;
            this.viewState.showEdit = false;
            this.backup = {
                id_contract: info.id,
                company_name: info.company_name,
                nit: info.doc_number,
                name: info.fullname,
                email: info.email,
                phone: info.phone,
                address: info.address,
                city: info.city
            };
        }
    }

    cancelDelete() {
        this.resetState();
    }

    onRemoveContractor() {
        this.contractorService
            .updateFuecBackupContractor(this.backup)
            .then((response) => {
                if (response.msg) {
                    console.log(response.msg);
                } else if (response.error) {
                    console.error(response.error);
                    return Promise.reject(response.error);
                }
                return this.contractorService.deleteContractor(
                    this.contractorState.id_contractor
                );
            })
            .then(() => {
                this.getContractors();
                this.md1.$modal.emit(false);
                this.viewState.showDelete = false;
                this.contractorState.id_contractor = null;
                this.ts.success('El contratista fue eliminado con éxito');
                this.resetState();
            })
            .catch((error) => {
                console.error('Error al eliminar contratista:', error);
            });
    }

    // FILTERS

    paginatorEvent(currentPage: number) {
        this.getContractors(currentPage);
        this.isReset = false;
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
}
