<header class="content-wrapper">
    <div class="grid-4col header-container">
        <div *ngIf="user" class="user-data-header">
            <h2 class="s-subtitle blue1 bold-font">{{ user.name }}</h2>
            <h3 class="xs-subtitle gray3">{{ user.username }}</h3>
        </div>

        <div class="input-archivo">
            <label for="certificado" *ngIf="account">
                <img
                    *ngIf="!account.iso_img"
                    src="assets/img/ISO-9001.png"
                    class="header-logo"
                />
                <img
                    *ngIf="account.iso_img"
                    src="{{ 'data:image/jpg;base64,' + account.iso_img }}"
                    class="input-logo"
                />
            </label>
            <input
                id="certificado"
                type="file"
                (change)="handleUploadIso($event)"
            />
        </div>

        <a href="https://www.monitoringinnovation.com" target="_blank" class="motion-icon-header">
            <svg
                height="7vh"
                version="1.1"
                id="motion-fuec_logo"
                class="motion-fuec_logo"
                x="0px"
                y="0px"
                viewBox="0 0 1000 233"
                style="enable-background: new 0 0 1000 233"
                xml:space="preserve"
            >
                <g>
                    <g>
                        <path
                            class="st0"
                            d="M966.4,42.8L927.2,3.6c-2.2-2.2-5.1-3.4-8.2-3.4H809.3c-6.4,0-11.6,5.2-11.6,11.6v114h14V14.2H914v30.2
                            c0,6.4,5.2,11.6,11.6,11.6h30.2v69.8h14V51C969.8,47.9,968.6,45,966.4,42.8z M927.9,42.1V24l18,18H927.9z"
                        />

                        <linearGradient
                            id="SVGID_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="962.5872"
                            y1="-33.4594"
                            x2="804.8546"
                            y2="124.2733"
                            gradientTransform="matrix(1 0 0 -1 0 234)"
                        >
                            <stop offset="0" style="stop-color: #c6007e" />
                            <stop offset="1" style="stop-color: #d4409e" />
                        </linearGradient>
                        <path
                            class="st1"
                            d="M995.3,232.8H772.1c-2.6,0-4.7-2.1-4.7-4.7v-79.1c0-2.6,2.1-4.7,4.7-4.7h223.3c2.6,0,4.7,2.1,4.7,4.7v79.1
                            C1000,230.7,997.9,232.8,995.3,232.8z"
                        />
                        <g>
                            <path
                                class="st2"
                                d="M780.2,167.2v54h14v-25.6h25.6v-14h-25.6v-12.1h25.6v-14h-27.9C785.4,155.6,780.2,160.8,780.2,167.2z"
                            />
                            <path
                                class="st2"
                                d="M894.2,167.2V210c0,6.4,5.2,11.6,11.6,11.6h27.9v-14h-25.6v-12.1h25.6v-14h-25.6v-12.1h25.6v-14h-27.9
                                C899.4,155.6,894.2,160.8,894.2,167.2z"
                            />
                            <path
                                class="st2"
                                d="M987.2,169.5v-14h-27.9c-6.4,0-11.6,5.2-11.6,11.6V210c0,6.4,5.2,11.6,11.6,11.6h27.9v-14h-25.6v-38.1H987.2
                                z"
                            />
                            <path
                                class="st2"
                                d="M866.3,207.7h-18.6V156h-14v54c0,6.4,5.2,11.6,11.6,11.6h23.3c6.4,0,11.6-5.2,11.6-11.6v-54h-14V207.7z"
                            />
                        </g>
                        <rect
                            x="827.9"
                            y="37.4"
                            class="st3"
                            width="67.4"
                            height="14"
                        />
                        <rect
                            x="827.9"
                            y="74.7"
                            class="st3"
                            width="111.6"
                            height="14"
                        />
                        <rect
                            x="827.9"
                            y="111.8"
                            class="st3"
                            width="111.6"
                            height="14"
                        />
                    </g>
                    <g>
                        <linearGradient
                            id="SVGID_2_"
                            gradientUnits="userSpaceOnUse"
                            x1="290.6978"
                            y1="165.3701"
                            x2="290.6978"
                            y2="46.7654"
                        >
                            <stop offset="0" style="stop-color: #0047bb" />
                            <stop offset="0.8" style="stop-color: #01bedb" />
                        </linearGradient>
                        <path
                            class="st4"
                            d="M162.8,128.2V163h-23.3v-34.9c0-19.3-15.6-34.9-34.9-34.9c7.3,9.7,11.6,21.8,11.6,34.9V163H93v-34.9
                            c0-10.3-4.5-19.6-11.6-26c-6.2-5.5-14.3-8.9-23.3-8.9h0c-19.3,0-34.9,15.6-34.9,34.9V163H0v-34.9C0,96,26,70,58.1,70
                            c8.3,0,16.1,1.7,23.3,4.8c7.1-3.1,15-4.8,23.3-4.8C136.8,70,162.8,96,162.8,128.2z M348.8,81.6V163h23.3V81.6
                            C364.4,83.2,356.5,83.2,348.8,81.6z M348.8,46.8v22.9c7.6,2,15.6,2,23.3,0V46.8H348.8z M534.9,70c-25.7,0-46.5,20.8-46.5,46.5V163
                            h23.3v-46.5c0-12.8,10.4-23.3,23.3-23.3c12.8,0,23.3,10.4,23.3,23.3V163h23.3v-46.5C581.4,90.8,560.6,70,534.9,70z M477.9,117.7
                            c0,26.3-21.3,47.7-47.7,47.7c-26.3,0-47.7-21.3-47.7-47.7c0-26.3,21.3-47.7,47.7-47.7C456.6,70,477.9,91.4,477.9,117.7z
                            M454.6,117.7c0-13.5-10.9-24.4-24.4-24.4c-13.5,0-24.4,10.9-24.4,24.4c0,13.5,10.9,24.4,24.4,24.4
                            C443.7,142.1,454.6,131.2,454.6,117.7z M302.3,46.8h-23.3c0,32.1,26,58.1,58.1,58.1V81.6C317.9,81.6,302.3,66,302.3,46.8z
                            M58.1,99.1c-1.4,0-2.7,0.1-4,0.3c-4.9,8.5-7.6,18.3-7.6,28.8V163h23.3v-34.9c0-8.2,2.9-15.8,7.6-21.8
                            C72.3,101.8,65.5,99.1,58.1,99.1z M310.6,136.7c-5.5-4.6-8.3-11.2-8.3-17.8v-18.5c-4.7-3.1-9-6.8-12.8-10.9
                            c-4.2-4.7-7.8-10.1-10.5-16v45.4c0,13.3,5.6,26.5,16.6,35.6c2.1,1.8,4.3,3.3,6.6,4.7c10.8,6.2,23.3,7.7,34.9,4.8V139
                            C329,143.7,318.3,143.1,310.6,136.7z M199.6,139.1c-10.1-10.1-18.8-20.6-25.4-30.5c-2.9,15,1.5,31.2,13.1,42.8
                            c11.6,11.6,27.8,16,42.8,13.1C220.2,157.8,209.7,149.2,199.6,139.1z M265.8,133.7c6-16.8,2.3-36.3-11.2-49.7
                            c-13.5-13.5-32.9-17.2-49.7-11.2c-3.1,1.1-6,2.5-8.8,4.2c1.5,4.7,5.9,11.7,12.3,19.8c9.4-5.7,21.8-4.4,29.9,3.7
                            c8.1,8.1,9.3,20.5,3.7,29.9c8,6.4,15,10.8,19.8,12.3C263.3,139.7,264.8,136.8,265.8,133.7z"
                        />
                        <linearGradient
                            id="SVGID_3_"
                            gradientUnits="userSpaceOnUse"
                            x1="381.6071"
                            y1="182.7617"
                            x2="211.1786"
                            y2="12.3333"
                        >
                            <stop offset="0.3" style="stop-color: #c6007e" />
                            <stop offset="1" style="stop-color: #d4409e" />
                        </linearGradient>
                        <path
                            class="st5"
                            d="M278.5,175.3c-9.5,9.5-43-8.5-74.8-40.3s-49.8-65.3-40.3-74.8c5.6-5.6,19.7-1.6,36.8,9.2
                            c-4.5-1.7-7.9-1.8-9.6-0.1c-5.4,5.4,7.8,27.5,29.6,49.3s43.9,35,49.3,29.6c1.7-1.7,1.6-5.1-0.1-9.6
                            C280.1,155.6,284.1,169.6,278.5,175.3z M430.2,106.1c-6.4,0-11.6,5.2-11.6,11.6c0,6.4,5.2,11.6,11.6,11.6s11.6-5.2,11.6-11.6
                            C441.9,111.3,436.7,106.1,430.2,106.1z"
                        />
                    </g>
                    <path
                        class="st6"
                        d="M676.3,232.8h-3.8c-0.2,0-0.5-0.2-0.5-0.4V0.7c0-0.2,0.2-0.4,0.5-0.4h3.8c0.2,0,0.5,0.2,0.5,0.4v231.7
                        C676.7,232.6,676.5,232.8,676.3,232.8z"
                    />
                </g>
            </svg>
        </a>
        <div *ngIf="account" class="min-trans-header">
            <img
                *ngIf="!account.iso_img"
                src="assets/img/mintransporte.png"
                class="header-logo"
            />
            <img
                *ngIf="account.iso_img"
                src="assets/img/mintransporte.png"
                class="header-logo"
            />
        </div>
    </div>
</header>
