<!--INICIO MODAL-->
<ng-template #content let-modal>
    <div class="modal-header" *ngIf="modal.modalTitle">
        <h3 class="kt-subheader__title">
            {{ model ? model.modalTitle : 'Buscador' }}
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-12">
                <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
            </div>
        </div>
    </div>
</ng-template>
<!--FIN MODAL-->

<!--FORMAS DE VISUALIZAR-->
<!-- <button
    *ngIf="model.isModal"
    class="btn btn-sm btn-light-success font-weight-bolder text-uppercase"
    type="button"
    (click)="open(content)"
>
    {{ model ? model.buttonTitle : 'Buscar' }}
</button> -->
<ng-container
    *ngIf="!model.isModal"
    [ngTemplateOutlet]="tableTemplate"
></ng-container>

<ng-container
    *ngIf="model.isModal"
    [ngTemplateOutlet]="tableTemplateModal"
    style="width: 100%"
></ng-container>
<!--FIN FORMAS DE VISUALIZAR-->

<!--begin::Card-->
<ng-template #tableTemplate>
    <div *ngIf="!removeCard" class="card card-custom">
        <!--begin::Header-->
        <div
            *ngIf="model.title"
            class="card-header-table flex-wrap border-0 pt-6 pb-0"
        >
            <div class="card-title">
                <h3 *ngIf="model.title" class="card-label">
                    {{ model ? model.title : 'Cargando...' }}
                    <span
                        *ngIf="model.description"
                        class="d-block text-muted pt-2 font-size-sm"
                        >{{ model ? model.description : '' }}</span
                    >
                </h3>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body">
            <ng-container [ngTemplateOutlet]="datatableTemplate"></ng-container>
        </div>
        <!--end::Body-->
    </div>

    <ng-container
        *ngIf="removeCard"
        [ngTemplateOutlet]="datatableTemplate"
    ></ng-container>
</ng-template>
<!--end::CARD-->

<!--Table Modal-->
<ng-template #tableTemplateModal>
    <div *ngIf="!removeCard" class="card card-custom">
        <!--begin::Body-->
        <div class="card-body">
            <ng-container
                [ngTemplateOutlet]="datatableTemplateModal"
            ></ng-container>
        </div>
        <!--end::Body-->
    </div>

    <ng-container
        *ngIf="removeCard"
        [ngTemplateOutlet]="datatableTemplateModal"
    ></ng-container>
</ng-template>
<!--end::CARD-->

<!--begin: Datatable-->
<ng-template #datatableTemplate>
    <section class="content">
        <div class="container-fluid">
            <div class="main-card mb-3 mt-3 card">
                <div class="card-header-table">
                    <ng-container>
                        <app-pagination
                            [model]="model"
                            [pagination]="model.pagination"
                            [title]="model.title"
                            (clickedPage)="clickedPage($event)"
                            (clickedPageSize)="clickedPageSize($event)"
                            (changeSearch)="changeSearch($event)"
                            (getTotalRecords)="getTotalRecords($event)"
                            [isReset]="isReset"
                        ></app-pagination>
                    </ng-container>
                </div>
                <ng-container>
                    <div>
                        <div
                            class="grid table-container"
                            id="kt_datatable"
                        >
                            <table>
                                <thead>
                                    <tr class="header">
                                        <th *ngIf="!hideIndex">
                                            <span></span>
                                        </th>

                                        <th
                                            *ngIf="!hideIndex"
                                            data-field="RecordID"
                                            data-sort="asc"
                                        >
                                            <span>#</span>
                                        </th>

                                        <th
                                            *ngFor="let field of model.fields"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                        >
                                            <span>
                                                <ng-container
                                                    *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                            </span>
                                        </th>
                                        <th
                                            *ngIf="model.enableActions"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                            class="acciones"
                                        >
                                            <span>Acciones</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    style="background-color: #fbfbfb"
                                    *ngIf="model?.records?.length > 0"
                                >
                                    <ng-container
                                        *ngFor="
                                            let record of model.records;
                                            let i = index
                                        "
                                    >
                                        <tr
                                            data-row="10"
                                            class="tr-table"
                                            [ngStyle]="{
                                                background:
                                                    selectBackroundColor(i)
                                            }"
                                            [style.cursor]="
                                                actionRow
                                                    ? 'pointer'
                                                    : 'default'
                                            "
                                        >
                                            <td
                                                *ngIf="!hideIndex"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                [style.color]="selectColor(i)"
                                            >
                                                <a>
                                                    <i
                                                        class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}"
                                                        [style.color]="
                                                            selectColor(i)
                                                        "
                                                    ></i>
                                                </a>
                                            </td>

                                            <td
                                                *ngIf="!hideIndex"
                                                (click)="clickedRow(i)"
                                                data-field="RecordID"
                                                aria-label="11"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                [style.color]="selectColor(i)"
                                            >
                                                <span
                                                    [style.color]="
                                                        selectColor(i)
                                                    "
                                                    ><span>{{
                                                        i +
                                                            1 +
                                                            (model.pagination
                                                                .currentPage -
                                                                1) *
                                                                model.pagination
                                                                    .pageSize
                                                    }}</span></span
                                                >
                                            </td>

                                            <td
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                *ngFor="
                                                    let field of model.fields
                                                "
                                                aria-label="Littel and Sons"
                                                [style.color]="selectColor(i)"
                                            >
                                                <ng-container
                                                    *ngIf="!field.type"
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                            [style.color]="
                                                                selectColor(i)
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                                    | date
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label
                                                            "
                                                            [style.color]="
                                                                selectColor(i)
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                            }}
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            [style.color]="
                                                                selectColor(i)
                                                            "
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</ng-container
                                                        >
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                            [style.color]="
                                                                selectColor(i)
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ] | date
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool
                                                            "
                                                            [style.color]="
                                                                selectColor(i)
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                field.valueBool
                                                            "
                                                        >
                                                            <ng-container
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === true
                                                                "
                                                            >
                                                                Si
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === false
                                                                "
                                                            >
                                                                No
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            [style.color]="
                                                                selectColor(i)
                                                            "
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</ng-container
                                                        >
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            field.symbol
                                                        "
                                                    >
                                                        <ng-container
                                                            class="
                                                                d-flex
                                                                align-items-center
                                                            "
                                                        >
                                                            <ng-container
                                                                class="
                                                                    symbol
                                                                    symbol-40
                                                                    symbol-light-primary
                                                                    flex-shrink-0
                                                                "
                                                            >
                                                                <ng-container
                                                                    class="
                                                                        symbol-label
                                                                        font-size-h4
                                                                        font-weight-bold
                                                                    "
                                                                    >{{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ][0]
                                                                    }}</ng-container
                                                                >
                                                            </ng-container>
                                                            <ng-container
                                                                class="ml-4"
                                                            >
                                                                <ng-container
                                                                    *ngIf="
                                                                        field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] | date
                                                                    }}
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="
                                                                        !field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ]
                                                                    }}
                                                                </ng-container>

                                                                <a>{{
                                                                    record[
                                                                        field
                                                                            .subkey
                                                                    ]
                                                                }}</a>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            !field.highlighted
                                                        "
                                                    >
                                                        <ng-container
                                                            class="
                                                                font-weight-bolder
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </ng-container>
                                                        <ng-container>
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            field.highlighted
                                                        "
                                                    >
                                                        <ng-container
                                                            class="
                                                                font-weight-bolder
                                                                text-primary
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            class="
                                                                font-weight-bold
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="field.type"
                                                >
                                                    <ng-container
                                                        class="mr-2"
                                                        *ngIf="
                                                            field.type ===
                                                            'progressbar'
                                                        "
                                                    >
                                                        <ng-container
                                                            class="progress"
                                                        >
                                                            <ng-container
                                                                class="
                                                                    progress-bar
                                                                    progress-bar-striped
                                                                    progress-bar-animated
                                                                "
                                                                [ngClass]="{
                                                                    'bg-gray-400':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0,
                                                                    'bg-danger':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 30,
                                                                    'bg-warning':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            30 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 50,
                                                                    'bg-primary':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            50 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 100,
                                                                    'bg-success':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] ===
                                                                        100,
                                                                    '':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                }"
                                                                role="progressbar"
                                                                aria-valuenow="10"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {{
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                        | number
                                                                            : '1.2-2'
                                                                }}%
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </td>

                                            <td
                                                *ngIf="model.enableActions"
                                                data-field="Actions"
                                                data-autohide-disabled="false"
                                                aria-label="null"
                                                
                                            >
                                                <ng-container>
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "
                                                    ></ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <div
                                            class="row"
                                            *ngIf="
                                                selectedRow === i && actionRow
                                            "
                                        >
                                            <div class="col-12">
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        actionRow;
                                                        context: {
                                                            $implicit: record,
                                                            index: index
                                                        }
                                                    "
                                                ></ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <!--begin::Table Empty-->
                        <!--end::Table Empty-->
                    </div>
                </ng-container>
            </div>
        </div>
    </section>

    <!--end: Datatable-->
</ng-template>

<ng-template #datatableTemplateModal>
    <section class="content-modal">
        <div class="container-fluid">
            <div class="main-card mb-3 mt-3 card">
                <div class="card-header-table">
                    <ng-container>
                        <app-pagination
                            [pagination]="model.pagination"
                            [title]="model.title"
                            (clickedPage)="clickedPage($event)"
                            (clickedPageSize)="clickedPageSize($event)"
                            (getTotalRecords)="getTotalRecords($event)"
                            (changeSearch)="changeSearch($event)"
                        ></app-pagination>
                    </ng-container>
                </div>
                <ng-container>
                    <div class="m-4">
                        <div
                            class="grid table-container"
                            style="
                                overflow-y: hidden;
                                overflow-x: auto;
                                width: 100%;
                                height: max-content;
                            "
                            id="kt_datatable"
                        >
                            <table>
                                <thead>
                                    <tr class="header">
                                        <th *ngIf="!hideIndex">
                                            <span></span>
                                        </th>

                                        <th
                                            *ngIf="!hideIndex"
                                            data-field="RecordID"
                                            data-sort="asc"
                                        >
                                            <span>#</span>
                                        </th>

                                        <th
                                            *ngFor="let field of model.fields"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                        >
                                            <span>
                                                <ng-container
                                                    *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                            </span>
                                        </th>
                                        <th
                                            *ngIf="model.enableActions"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                        >
                                            <span>Acciones</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="model?.records?.length > 0">
                                    <ng-container
                                        *ngFor="
                                            let record of model.records;
                                            let i = index
                                        "
                                    >
                                        <tr data-row="10" class="tr-table">
                                            <td
                                                *ngIf="!hideIndex"
                                                (click)="clickedRow(i)"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                            >
                                                <a>
                                                    <i
                                                        class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}"
                                                    ></i>
                                                </a>
                                            </td>

                                            <td
                                                *ngIf="!hideIndex"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                data-field="RecordID"
                                                aria-label="11"
                                            >
                                                <ng-container
                                                    ><ng-container>{{
                                                        i +
                                                            1 +
                                                            (model.pagination
                                                                .currentPage -
                                                                1) *
                                                                model.pagination
                                                                    .pageSize
                                                    }}</ng-container></ng-container
                                                >
                                            </td>

                                            <td
                                                (click)="clickedRow(i)"
                                                *ngFor="
                                                    let field of model.fields
                                                "
                                                aria-label="Littel and Sons"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="!field.type"
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                                    | date
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                            }}
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</ng-container
                                                        >
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ] | date
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                field.valueBool
                                                            "
                                                        >
                                                            <ng-container
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === true
                                                                "
                                                            >
                                                                Si
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === false
                                                                "
                                                            >
                                                                No
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</ng-container
                                                        >
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            field.symbol
                                                        "
                                                    >
                                                        <ng-container
                                                            class="
                                                                d-flex
                                                                align-items-center
                                                            "
                                                        >
                                                            <ng-container
                                                                class="
                                                                    symbol
                                                                    symbol-40
                                                                    symbol-light-primary
                                                                    flex-shrink-0
                                                                "
                                                            >
                                                                <ng-container
                                                                    class="
                                                                        symbol-label
                                                                        font-size-h4
                                                                        font-weight-bold
                                                                    "
                                                                    >{{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ][0]
                                                                    }}</ng-container
                                                                >
                                                            </ng-container>
                                                            <ng-container
                                                                class="ml-4"
                                                            >
                                                                <ng-container
                                                                    *ngIf="
                                                                        field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] | date
                                                                    }}
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="
                                                                        !field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ]
                                                                    }}
                                                                </ng-container>

                                                                <a>{{
                                                                    record[
                                                                        field
                                                                            .subkey
                                                                    ]
                                                                }}</a>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            !field.highlighted
                                                        "
                                                    >
                                                        <ng-container
                                                            class="
                                                                font-weight-bolder
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </ng-container>
                                                        <ng-container>
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            field.highlighted
                                                        "
                                                    >
                                                        <ng-container
                                                            class="
                                                                font-weight-bolder
                                                                text-primary
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </ng-container>
                                                        <ng-container
                                                            class="
                                                                font-weight-bold
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="field.type"
                                                >
                                                    <ng-container
                                                        class="mr-2"
                                                        *ngIf="
                                                            field.type ===
                                                            'progressbar'
                                                        "
                                                    >
                                                        <ng-container
                                                            class="progress"
                                                        >
                                                            <ng-container
                                                                class="
                                                                    progress-bar
                                                                    progress-bar-striped
                                                                    progress-bar-animated
                                                                "
                                                                [ngClass]="{
                                                                    'bg-gray-400':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0,
                                                                    'bg-danger':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 30,
                                                                    'bg-warning':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            30 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 50,
                                                                    'bg-primary':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            50 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 100,
                                                                    'bg-success':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] ===
                                                                        100,
                                                                    '':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                }"
                                                                role="progressbar"
                                                                aria-valuenow="10"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {{
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                        | number
                                                                            : '1.2-2'
                                                                }}%
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </td>

                                            <td
                                                *ngIf="model.enableActions"
                                                data-field="Actions"
                                                data-autohide-disabled="false"
                                                aria-label="null"
                                            >
                                                <ng-container>
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "
                                                    ></ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <div
                                            class="row"
                                            *ngIf="
                                                selectedRow === i && actionRow
                                            "
                                        >
                                            <div class="col-12">
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        actionRow;
                                                        context: {
                                                            $implicit: record,
                                                            index: index
                                                        }
                                                    "
                                                ></ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <!--begin::Table Empty-->
                        <!--end::Table Empty-->
                    </div>
                </ng-container>
            </div>
        </div>
    </section>

    <!--end: Datatable-->
</ng-template>
