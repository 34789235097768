export class Account {
  id: number;
  name: string;
  id_wia: string;
  emails: string;
  uacl: string;
  cls: string;
  crt: string;
  bact: string;
  wialon_units: string;
  motion_unis: string;
  user_id: number;
  user_ids: number[];
  driver_ids: number[];
  fuec_ids: number[];
  responsible_ids: number[];
  contractor_ids: number[];
  route_ids: number[];
  signature: string;
  logo: string;
  iso_img: string;
  razonsocial: string;
  id_type: string;
  id_document: string;
  mobile: string;
  email: string;
  website: string;
  address: string;
  fuec_code_hab: string;
  fuec_resolucion: string;
  fuec_fecha_hab: string;
  fuec_end_number: string;
  token: string;
}
