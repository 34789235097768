import {AppService} from '@services/app.service';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
    public user: any;
    public account: any;

    constructor(
        private appService: AppService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.loadUserInfo();
        this.loadAccountInfo();
    }

    private async loadUserInfo(): Promise<void> {
        try {
            const user = await this.appService.getSessionInfo();
            this.user = user || this.handleLogout();
        } catch (error) {
            this.toastr.error(
                'Error al cargar la información de usuario',
                error
            );
        }
    }

    private async loadAccountInfo(): Promise<void> {
        try {
            const account = await this.appService.getAccountInfo();
            this.account = account || this.handleLogout();
        } catch (error) {
            this.toastr.error(
                'Error al cargar la información de la cuenta',
            );
        }
    }

    public async handleUploadIso(event: Event): Promise<void> {
        const input = event.target as HTMLInputElement;
        const file = input.files?.[0];
        try {
            const reader = new FileReader();
            reader.onload = async () => {
                const result = reader.result?.toString();
                if (result) {
                    const response = await this.appService.uploadIso(result);
                    if (response) {
                        this.toastr.success('Archivo ISO cargado correctamente');
                        await this.loadAccountInfo();
                    } else {
                        this.toastr.error('Error al cargar el archivo ISO');
                    }
                }
            };
            reader.readAsDataURL(file);
        } catch (error) {
            this.toastr.error('Error uploading ISO file', error);
        }
    }

    private handleLogout(): void {
        this.appService.logout();
    }
}
