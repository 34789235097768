import { ManageResponsiblesComponent } from '@pages/manage-responsibles/manage-responsibles.component';
import { ManageContractorsComponent } from '@pages/manage-contractors/manage-contractors.component';
import { ManageAccountComponent } from '@pages/manage-account/manage-account.component';
import { ManageRoutesComponent } from '@pages/manage-routes/manage-routes.component';
import { FuecDetailComponent } from '@pages/fuec-detail/fuec-detail.component';
import { CreateFuecComponent } from '@pages/create-fuec/create-fuec.component';
import { ManageFuecComponent } from '@pages/manage-fuec/manage-fuec.component';
import { LoginComponent } from '@modules/login/login.component';
import { MainComponent } from '@modules/main/main.component';
import { Routes, RouterModule } from '@angular/router';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { AuthGuard } from '@guards/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '', component: ManageAccountComponent 
            },
            {
                path: 'fuec', component: CreateFuecComponent 
            },
            {
                path: 'history', component: ManageFuecComponent
            },
            {
                path: 'responsibles', component: ManageResponsiblesComponent 
            },
            {
                path: 'contractors', component: ManageContractorsComponent 
            },
            {
                path: 'routes', component: ManageRoutesComponent
            }
        ]
    },
    {
        path: 'login', component: LoginComponent, canActivate: [NonAuthGuard]
    },
    {
        path: 'fuec/:id', component: FuecDetailComponent, canActivate: [NonAuthGuard] 
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
