<form class="grid-4col fuec-form" *ngIf="account">
    <div class="grid logo-firma-container">
        <div class="grid input-archivo">
            <img *ngIf="!account.logo" src="assets/img/company-logo.png" />
            <img
                *ngIf="account.logo"
                src="{{ 'data:image/jpg;base64,' + account.logo }}"
                er
            />
            <label for="cambiar-logo">
                <a type="button" class="btn4 s-btn"> Cambiar Logo </a>
            </label>

            <input
                id="cambiar-logo"
                type="file"
                (change)="handleUploadLogo($event)"
            />
        </div>

        <div class="grid input-archivo">
            <img *ngIf="!account.signature" src="assets/img/signature.png" />
            <img
                *ngIf="account.signature"
                src="{{ 'data:image/jpg;base64,' + account.signature }}"
            />
            <label for="cambiar-firma">
                <a type="button" class="btn4 s-btn"> Cambiar Firma </a>
            </label>
            <input
                id="cambiar-firma"
                type="file"
                (change)="handleUploadSignature($event)"
            />
        </div>
    </div>

    <div class="grid inputs-container">
        <div class="input-div">
            <label for="razon-social">
                <h3 class="label-title">Razón Social</h3>
            </label>
            <input
                id="razon-social"
                type="text"
                placeholder="Razón Social"
                [(ngModel)]="account.razonsocial"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="celular">
                <h3 class="label-title">Celular</h3>
            </label>
            <input
                id="celular"
                type="tel"
                placeholder="Número de celular"
                maxLength="10"
                pattern="\d{10}"
                [(ngModel)]="account.mobile"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="direccion">
                <h3 class="label-title">Dirección</h3>
            </label>
            <input
                id="direccion"
                type="text"
                placeholder="Dirección"
                [(ngModel)]="account.address"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="numero-resolucion">
                <h3 class="label-title">Nº Resolución de habilitación</h3>
            </label>
            <input
                id="numero-resolucion"
                type="number"
                placeholder="Número de Resolución"
                [(ngModel)]="account.fuec_resolucion"
                (ngModelChange)="onChangeResolution($event)"
                [ngModelOptions]="{standalone: true}"
                minlength="4"
                maxlength="4"
            />
        </div>
    </div>

    <div class="grid inputs-container">
        <div class="input-div">
            <label for="tipo-id-list">
                <h3 class="label-title">Tipo de identificación</h3>
            </label>
            <select
                id="tipo-id-list"
                [(ngModel)]="account.id_type"
                [ngModelOptions]="{standalone: true}"
            >
                <option value="CC">Cedula de ciudadania</option>
                <option value="CE">Cedula de extranjeria</option>
                <option value="PA">Pasaporte</option>
                <option value="NIT">NIT</option>
            </select>
        </div>

        <div class="input-div">
            <label for="email">
                <h3 class="label-title">E-mail*</h3>
            </label>
            <input
                id="email"
                type="email"
                placeholder="Correo electrónico"
                [(ngModel)]="account.email"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="secuencia">
                <h3 class="label-title">Secuencia</h3>
            </label>
            <input
                id="secuencia"
                type="number"
                placeholder="Número de secuencia"
                [(ngModel)]="account.fuec_end_number"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="fecha-hab">
                <h3 class="label-title">Fecha de habilitación</h3>
            </label>
            <input
                id="fecha-hab"
                type="date"
                placeholder="dd/mm/aaaa"
                maxLength="10"
                pattern="\d{1,2}/\d{1,2}/\d{4}"
                [(ngModel)]="account.fuec_fecha_hab"
                [ngModelOptions]="{standalone: true}"
            />
        </div>
    </div>

    <div class="grid inputs-container">
        <div class="input-div">
            <label for="documento">
                <h3 class="label-title">Nº de documento</h3>
            </label>
            <input
                id="documento"
                type="number"
                placeholder="Número de su documento"
                [(ngModel)]="account.id_document"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="sitio-web">
                <h3 class="label-title">Sitio web</h3>
            </label>
            <input
                id="sitio-web"
                type="text"
                placeholder="www"
                [(ngModel)]="account.website"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="codigo-hab">
                <h3 class="label-title">Código de habilitación</h3>
            </label>
            <input
                id="codigo-hab"
                type="number"
                placeholder="Código"
                [(ngModel)]="account.fuec_code_hab"
                [ngModelOptions]="{standalone: true}"
            />
        </div>

        <div class="input-div">
            <label for="actualizar-info">
                <h3 class="label-title">Actualizar información</h3>
            </label>
            <button
                id="actualizar-info"
                type="submit"
                class="btn3"
                (click)="updateAccount()"
            >
                Actualizar
            </button>
        </div>
    </div>
</form>
