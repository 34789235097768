import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fuec',
  templateUrl: './fuec.component.html',
  styleUrls: ['./fuec.component.scss']
})
export class FuecComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
