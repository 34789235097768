<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
    <div class="row m-0 p-0 w-100">
        <div
            class="d-flex align-items-center justify-content-center h-100 w-100"
        >
            <div class="acionts-content">
                <div matTooltip="Editar" class="icon-td">
                    <svg
                        viewBox="0 0 400 400"
                        xmlns="http://www.w3.org/2000/svg"
                        style="enable-background: new 0 0 100 100"
                        class="table-icon"
                        (click)="showDetailInfo($implicit)"
                    >
                        <path
                            d="M200 80C244.8 80 283.6 101.2 315.6 142.8C331.6 163.6 342.4 185.2 348.4 199.2C342 212.8 330.8 234 314.4 254.8C280.8 298 242.4 320 200 320C117.2 320 68.4 234.4 51.6 198.8C66.8 163.6 111.6 80 200 80ZM200 32C51.6 32 0 200 0 200C0 200 60.4 368 200 368C339.6 368 400 200 400 200C400 200 348.4 32 200 32ZM200 176C213.2 176 224 186.8 224 200C224 213.2 213.2 224 200 224C186.8 224 176 213.2 176 200C176 186.8 186.8 176 200 176ZM200 128C160.4 128 128 160.4 128 200C128 239.6 160.4 272 200 272C239.6 272 272 239.6 272 200C272 160.4 239.6 128 200 128Z"
                            fill="current"
                        />
                    </svg>
                </div>
                <div matTooltip="Eliminar " class="icon-td">
                    <svg
                        version="1.1"
                        id="listos"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        style="enable-background: new 0 0 100 100"
                        xml:space="preserve"
                        class="table-icon"
                        (click)="showDetailInfoDelete($implicit)"
                    >
                        <path
                            d="M100.2,11.2L88.8-0.2L50,38.7L11.2-0.2L-0.2,11.2L38.7,50L-0.2,88.8l11.4,11.4L50,61.3l38.8,38.9l11.4-11.4
           L61.3,50L100.2,11.2z "
                            fill="current"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<app-table
    [model]="routesModel"
    [removeCard]="false"
    (paginatorEvent)="paginatorEvent($event)"
    (pageSizeEvent)="pageSizeEvent($event)"
    (searchEvent)="searchEvent($event)"
    (getRecords)="getTotalRoutes($event)"
    [hideIndex]="true"
    [actionsTemplate]="actionsTemplate"
    [isReset]="isReset"
></app-table>
<div *ngIf="viewState.showModal && viewState.showEdit">
    <app-modal>
        <form [formGroup]="routeState.routesForm">
            <div class="grid-2col inputs-container">
                <div class="input-div">
                    <div class="add-input">
                        <label for="rute_name">
                            <h3 class="label-title gray1">Nombre de ruta</h3>
                        </label>
                        <input
                            id="rute_name"
                            type="text"
                            placeholder="Ruta"
                            class="label-title gray1 i-select"
                            formControlName="rute_name"
                        />
                    </div>
                </div>
                <div class="input-div">
                    <div class="add-input">
                        <label for="description">
                            <h3 class="label-title gray1">Descripción</h3>
                        </label>
                        <input
                            id="description"
                            type="text"
                            placeholder="Descripción"
                            class="label-title gray1 i-select"
                            formControlName="description"
                        />
                    </div>
                </div>
                <div class="input-div">
                    <div class="add-input">
                        <label for="origin">
                            <h3 class="label-title gray1">Origen</h3>
                        </label>
                        <input
                            id="origin"
                            type="text"
                            placeholder="Origen"
                            class="label-title gray1 i-select"
                            formControlName="origin"
                        />
                    </div>
                </div>
                <div class="input-div">
                    <div class="add-input">
                        <label for="destination">
                            <h3 class="label-title gray1">Destino</h3>
                        </label>
                        <input
                            id="destination"
                            type="text"
                            placeholder="Destino"
                            class="label-title gray1 i-select"
                            formControlName="destination"
                        />
                    </div>
                </div>
                <div class="content-buttons">
                  <button
                      type="button"
                      (click)="showDetailInfoEdit()"
                      class="btn3"
                  >
                      Guardar
                  </button>
              </div>
            </div>
        </form>
    </app-modal>
</div>


<div *ngIf="viewState.showEditInfo">
  <app-modal>
      <div class="row">
          <h1>
              ¿Está seguro de editar este ruta?
          </h1>
      </div>
      <div class="row content-buttons1">
          <button type="button" (click)="showDetailInfoEdit()" class="btn4">
              Cancelar
          </button>
          <button type="button" (click)="editRoute()" class="btn3">
              Aceptar
          </button>
      </div>
  </app-modal>
</div>


<div *ngIf="viewState.showModal && viewState.showDelete">
  <app-modal>
      <div class="row">
          <h1>
              ¿Está seguro de eliminar esta Ruta?
          </h1>
      </div>
      <div class="row content-buttons1">
          <button type="button" class="btn4" (click)="cancelDelete()">
              Cancelar
          </button>
          <button type="button" class="btn3" (click)="onRemoveRoute()">
              Aceptar
          </button>
      </div>
  </app-modal>
</div>
