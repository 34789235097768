import {
    Backup,
    RouteState,
    ViewState
} from './manage-routes.component-interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit, EventEmitter} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {ExcelService} from '@services/excel.service';
import {RouteService} from '@services/route.service';
import {FuecService} from '@services/fuec.service';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-manage-routes',
    templateUrl: './manage-routes.component.html',
    styleUrls: ['./manage-routes.component.scss']
})
export class ManageRoutesComponent implements OnInit {
    emitEvent = new EventEmitter();
    viewState: ViewState = {
        showModal: false,
        showEdit: false,
        showDelete: false,
        showEditInfo: false
    };

    backup: Backup = {
        id_route: '',
        name: '',
        detail: '',
        origin: '',
        destination: ''
    };

    routeState: RouteState = {
        routes: [],
        routes_count: 0,
        routesForm: '',
        id_route: ''
    };

    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    isReset = false;

    routesModel: TableViewModel = {
        title: 'Rutas',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'name', filter: false, data: []},
            {title: 'Descripción', key: 'description', filter: false, data: []},
            {title: 'Origen', key: 'origin', filter: false, data: []},
            {title: 'Destino', key: 'destination', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };

    constructor(
        private appService: AppService,
        private routeService: RouteService,
        private md1: SwitchService,
        private excelService: ExcelService,
        private ts: ToastrService
    ) {}

    ngOnInit(): void {
        this.getRutes();
        this.initForm();
        this.suscribeToModalEvents();
    }

    initForm(): void {
        this.routeState.routesForm = new FormGroup({
            rute_name: new FormControl(null, Validators.required),
            description: new FormControl(null, Validators.required),
            origin: new FormControl(null, Validators.required),
            destination: new FormControl(null, Validators.required)
        });
    }

    suscribeToModalEvents(): void {
        this.md1.$modal.subscribe((value) => {
            this.viewState.showModal = value;
        });
        this.md1.EditInfo.subscribe((value) => {
            this.viewState.showEditInfo = value;
        });
    }

    private resetState(): void {
        this.viewState.showModal = false;
        this.viewState.showEdit = false;
        this.viewState.showDelete = false;
        this.viewState.showEditInfo = false;
        this.backup = {};
        this.routeState.id_route = null;
        this.isReset = true;
    }

    getRutes(currentPage: number = this.currentPage) {
        this.appService
            .getRoutesFuecs(currentPage, this.pageSize, this.searchby)
            .then((routes) => {
                if (routes.result) {
                    this.routeState.routes = routes.result.map((route: any) => {
                        return {
                            ...route,
                            origin: route.origin || 'No hay origen',
                            destination: route.destination || 'No hay destino'
                        };
                    });
                    this.routeState.routes_count = routes.total_count;
                    this.routesModel.records = this.routeState.routes;
                    this.routesModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: routes.count,
                        rowCount: this.routeState.routes_count
                    };
                }
            });
    }

    getTotalRoutes(header: any) {
        this.appService.getRoutesFuecs().then(async (routes) => {
            if (routes.result) {
                this.routesModel.totalRecords = routes.result;
                var data = {
                    title: header.title,
                    header: header.header,
                    data: []
                };
                for await (let record of routes.result) {
                    var row = header.keys.map((key) => record[key]);
                    data.data.push(row);
                }
                await this.excelService.generateExcel(data);
            }
        });
    }

    // EDIT ROUTE

    showDetailInfo(info) {
        if (this.viewState.showModal) {
            this.md1.$modal.emit(false);
            this.viewState.showEdit = false;
            this.backup = {};
        } else {
            this.md1.$modal.emit(true);
            this.viewState.showDelete = false;
            this.viewState.showEdit = true;
            this.routeState.id_route = info.id;
            this.routeState.routesForm.patchValue({
                rute_name: info.name,
                description: info.description,
                origin: info.origin,
                destination: info.destination
            });

            this.backup = {
                id_route: info.id,
                name: info.name,
                detail: info.description,
                origin: info.origin,
                destination: info.destination
            };
        }
    }

    showDetailInfoEdit() {
        if (this.routeState.routesForm.valid) {
            this.viewState.showEditInfo = !this.viewState.showEditInfo;
            this.md1.EditInfo.emit(this.viewState.showEditInfo);
        } else {
            this.ts.error('Debe llenar todos los campos');
        }
    }

    editRoute() {
        if (this.routeState.routesForm.valid) {
            var params = {
                id: this.routeState.id_route,
                name: this.routeState.routesForm.value.rute_name,
                detail: this.routeState.routesForm.value.description,
                origin: this.routeState.routesForm.value.origin,
                destination: this.routeState.routesForm.value.destination
            };

            this.routeService
                .updateFuecBackupRoute(this.backup)
                .then((response) => {
                    if (response.msg) {
                        console.log(response.msg);
                    } else if (response.error) {
                        console.log(response.error);
                    }
                });

            this.routeService.updateRoute(params).then((route) => {
                if (route.msg) {
                    this.ts.success('La ruta se edito con éxito');
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la actualización de la ruta'
                    );
                }
                this.getRutes();
                this.resetState();
            });
        }
    }

    // REMOVE ROUTE

    showDetailInfoDelete(info) {
        if (this.viewState.showModal) {
            this.md1.$modal.emit(false);
            this.viewState.showDelete = false;
            this.backup = {};
        } else {
            this.routeState.id_route = info.id;
            this.md1.$modal.emit(true);
            this.viewState.showDelete = true;
            this.viewState.showEdit = false;
            this.backup = {
                id_route: info.id,
                name: info.name,
                detail: info.description,
                origin: info.origin,
                destination: info.destination
            };
        }
    }

    cancelDelete() {
        this.resetState();
    }

    onRemoveRoute() {
        this.routeService
            .updateFuecBackupRoute(this.backup)
            .then((response) => {
                if (response.msg) {
                    console.log(response.msg);
                } else if (response.error) {
                    console.error(response.error);
                    return Promise.reject(response.error);
                }
                return this.routeService.deleteRoute(this.routeState.id_route);
            })
            .then(() => {
                this.getRutes();
                this.md1.$modal.emit(false);
                this.viewState.showDelete = false;
                this.routeState.id_route = null;
                this.ts.success('La ruta fue eliminada con exito');
                this.isReset = true;
            });
    }

    // FILTERS

    paginatorEvent(currentPage: number) {
        this.getRutes(currentPage);
        this.isReset = false;
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
}
