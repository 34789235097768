import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    constructor(private http: HttpClient) {}

    async createUnit(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/unit/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (!post.error) {
            var driver_id = post.result;
            
            return driver_id;
        } else {
            return false
        }
    }

    async getUnits(){
        let post = await this.http
            .get<any>('/api/fuec/units_external')
            .toPromise();
        if (!post.error) {
            return post;
        } else {
            return false
        }
    }

}
