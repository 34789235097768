import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
    @Input()
    closeModal: boolean = false;

    @Output()
    closeFunction = new EventEmitter<any>();

    constructor(private modalServicer: SwitchService) {}

    ngOnInit(): void {}

    close() {
        if (this.closeModal) {
            this.closeFunction.emit();
        } else {
            this.modalServicer.$modal.emit(false);
        }
        this.modalServicer.$modalExport.emit(false);
        this.modalServicer.EditInfo.emit(false)
    }

    onDestroy() {}
}
