<form
    [formGroup]="routesForm"
    (ngSubmit)="createRoutes()"
    class="form-container"
>
    <div class="grid-1col inputs-container">
        <div class="input-div">
            <div class="add-input">
                <label for="nombre">
                    <div class="label-title gray1">Nombre de la ruta</div>
                </label>
                <input
                    id="nombre"
                    type="text"
                    placeholder="Nombre"
                    class="label-title gray1 i-select"
                    formControlName="name"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="orig">
                    <div class="label-title gray1">Origen</div>
                </label>
                <input
                    id="orig"
                    type="text"
                    placeholder="Origen"
                    class="label-title gray1 i-select"
                    formControlName="orig"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="dest">
                    <div class="label-title gray1">Destino</div>
                </label>
                <input
                    id="dest"
                    type="text"
                    placeholder="Destino"
                    class="label-title gray1 i-select"
                    formControlName="dest"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="desc">
                    <div class="label-title gray1">Descripción</div>
                </label>
                <textarea
                    id="desc"
                    type="text"
                    placeholder="Descripción"
                    class="label-title gray1 i-select"
                    formControlName="desc"
                ></textarea>
            </div>
        </div>
    </div>
    <div class="center">
        <button type="submit" class="btn3">Crear ruta</button>
    </div>
</form>
