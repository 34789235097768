import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FuecService} from './fuec.service';

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    public drivesSubject = new BehaviorSubject<any[]>([]);
    drives$ = this.drivesSubject.asObservable();

    constructor(private fuecService: FuecService) {}

    initialStateDrivers(): void {
        this.fuecService.getDriversFuecs(1, 5, "", true, {}).then((drivers) => {
            this.drivesSubject.next(drivers);
        });
    }

    clearStateDrivers(): void {
        this.drivesSubject.next([]); 
    }
    
}
