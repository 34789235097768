import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MinTransporte} from 'assets/images/MinTransporte';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import {superIT} from 'assets/images/superIT';
import pdfMake from 'pdfmake/build/pdfmake';
import {Location} from '@angular/common';
import {Vehicle} from '@/models/vehicle';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-fuec-detail',
    templateUrl: './fuec-detail.component.html',
    styleUrls: ['./fuec-detail.component.scss']
})
export class FuecDetailComponent implements OnInit {
    isHovered: boolean = false;
    account: any;
    responsibles: any;
    routes: any;
    contractors: any;
    drivers: any;
    units: any;
    drivers_count: number;
    vehicle: Vehicle;
    route_name: string;
    contractor_name: string;
    contractor_nit: string;
    contract_object: string;

    public fuecForm: FormGroup;
    public myAngularxQrCode: string = null;
    public currentYear: string = DateTime.now().toFormat('y');

    vehiculo = new FormControl();
    responsables = new FormControl();

    date_hab: string;
    current_year: string = new Date(Date.now()).getFullYear().toString();
    fuec_contract_number: string;
    contract_number: string;
    start_date: any;
    end_date: any;
    fuec: any;
    notFound: boolean = false;
    fuec_series_format: string = '';

    @ViewChild('FuectPrint') pdfTable: ElementRef;

    // character limiter function

    insertLineBreaks(text, maxLength) {
        let result = '';
        for (let i = 0; i < text.length; i += maxLength) {
            result += text.slice(i, i + maxLength) + '\n';
        }
        return result.trim();
    }

    parseBackup(routeBackup, type) {
        try {
            const conv = JSON.parse(routeBackup);
            if (conv[type] && conv[type] !== '') {
                return conv[type];
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.fuecForm = new FormGroup({
            fuec_series: new FormControl(null, Validators.required)
        });
        this.getFuec();
    }

    onMouseEnter() {
        this.isHovered = true;
    }

    onMouseLeave() {
        this.isHovered = false;
    }

    navigateToRoute() {
        this.router.navigate(['/history']);
    }

    getFuec() {
        const id = this.route.snapshot.paramMap.get('id');
        this.apiService.getFuecDetail(id).then((fuec) => {
            if (fuec) {
                this.fuec = fuec.result;
                this.fuecForm.controls['fuec_series'].setValue(
                    this.fuec.fuec_series
                );
                this.notFound = false;
            } else {
                this.notFound = true;
            }
        });
    }

    searchFuec() {
        this.router
            .navigate([`/fuec/${this.fuecForm.value.fuec_series}`])
            .then(() => {
                this.getFuec();
            });
    }

    formatFuecSeries(): string {
        const separate = this.fuec.fuec_series.split('');
        let num1 = '';
        for (var i = 0; i <= 2; i++) {
            num1 = `${num1}${separate[i]}`;
        }
        let num2 = '';
        for (var i = 3; i <= 6; i++) {
            num2 = `${num2}${separate[i]}`;
        }
        let num3 = '';
        for (var i = 7; i <= 8; i++) {
            num3 = `${num3}${separate[i]}`;
        }
        let num4 = '';
        for (var i = 9; i <= 12; i++) {
            num4 = `${num4}${separate[i]}`;
        }
        let num5 = '';
        for (var i = 13; i <= 16; i++) {
            num5 = `${num5}${separate[i]}`;
        }
        let num6 = '';
        for (var i = 17; i <= 20; i++) {
            num6 = `${num6}${separate[i]}`;
        }
        return (this.fuec_series_format = `${num1} ${num2} ${num3} ${num4} ${num5} ${num6}`);
    }

    openPDF() {
        var json_drivers = [];
        const emailText = this.insertLineBreaks(this.fuec.account.email, 35);

        json_drivers = this.fuec.drivers.map((driver) => {
            const data = [
                {
                    text: driver.name,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.cc,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.licencia,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.vencimiento,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {}
            ];

            return data;
        });

        let json_responsibles = [];

        if (this.fuec.responsibles.length > 0) {
            json_responsibles = this.fuec.responsibles.map((responsible) => {
                return [
                    {
                        text:
                            this.parseBackup(
                                this.fuec.responsible_ids_backup,
                                'name'
                            ) || responsible.fullname,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text:
                            this.parseBackup(
                                this.fuec.responsible_ids_backup,
                                'nit'
                            ) || responsible.doc_number,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text:
                            this.parseBackup(
                                this.fuec.responsible_ids_backup,
                                'address'
                            ) || responsible.address,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text:
                            this.parseBackup(
                                this.fuec.responsible_ids_backup,
                                'phone'
                            ) || responsible.phone,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                ];
            });
        } else {
            // Si `fuec.responsibles` está vacío, construimos una estructura usando solo `parseBackup`
            json_responsibles.push([
                {
                    text:
                        this.parseBackup(
                            this.fuec.responsible_ids_backup,
                            'name'
                        ) || '',
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text:
                        this.parseBackup(
                            this.fuec.responsible_ids_backup,
                            'nit'
                        ) || '',
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text:
                        this.parseBackup(
                            this.fuec.responsible_ids_backup,
                            'address'
                        ) || '',
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text:
                        this.parseBackup(
                            this.fuec.responsible_ids_backup,
                            'phone'
                        ) || '',
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {}
            ]);
        }

        var docDefinition = {
            pageMargins: [30, 90, 40, 60],
            header: {
                columns: [
                    {
                        image: MinTransporte,
                        width: 260,
                        height: 60,
                        alignment: 'left',
                        opacity: 1,
                        margin: [40, 10]
                    },
                    {
                        image: `data:image/jpeg;base64,${this.fuec.account.logo}`,
                        width: 100,
                        height: 70,
                        alignment: 'right',
                        opacity: 0.9,
                        fit: [100, 70],
                        margin: [-25, 15]
                    }
                ],
                columnGap: 80
            },
            content: [
                {
                    style: 'tableExample',
                    table: {
                        body: [
                            [
                                {
                                    text: [
                                        `FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO \n PÚBLICO DE TRANSPORTE TERRESTRE AUTOMOTOR ESPECIAL \n NO. `,
                                        {
                                            text: this.formatFuecSeries(),
                                            color: '#FE0000'
                                        }
                                    ],
                                    colSpan: 24,
                                    alignment: 'center'
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Razón social: ',
                                            bold: true
                                        },
                                        this.fuec.account.razonsocial
                                    ],
                                    colSpan: 14
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'NIT: ',
                                            bold: true
                                        },
                                        this.fuec.account.id_document
                                    ],
                                    colSpan: 10
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Contrato No. ',
                                            bold: true
                                        },
                                        this.fuec.contract_number
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Contratante: ',
                                            bold: true
                                        },
                                        this.parseBackup(
                                            this.fuec.contractor_backup,
                                            'company_name'
                                        )
                                            ? this.parseBackup(
                                                  this.fuec.contractor_backup,
                                                  'company_name'
                                              )
                                            : this.fuec.contractor.company_name
                                    ],
                                    colSpan: 14
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'NIT: ',
                                            bold: true
                                        },
                                        this.fuec.contractor.nit
                                    ],
                                    colSpan: 10
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Objeto del contrato: ',
                                            bold: true
                                        },
                                        this.fuec.contract_object
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Ruta: ',
                                            bold: true
                                        },
                                        this.parseBackup(
                                            this.fuec.route_backup,
                                            'name'
                                        )
                                            ? this.parseBackup(
                                                  this.fuec.route_backup,
                                                  'name'
                                              )
                                            : this.fuec.route.name
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Descripcion: ',
                                            bold: true
                                        },
                                        this.parseBackup(
                                            this.fuec.route_backup,
                                            'detail'
                                        )
                                            ? this.parseBackup(
                                                  this.fuec.route_backup,
                                                  'detail'
                                              )
                                            : this.fuec.route.description
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Origen - Destino: ',
                                            bold: true
                                        },

                                        this.parseBackup(
                                            this.fuec.route_backup,
                                            'origin'
                                        ) ||
                                        this.parseBackup(
                                            this.fuec.route_backup,
                                            'destination'
                                        )
                                            ? `${
                                                  this.parseBackup(
                                                      this.fuec.route_backup,
                                                      'origin'
                                                  ) ||
                                                  this.fuec.route.origin ||
                                                  this.fuec.route.name
                                              } - ${
                                                  this.parseBackup(
                                                      this.fuec.route_backup,
                                                      'destination'
                                                  ) ||
                                                  this.fuec.route.destination ||
                                                  this.fuec.route.name
                                              }`
                                            : this.fuec.route.origin ||
                                              this.fuec.route.destination
                                            ? `${
                                                  this.fuec.route.origin ||
                                                  this.fuec.route.name
                                              } - ${
                                                  this.fuec.route.destination ||
                                                  this.fuec.route.name
                                              }`
                                            : this.fuec.route.name
                                    ],
                                    colSpan: 24
                                },

                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Convenio \n',
                                            bold: true
                                        },
                                        this.fuec.agreement === 'cv'
                                            ? this.fuec.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Consorcio \n',
                                            bold: true
                                        },
                                        this.fuec.agreement === 'cs'
                                            ? this.fuec.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Unión temporal \n',
                                            bold: true
                                        },
                                        this.fuec.agreement === 'ut'
                                            ? this.fuec.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Otro \n',
                                            bold: true
                                        },
                                        this.fuec.agreement == 'no-aplica'
                                            ? 'no-aplica'
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Vigencia del contrato:',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Desde: \n',
                                            bold: true
                                        },
                                        this.fuec.date_start
                                    ],
                                    alignment: 'center',
                                    colSpan: 12
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Hasta: \n',
                                            bold: true
                                        },
                                        this.fuec.date_end
                                    ],
                                    alignment: 'center',
                                    colSpan: 12
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Características del vehículo',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Placa',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Modelo',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Marca',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Clase',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Interno',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Operación',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: this.fuec.vehicle.registration_plate
                                        ? this.fuec.vehicle.registration_plate
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.fuec.vehicle.year
                                        ? this.fuec.vehicle.year
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.fuec.vehicle.brand
                                        ? this.fuec.vehicle.brand
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.fuec.vehicle.model
                                        ? this.fuec.vehicle.model
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.fuec.vehicle.vin
                                        ? this.fuec.vehicle.vin
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.fuec.vehicle.noperacion
                                        ? this.fuec.vehicle.noperacion
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Conductores',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Nombres y Apellidos',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Identificación',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Licencia',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Vigencia',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            //drivers
                            ...json_drivers,
                            [
                                {
                                    text: [
                                        {
                                            text: 'Responsables',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Nombres y Apellidos',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Identificacion',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Dirección',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Teléfono',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            //responsibles
                            ...json_responsibles,
                            [
                                {
                                    qr: `https://fuec.monitoringinnovation.com/fuec/${this.fuec.fuec_series}`,
                                    alignment: 'center',
                                    fit: 100,
                                    colSpan: 6,
                                    margin: [0, 10]
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: `${this.fuec.account.razonsocial} \n ${this.fuec.account.address} \n ${this.fuec.account.mobile} \n ${emailText}`,
                                    alignment: 'center',
                                    colSpan: 6,
                                    width: 90,
                                    link: `mailto:${this.fuec.account.email}`
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    image: superIT,
                                    width: 80,
                                    alignment: 'center',
                                    colSpan: 6,
                                    margin: [0, 5]
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    image: `data:image/jpeg;base64,${this.fuec.account.signature}`,
                                    width: 90,
                                    alignment: 'center',
                                    colSpan: 6,
                                    margin: [0, 5]
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ]
                        ]
                    }
                },
                {
                    text: 'INSTRUCTIVO PARA LA DETERMINACION DEL NUMERO CONSECUTIVO DEL FUEC',
                    pageBreak: 'before',
                    alignment: 'center',
                    fontSize: 14,
                    bold: true
                },
                {
                    margin: [25, 10],
                    columns: [
                        [
                            {
                                text: 'El Formato Unico de Extracto del Contrato "FUEC" estará constituido por los siguientes números:',
                                fontSize: 10,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'a) Los tres primeros dígitos de izquierda a derecha correcponderán al código de la Dirección Territorial que otorgó la habilitación o de aquella a la cual se hubiese trasladado la Empresa de Servicio público de Transporte Terrestre Automotor Especial;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Antioquia-Choco',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '305'
                                    },
                                    {
                                        width: '*',
                                        text: 'Huila-Caquetá ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '441'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Atlántico ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '208'
                                    },
                                    {
                                        width: '*',
                                        text: 'Magdalena',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '247'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Bolívar-San Andrés y Providencia',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '213'
                                    },
                                    {
                                        width: '*',
                                        text: 'Meta-Vaupés-Vichada ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '550'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Boyacá-Casanare',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '415'
                                    },
                                    {
                                        width: '*',
                                        text: 'Nariño-Putumayo',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '352'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Caldas',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '317'
                                    },
                                    {
                                        width: '*',
                                        text: 'N/Santander-Arauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '454'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '319'
                                    },
                                    {
                                        width: '*',
                                        text: 'Quindio',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '363'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'César',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '220'
                                    },
                                    {
                                        width: '*',
                                        text: 'Risaralda',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '366'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Córdoba-Sucre',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '223'
                                    },
                                    {
                                        width: '*',
                                        text: 'Santander',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '468'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cundinamarca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '425'
                                    },
                                    {
                                        width: '*',
                                        text: 'Tolima',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '473'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Guajira',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '241'
                                    },
                                    {
                                        width: '*',
                                        text: 'Valle del Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '376'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                ul: [
                                    'b) Los cuatro dígitos siguientes señalarán el número de resolución mediante la cual se otorgó la habilitación de la Empresa. En caso que la resolución no tenga estos dígitos, los faltantes serán completados con ceros a la izquierda;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'c) Los dos siguientes dígitos, corresponderán a los dos últimos del año en que la empresa fue habilitada;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'd) Acontinuación, cuatro dígitos que corresponderán al año en el que se expide el extracto del contrato;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `e) Posteriormente, cuatro dígitos que identifican el número del contrato. La numeración debe ser consecutiva, establecida por cada empresa y continuará con la numeración dada a los contratos de prestación del servicios celebrados para el transporte de estudiantes, empleados, turistas, usuarios del servicio de salud, grupos específicos de usuarios, en vigencia de la resolución 3068 de 2014.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `f) Finalmente, los cuatro últimos dígitos corresponderán al número consecutivo del extracto de contrato que se expida para la ejecución de cada contrato. Se debe expedir un nuevo extracto por vencimiento del plazo inicial del mismo o por cambio del vehículo.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: 'EJEMPLO:',
                                fontSize: 10,
                                bold: true
                            },
                            {
                                text: `Empresa habilitada por la Dirección Territorial Cundinamarca en el año 2012 con resolución de habilitación No. 0155, que expide el primer extracto del contrato en el año 2015, del contrato 255. El número del Formato Unico de Extracto del Contrato "FUEC" será : 425015512201502550001.
                                `,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                fontSize: 7,
                                columns: [
                                    {
                                        text: `Nota:`,
                                        width: 25,
                                        bold: true
                                    },
                                    {
                                        text: `El vehiculo se encuentra en perfecto estado Mecánico y de aseo.`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Para verificar la autenticidad de esta planilla consultar la pagina web https://fuec.monitoringinnovation.com/fuec/${this.fuec.fuec_series}.`,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: `INFORMACION POR RUTA ESTIMADA`,
                                fontSize: 7,
                                bold: true,
                                alignment: 'center',
                                margin: [0, 7]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Distancia recorrida estimada`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Duracion total estimada `,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Peajes`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Consumo de combustible estimado`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Resol. 315/2013 Art. PROTOCOLO DE ALISTAMIENTO DIARIO,Se deja constancia en este documento que participaron del proceso de alistamiento diaro el(los) conductor(es) mencionado(s) en este FUEC bajo la supervicion de la empresa a travéz deusos tecnológicos amparados por la ley 1450, art. 230 y del decreto ley 019 de 2012, art. 4`,
                                fontSize: 7
                            }
                        ]
                    ]
                }
            ]
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#8a8a8a';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                }
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }
}
