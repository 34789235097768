import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FuecService} from '@services/fuec.service';
import {ContractorService} from '@services/contractor.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-contractor',
    templateUrl: './contractor.component.html',
    styleUrls: ['./contractor.component.scss']
})
export class ContractorComponent implements OnInit {
    @Output()
    emitEvent = new EventEmitter();

    public contractorsForm: FormGroup;

    constructor(private fuecService: FuecService, private ts: ToastrService, private contractorService: ContractorService) {}

    ngOnInit(): void {
        this.contractorsForm = new FormGroup({
            company_name: new FormControl(null, Validators.required),
            nit: new FormControl(null, Validators.required),
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required)
        });
    }

    createContract() {
        if (this.contractorsForm.valid) {
            var params = {
                company_name: this.contractorsForm.value.company_name,
                nit: this.contractorsForm.value.nit,
                name: this.contractorsForm.value.name,
                email: this.contractorsForm.value.email,
                address: this.contractorsForm.value.address,
                city: this.contractorsForm.value.city,
                phone: this.contractorsForm.value.phone
            };
            this.contractorService.createContractor(params).then((response) => {
                if (response.msg) {
                    this.ts.success('El contratista se creó con éxito');
                    this.emitEvent.emit();
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la creacion del contratista'
                    );
                }
            });
        }
    }
}
