import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {ExcelService} from '@services/excel.service';
import {AppService} from '@services/app.service';
import {FuecService} from '@services/fuec.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-manage-fuec',
    templateUrl: './manage-fuec.component.html',
    styleUrls: ['./manage-fuec.component.scss']
})
export class ManageFuecComponent implements OnInit {
    currentPage = 1;
    pageSize = 5;
    searchby = '';
    isReset = false;

    fuecsModel: TableViewModel = {
        title: 'FUECS',
        description: '',
        filter: {page: this.currentPage, pageSize: this.pageSize},
        fields: [
            {title: 'Folio', key: 'folio', filter: false, data: []},
            {title: 'Serie', key: 'fuec_series', filter: false, data: []},
            {
                title: 'Vehiculo',
                key: 'vehicle',
                subkey: 'name',
                subobject: true,
                filter: false,
                data: []
            },
            {
                title: 'Contratista',
                key: 'contractor',
                subkey: 'contractor_name',
                subobject: true,
                filter: false,
                data: []
            }
        ],
        records: [],
        totalRecords: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };

    constructor(
        private fuecService: FuecService,
        private cd: ChangeDetectorRef,
        private excelService: ExcelService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadFuecsData();
    }

    loadFuecsData(): void {
        this.fuecService
            .getHistoryFuecs(this.currentPage, this.pageSize, this.searchby)
            .then((fuecs) => {
                if (fuecs?.result) {
                    this.updateModelData(fuecs.result, fuecs.total_count);
                }
            })
            .catch((error) => console.error('Error loading fuecs data', error));
    }

    private updateModelData(records: any[], totalRecords: number): void {
        this.fuecsModel.records = records;
        this.fuecsModel.pagination = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            pageCount: Math.ceil(totalRecords / this.pageSize),
            rowCount: totalRecords
        };
        this.cd.detectChanges();
    }

    getTotalHistory(header: {
        title: string;
        header: string[];
        keys: string[];
    }): void {
        this.fuecService
            .getHistoryFuecs()
            .then((fuecs) => {
                if (fuecs?.result) {
                    const data = {
                        title: header.title,
                        header: header.header,
                        data: fuecs.result.map((record) =>
                            header.keys.map((key) => record[key])
                        )
                    };
                    this.excelService.generateExcel(data);
                }
            })
            .catch((error) =>
                console.error('Error fetching total history data', error)
            );
    }

    goFuecDetail(fuec: {fuec_series: string}): void {
        this.router.navigate([`/fuec/${fuec.fuec_series}`]);
    }

    paginatorEvent(currentPage: number): void {
        this.currentPage = currentPage;
        this.loadFuecsData();
    }

    pageSizeEvent(pageSize: number): void {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string): void {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
}
