<div class="content-app-my">
  <app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

  <app-menu-sidebar
  ></app-menu-sidebar>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>

  <app-footer class="main-footer"></app-footer>
  <!--<aside class="control-sidebar"></aside>-->
  <!--<div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>-->
</div>
