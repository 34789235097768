import {AppService} from '@services/app.service';
import {Component} from '@angular/core';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent {
    constructor(public appService: AppService) {}
    logout() {
      this.appService.logout();
    }
}
