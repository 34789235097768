import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FuecService} from '@services/fuec.service';
import {ResponsibleService} from '@services/responsible.service'
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-responsible',
    templateUrl: './responsible.component.html',
    styleUrls: ['./responsible.component.scss']
})
export class ResponsibleComponent implements OnInit {
    @Output()
    emitEvent = new EventEmitter();

    public responsiblesForm: FormGroup;

    constructor(private fuecService: FuecService, private ts: ToastrService, private responsibleService: ResponsibleService) {}

    ngOnInit(): void {
        this.responsiblesForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            id_type: new FormControl(null, Validators.required),
            nit: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required)
        });
    }

    createResponsible() {
        if (this.responsiblesForm.valid) {
            var params = {
                name: this.responsiblesForm.value.name,
                id_type: this.responsiblesForm.value.id_type,
                id_document: this.responsiblesForm.value.nit,
                email: this.responsiblesForm.value.email,
                city: this.responsiblesForm.value.city,
                address: this.responsiblesForm.value.address,
                phone: this.responsiblesForm.value.phone
            };
            this.responsibleService.createResponsible(params).then((response) => {
                if (response.msg) {
                    this.ts.success('El responsable se creó con éxito');
                    this.emitEvent.emit();
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la creacion del responsable'
                    );
                }
            });
        }
    }
}
