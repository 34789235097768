import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {ManageAccountComponent} from '@pages/manage-account/manage-account.component';
import {CreateFuecComponent} from '@pages/create-fuec/create-fuec.component';
import {ManageFuecComponent} from '@pages/manage-fuec/manage-fuec.component';
import {ManageResponsiblesComponent} from '@pages/manage-responsibles/manage-responsibles.component';
import {ManageContractorsComponent} from '@pages/manage-contractors/manage-contractors.component';
import {ManageRoutesComponent} from '@pages/manage-routes/manage-routes.component';
import {FuecDetailComponent} from '@pages/fuec-detail/fuec-detail.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                component: ManageAccountComponent
            },
            {
                path: 'fuec',
                component: CreateFuecComponent
            },
            {
                path: 'history',
                component: ManageFuecComponent
            },
            {
                path: 'responsibles',
                component: ManageResponsiblesComponent
            },
            {
                path: 'contractors',
                component: ManageContractorsComponent
            },
            {
                path: 'routes',
                component: ManageRoutesComponent
                
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'fuec/:id',
        component: FuecDetailComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
