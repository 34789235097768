import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {AppService} from '@services/app.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
    public searchForm: FormGroup;
    public user;
    public account;

    constructor(private appService: AppService) {}

    ngOnInit() {
        this.searchForm = new FormGroup({
            search: new FormControl(null)
        });
        this.appService.getSessionInfo().then(
          user => user ? this.user = user: this.logout()
        );
        this.appService
          .getAccountInfo()
          .then((account) =>
            account ? (this.account = account) : this.logout()
          );

    }

    logout() {
        this.appService.logout();
    }

    handleUploadIso(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.appService.uploadIso(reader.result.toString()).then((res) => {
          this.appService
            .getAccountInfo()
            .then((account) =>
              account ? (this.account = account) : this.logout()
            );
        });
      };
    }
}
