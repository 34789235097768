import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {Account} from '@/models/account';
import {Router} from '@angular/router';
import { ContextService } from './context.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    public responsibles: any = null;
    public responsibles_count: any = null;
    public contractors: any = null;
    public contractors_count: any = null;
    public routes: any = null;
    public routes_count: any = null;
    public account: any = null;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private http: HttpClient,
        private contextService: ContextService 
    ) {}

    async loginByAuth({email, password}) {
        try {
            let body = {
                jsonrpc: '2.0',
                params: {
                    login: email,
                    password: password,
                    db: 'plataforma_motion'
                }
            };
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            let data = await this.http
                .post<any>('/api/fuec/auth/', body, {headers: headers})
                .toPromise();
            if ('error' in data) {
                this.toastr.error('Acceso denegado');
                return;
            } else {
                if ('error' in data.result) {
                    this.toastr.error(data.result.error.data.message);
                } else if (data.result.uid > 0) {
                    await this.saveUserInfo(data.result);
                } else {
                    this.toastr.error('Algo salio mal, intentalo de nuevo.');
                }
            }
            this.getProfile();
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async logout() {
        await this.http.get<any>('/api/fuec/logout').toPromise();
        this.user = null;
        this.contextService.clearStateDrivers()
        await this.router.navigate(['/login']);
    }

    async getProfile() {
        try {
            this.user = await this.http
                .get<any>('/api/fuec/session')
                .toPromise();
            if (!this.user) {
                this.logout();
                return;
            }
            await this.router.navigate(['/']);
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    async getSessionInfo() {
        let user = await this.http.get<any>('/api/fuec/session').toPromise();
        if (user.uid != null) {
            return user;
        } else {
            return null;
        }
    }

    async getAccountInfo() {
        let account = await this.http.get<any>('/api/fuec/account').toPromise();
        if (!account.error) {
            this.setLocalAccount(account);
            return account;
        } else {
            return null;
        }
    }

    async updateAccountInfo(account: Account) {
        let updatedAccount = await this.http
            .post<any>('/api/fuec/account/update', {
                jsonrpc: '2.0',
                params: account
            })
            .toPromise();
        if (!updatedAccount.error) {
            this.setLocalAccount(account);
            return account;
        } else {
            return null;
        }
    }

    async uploadLogo(fileb64: string) {
        let updatedLogo = await this.http
            .post<any>('/api/fuec/update/logo', {
                jsonrpc: '2.0',
                params: {
                    logo: fileb64
                }
            })
            .toPromise();
        if (!updatedLogo.error) {
            return null;
        } else {
            return null;
        }
    }

    async uploadSignature(fileb64: string) {
        let updatedLogo = await this.http
            .post<any>('/api/fuec/update/signature', {
                jsonrpc: '2.0',
                params: {
                    signature: fileb64
                }
            })
            .toPromise();
        if (!updatedLogo.error) {
            return null;
        } else {
            return null;
        }
    }

    async uploadIso(fileb64: string) {
        let updatedIso = await this.http
            .post<any>('/api/fuec/update/iso', {
                jsonrpc: '2.0',
                params: {
                    iso_img: fileb64
                }
            })
            .toPromise();
        if (!updatedIso.error) {
            return true;
        } else {
            return false;
        }
    }

    async getResponsiblesFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let responsibles = await this.http
            .get<any>(
                `/api/fuec/responsibles/${page ? `page=${page}` : ``}${
                    pageSize ? `&pagesize=${pageSize}` : ``
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!responsibles.error) {
            this.responsibles = responsibles.result;
            this.responsibles_count = responsibles.count;
            return responsibles;
        } else {
            return null;
        }
    }
    async getContractorsFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let contractors = await this.http
            .get<any>(
                `/api/fuec/contractors/${page ? `page=${page}` : ``}${
                    pageSize ? `&pagesize=${pageSize}` : ``
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!contractors.error) {
            this.contractors = contractors.result;
            this.contractors_count = contractors.count;
            return contractors;
        } else {
            return null;
        }
    }

    async getRoutesFuecs(page?: number, pageSize?: number, searchby?: string) {
        let routes = await this.http
            .get<any>(
                `/api/fuec/routes/${page ? `page=${page}` : ``}${
                    pageSize ? `&pagesize=${pageSize}` : ``
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!routes.error) {
            this.routes = routes.result;
            this.routes_count = routes.count;
            return routes;
        } else {
            return null;
        }
    }

    padLeadingZeros(num, size: number) {
        var s = num + '';
        while (s.length < size) s = '0' + s;
        return s;
    }

    zfill(number: string, width: number): string {
        let numberOutput = number.toString();
        let length = numberOutput.length;
        let zero = '0';

        if (width <= length) {
            return numberOutput;
        } else {
            return zero.repeat(width - length) + numberOutput;
        }
    }

    insertLineBreaks(text, maxLength) {
        let result = '';
        for (let i = 0; i < text.length; i += maxLength) {
            result += text.slice(i, i + maxLength) + '\n';
        }
        return result.trim();
    }

    private saveUserInfo(result) {
        this.user = result;
    }

    private setLocalAccount(account: Account) {
        this.account = account;
    }
}
