<li class="nav-item dropdown user-menu">
    <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        (click)="toggleDropdownMenu()"
    >
      <ng-container *ngIf="!user">
        <img
          src="./assets/images/avatars/0.png"
          class="user-image img-circle elevation-2"
          alt="User Image"
        />
      </ng-container>
      <ng-container *ngIf="user">
        <img
          src="{{'data:image/jpg;base64,' + user.image}}"
          class="user-image img-circle elevation-2"
          alt="User Image"
        />
      </ng-container>

    </a>
    <ul
        #dropdownMenu
        class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
    >
        <!-- User image -->
        <li class="user-header bg-primary">
          <ng-container *ngIf="!user">
            <img
              src="./assets/images/avatars/0.png"
              class="img-circle elevation-2"
              alt="User Image"
            />
          </ng-container>
          <ng-container *ngIf="user">
              <img
                src="{{'data:image/jpg;base64,' + user.image}}"
                class="img-circle elevation-2"
                alt="User Image"
              />
          </ng-container>

            <p>
                <span>{{ user.username }}</span>
                <small>
                    <span>{{ user.name }}</span>
                </small>
            </p>
        </li>
        <!-- Menu Body -->
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/account']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Salir</a
            >
        </li>
    </ul>
</li>
