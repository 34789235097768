<footer class="content-wrapper row footer_brand">
  <div class="footer-div col-6">
    <p>&copy; Copyright {{currentYear}}, Monitoring Innovation</p>
  </div>
  <div >
    <a href="https://www.monitoringinnovation.com" target="_blank">
      Designed by Motion
    </a>
  </div>
</footer>
