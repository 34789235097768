import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {ExcelService} from '@services/excel.service';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-manage-routes',
    templateUrl: './manage-routes.component.html',
    styleUrls: ['./manage-routes.component.scss']
})

export class ManageRoutesComponent implements OnInit {

    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    routes: any;
    routes_count: any;
    showModal: boolean;
    showEdit: boolean;
    showEditInfo: boolean;
    showDelete: boolean;
    routesForm: FormGroup;
    backup = {};
    id_route: number;
    related_fuecs: [] = [];
    isReset = false;

    routesModel: TableViewModel = {
        title: 'Rutas',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'name', filter: false, data: []},
            {title: 'Descripción', key: 'description', filter: false, data: []},
            {title: 'Origen', key: 'origin', filter: false, data: []},
            {title: 'Destino', key: 'destination', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private md1: SwitchService,
        private excelService: ExcelService,
        private ts: ToastrService
    ) {}

    ngOnInit(): void {
        this.getRutes();
        this.routesForm = new FormGroup({
            rute_name: new FormControl(null, Validators.required),
            description: new FormControl(null, Validators.required),
            origin: new FormControl(null, Validators.required),
            destination: new FormControl(null, Validators.required)
        });
        this.md1.$modal.subscribe((value) => {
            this.showModal = value;
        });
        this.md1.EditInfo.subscribe((value) => {
            this.showEditInfo = value;
        });
    }

    getRutes() {
        this.appService
            .getRoutesFuecs(this.currentPage, this.pageSize, this.searchby)
            .then((routes) => {
                if (routes.result) {
                    this.routes = routes.result.map((route: any) => {
                        return {
                            ...route,
                            origin: route.origin || 'No hay origen',
                            destination: route.destination || 'No hay destino'
                        };
                    });
                    this.routes_count = routes.total_count;
                    this.routesModel.records = this.routes;
                    this.routesModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: routes.count,
                        rowCount: this.routes_count
                    };
                }
            });
    }

    getTotalRoutes(header: any) {
        this.appService.getRoutesFuecs().then(async (routes) => {
            if (routes.result) {
                this.routesModel.totalRecords = routes.result;
                var data = {
                    title: header.title,
                    header: header.header,
                    data: []
                };
                for await (let record of routes.result) {
                    var row = [];
                    for await (let key of header.keys) {
                        row.push(record[key]);
                    }
                    data.data.push(row);
                }
                await this.excelService.generateExcel(data);
            }
        });
    }

    // EDIT ROUTE

    showDetailInfo(info) {
        if (this.showModal) {
            this.md1.$modal.emit(false);
            this.showEdit = false;
            this.backup = {};
        } else {
            this.md1.$modal.emit(true);
            this.showDelete = false;
            this.showEdit = true;
            this.id_route = info.id;
            this.routesForm.controls['rute_name'].setValue(info.name);
            this.routesForm.controls['description'].setValue(info.description);
            this.routesForm.controls['origin'].setValue(info.origin);
            this.routesForm.controls['destination'].setValue(info.destination);
            this.backup = {
                id_route: info.id,
                name: info.name,
                detail: info.description,
                origin: info.origin,
                destination: info.destination
            };
        }
    }

    showDetailInfoEdit() {
        if (this.routesForm.valid) {
            if (!this.showEditInfo) {
                this.md1.EditInfo.emit(true);
                this.showEditInfo = true;
            } else {
                this.md1.EditInfo.emit(false);
                this.showEditInfo = false;
            }
        } else {
            this.ts.error('Debe llenar todos los campos');
        }
    }

    editRoute() {
        if (this.routesForm.valid) {
            var params = {
                id: this.id_route,
                name: this.routesForm.value.rute_name,
                detail: this.routesForm.value.description,
                origin: this.routesForm.value.origin,
                destination: this.routesForm.value.destination
            };

            this.apiService
                .updateFuecBackupRoute(this.backup)
                .then((response) => {
                    if (response.msg) {
                        console.log(response.msg);
                    } else if (response.error) {
                        console.log(response.error);
                    }
                });

            this.apiService.updateRoute(params).then((route) => {
                if (route.msg) {
                    this.ts.success('La ruta se edito con éxito');
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la actualización de la ruta'
                    );
                }
                this.getRutes();
                this.md1.$modal.emit(false);
                this.md1.EditInfo.emit(false);
                this.id_route = null;
                this.isReset = true;
                this.backup = {}
            });
        }
    }

    // REMOVE ROUTE

    showDetailInfoDelete(info) {
        if (this.showModal) {
            this.md1.$modal.emit(false);
            this.showDelete = false;
            this.backup = {};
        } else {
            this.id_route = info.id;
            this.md1.$modal.emit(true);
            this.showDelete = true;
            this.showEdit = false;
            this.backup = {
                id_route: info.id,
                name: info.name,
                detail: info.description,
                origin: info.origin,
                destination: info.destination
            };
        }
    }

    cancelDelete() {
        this.md1.$modal.emit(false);
        this.showDelete = false;
        this.backup = {};
    }

    onRemoveRoute() {
        this.apiService.updateFuecBackupRoute(this.backup).then((response) => {
            if (response.msg) {
                console.log(response.msg);
            } else if (response.error) {
                console.error(response.error);
            }
        });

        this.apiService.deleteRoute(this.id_route).then(() => {
            this.getRutes();
            this.md1.$modal.emit(false);
            this.showDelete = false;
            this.id_route = null;
            this.ts.success('La ruta fue eliminada con exito');
            this.isReset = true;
        });
    }

    // FILTERS

    paginatorEvent(currentPage: number) {
        this.appService
            .getRoutesFuecs(currentPage, this.pageSize, this.searchby)
            .then((routes) => {
                if (routes.result) {
                    this.routes = routes.result.map((route: any) => {
                        return {
                            ...route,
                            origin: route.origin || 'No hay origen',
                            destination: route.destination || 'No hay destino'
                        };
                    });
                    this.routes_count = routes.total_count;
                    this.routesModel.records = this.routes;
                    this.routesModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: routes.count,
                        rowCount: this.routes_count
                    };
                }
            });
        this.isReset = false;
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
}
