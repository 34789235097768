<div
    class="PDF-FUEC"
    id="pdfTable"
    #pdfTable
    [style.width]="width"
    [style.height]="height"
    [style.font-size]="fontSize"
>
    <div class="pg pg-1">
        <header class="header">
            <div class="cnt cnt-1">
                <img
                    src="assets/images/Logo_mintransporte.png"
                    alt=""
                    style="width: 50%"
                />
            </div>
            <div class="cnt cnt-1">
                <img
                    src="assets/images/vigilado_supertransporte.png"
                    alt=""
                    style="width: 50%"
                />
            </div>
            <div class="cnt cnt-2">
                <img
                    *ngIf="logoAccount"
                    src="{{ 'data:image/jpg;base64,' + logoAccount }}"
                    alt=""
                    style="width: 50%; max-height: 70%"
                />
            </div>
        </header>
        <div class="content">
            <table cellspacing="0" border="0" class="table">
                <colgroup span="12" width="64"></colgroup>
                <tr>
                    <td colspan="12" rowspan="2" align="center" valign="bottom">
                        <font
                            color="#000000"
                            style="word-spacing: 0.75em; font-weight: bold"
                            >FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO
                            PÚBLICO DE <br />TRANSPORTE TERRESTRE AUTOMOTOR
                            ESPECIAL <br />
                            NO. 
                        </font>  <font style="color: red"> {{ fuec_series_format }} </font> 
                    </td>
                </tr>
                <tr></tr>
                <tr>
                    <td colspan="6" align="left" valign="bottom">
                        <font style="font-weight: bold">RAZÓN SOCIAL:</font> {{ razonsocial }}
                    </td>
                    <td colspan="6" align="right" valign="bottom">
                        <font style="font-weight: bold;">NIT: </font> {{ id_document }}
                    </td>
                </tr>
                <tr>
                    <td colspan="12" align="left" valign="bottom">
                        <font color="#000000" style="font-weight: bold;"
                            >CONTRATO No:</font> {{ contract_number }}
                    </td>
                </tr>
                <tr>
                    <td colspan="6" align="left" valign="bottom">
                        <font color="#000000" style="font-weight: bold;"
                            >CONTRATANTE: </font> {{ contractor_name }}
                    </td>
                    <td colspan="6" align="right" valign="bottom">
                        <font color="#000000" style="font-weight: bold;">NIT: </font> {{ contractor_nit }}
                    </td>
                </tr>
                <tr>
                    <td colspan="12" align="left" valign="bottom">
                        <font color="#000000" style="font-weight: bold;">OBJETO DE CONTRATO: </font>{{ contract_object }}
                    </td>
                </tr>
                <tr>
                    <td colspan="12" align="left" valign="top">
                        <font color="#000000" style="font-weight: bold;">ORIGEN - DESTINO: </font> {{ route_name }}
                    </td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td colspan="12" valign="bottom">
                        <font color="#000000" style="font-size: 1.5em; font-weight: bold;"
                            >TIPO DE CONVENIO:</font
                        >
                    </td>
                </tr>
                <tr>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="2"
                        align="center"
                        valign="bottom"
                    >
                        Convenio
                        {{ agreement === 'cv' ? agreement_desc : '' }}
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="2"
                        align="center"
                        valign="bottom"
                    >
                        Consorcio
                        {{ agreement === 'cs' ? agreement_desc : '' }}
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="2"
                        align="center"
                        valign="bottom"
                    >
                        Union temporal
                        {{ agreement === 'ut' ? agreement_desc : '' }}
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="2"
                        align="center"
                        valign="bottom"
                    >
                        Con:
                        {{ agreement === 'no-aplica' ? 'no-aplica' : '' }}
                    </td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td colspan="12" align="center" valign="bottom">
                        <font color="#000000" style="font-size: 1.5em; font-weight: bold;"
                            >Vigencia del contrato:</font
                        >
                    </td>
                </tr>
                <tr>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">FECHA INICIAL</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000">{{this.date_start_format.day}}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000">{{this.date_start_format.month}}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000">{{this.date_start_format.year}}</font>
                    </td>
                </tr>
                <tr>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">FECHA DE VENCIMIENTO</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000">{{this.date_end_format.day}}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000">{{this.date_end_format.month}}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000">{{this.date_end_format.year}}</font>
                    </td>
                </tr>
                <tr></tr>
                <tr>
                    <td colspan="12" align="center" valign="bottom">
                        <font color="#000000" style="font-size: 1.5em; font-weight: bold;"
                            >Caracteristicas del vehiculo</font
                        >
                    </td>
                </tr>
                <tr>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="2"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">Placa </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="2"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">Modelo </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="2"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">Marca</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="2"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">Clase</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="2"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">N&uacute;mero Interno </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="2"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;" >N&uacute;mero<br />Operaci&oacute;n</font>
                    </td>
                </tr>
                <tr></tr>
                <tr *ngIf="vehicle">
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        align="center"
                        valign="bottom"
                    >
                        <font color="#000000">{{
                            vehicle.registration_plate
                                ? vehicle.registration_plate
                                : ''
                        }}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        align="center"
                        valign="bottom"
                        sdval="2017"
                        sdnum="1033;"
                    >
                        <font color="#000000">{{
                            vehicle.year ? vehicle.year : ''
                        }}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        align="center"
                        valign="bottom"
                    >
                        <font color="#000000">{{
                            vehicle.model ? vehicle.model : ''
                        }}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        align="center"
                        valign="bottom"
                    >
                        <font color="#000000">BUS </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        align="center"
                        valign="bottom"
                        sdval="516"
                        sdnum="1033;"
                    >
                        <font color="#000000">{{
                            vehicle.voperacion ? vehicle.voperacion : ''
                        }}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        align="center"
                        valign="bottom"
                        sdval="204962"
                        sdnum="1033;"
                    >
                        <font color="#000000">{{
                            vehicle.noperacion ? vehicle.noperacion : ''
                        }}</font>
                    </td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr *ngFor="let driver of drivers_fuec; let i = index">
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">DATOS DEL CONDUCTOR {{ i + 1 }}</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >NOMBRES Y APELLIDOS:<br />{{ driver.name }}</font
                        >
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >N&uacute;mero Identificacion: <br />
                            {{ driver.cc }}
                        </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >N&uacute;mero Licencia: <br />{{ driver.licencia }}
                        </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >Vigencia: <br />{{ driver.vencimiento }}</font
                        >
                    </td>
                </tr>

                <tr *ngFor="let responsable of responsables">
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000" style="font-weight: bold;">RESPONSABLE DEL CONTRATANTE</font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="2"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >NOMBRES Y APELLIDOS: <br />{{
                                responsable.fullname
                            }}</font
                        >
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >N&uacute;mero Identificacion: <br />{{
                                responsable.doc_number
                            }}
                        </font>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >Direccion: <br />{{ responsable.address }}</font
                        >
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="3"
                        rowspan="1"
                        align="center"
                        valign="top"
                    >
                        <font color="#000000"
                            >Telefono: <br />{{ responsable.phone }}</font
                        >
                    </td>
                </tr>
                <tr>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="4"
                        rowspan="5"
                        height="13"
                        align="center"
                        valign="bottom"
                    >
                        <qr-code
                            [value]="
                                'https://fuec.monitoringinnovation.com/fuec/' +
                                fuec_series
                            "
                            [size]="sizeQr ? sizeQr : 20"
                        ></qr-code>
                    </td>
                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="4"
                        rowspan="5"
                        align="center"
                        valign="bottom"
                    >
                        <div class="razonsocial">{{ razonsocial }}</div>
                        <br />
                        {{ address }} <br />
                        {{ mobile }} <br />
                        {{ email }} <br />
                        <img
                            src="assets/images/SuperIT.png"
                            style="max-width: 80%; max-height: 90%; width: 20%"
                        />
                    </td>

                    <td
                        style="
                            border-top: 1px solid #000000;
                            border-bottom: 1px solid #000000;
                            border-left: 1px solid #000000;
                            border-right: 1px solid #000000;
                        "
                        colspan="4"
                        rowspan="5"
                        align="center"
                        valign="bottom"
                    >
                        <img
                            *ngIf="logoSignature"
                            src="{{ 'data:image/jpg;base64,' + logoSignature }}"
                            style="max-width: 80%; width: 50%"
                        />
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="pg pg-2">
        <header class="header">
            <div class="cnt cnt-1">
                <img
                    src="assets/images/Logo_mintransporte.png"
                    alt=""
                    style="width: 50%"
                />
            </div>
            <div class="cnt cnt-1">
                <img
                    src="assets/images/vigilado_supertransporte.png"
                    alt=""
                    style="width: 50%"
                />
            </div>
            <div class="cnt cnt-2">
                <img
                    *ngIf="logoAccount"
                    src="{{ 'data:image/jpg;base64,' + logoAccount }}"
                    alt=""
                    style="width: 50%; max-height: 70%"
                />
            </div>
        </header>

        <div class="content">
            <div class="title-page">
                INSTRUCTIVO PARA LA DETERMINACION DEL NUMERO CONSECUTIVO DEL
                FUEC
            </div>
            <div class="box">
                <p>
                    El Formato Unico de Extracto del Contrato "FUEC" estará
                    constituido por los siguientes números:
                </p>
                <div class="section">
                    <div class="text">
                        a) Los tres primeros dígitos de izquierda a derecha
                        correcponderán al código de la Dirección Territorial que
                        otorgó la habilitación o de aquella a la cual se hubiese
                        trasladado la Empresa de Servicio público de Transporte
                        Terrestre Automotor Especial;
                    </div>
                    <div class="content-table">
                        <table>
                            <tr>
                                <td>
                                    <div>Antioquia-Choco</div>
                                    <div>305</div>
                                </td>
                                <td>
                                    <div>Huila-Caquetá</div>
                                    <div>441</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Atlántico</div>
                                    <div>208</div>
                                </td>
                                <td>
                                    <div>Magdalena</div>
                                    <div>247</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Bolívar-San Andrés y Providencia</div>
                                    <div>213</div>
                                </td>
                                <td>
                                    <div>Meta-Vaupés-Vichada</div>
                                    <div>550</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Boyacá-Casanare</div>
                                    <div>415</div>
                                </td>
                                <td>
                                    <div>Nariño-Putumayo</div>
                                    <div>352</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Caldas</div>
                                    <div>317</div>
                                </td>
                                <td>
                                    <div>N/Santander-Arauca</div>
                                    <div>454</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Caldas</div>
                                    <div>317</div>
                                </td>
                                <td>
                                    <div>N/Santander-Arauca</div>
                                    <div>454</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Cauca</div>
                                    <div>319</div>
                                </td>
                                <td>
                                    <div>Quindio</div>
                                    <div>363</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>César</div>
                                    <div>220</div>
                                </td>
                                <td>
                                    <div>Risaralda</div>
                                    <div>366</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Córdoba-Sucre</div>
                                    <div>223</div>
                                </td>
                                <td>
                                    <div>Santander</div>
                                    <div>468</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Cundinamarca</div>
                                    <div>425</div>
                                </td>
                                <td>
                                    <div>Tolima</div>
                                    <div>473</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Guajira</div>
                                    <div>241</div>
                                </td>
                                <td>
                                    <div>Valle del Cauca</div>
                                    <div>376</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        b) Los cuatro dígitos siguientes señalarán el número de
                        resolución mediante la cual se otorgó la habilitación de
                        la Empresa. En caso que la resolución no tenga estos
                        dígitos, los faltantes serán completados con ceros a la
                        izquierda;
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        c) Los dos siguientes dígitos, corresponderán a los dos
                        últimos del año en que la empresa fue habilitada;
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        d) Acontinuación, cuatro dígitos que corresponderán al
                        año en el que se expide el extracto del contrato;
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        e) Posteriormente, cuatro dígitos que identifican el
                        número del contrato. La numeración debe ser consecutiva,
                        establecida por cada empresa y continuará con la
                        numeración dada a los contratos de prestación del
                        servicios celebrados para el transporte de estudiantes,
                        empleados, turistas, usuarios del servicio de salud,
                        grupos específicos de usuarios, en vigencia de la
                        resolución 3068 de 2014.
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        f) Finalmente, los cuatro últimos dígitos corresponderán
                        al número consecutivo del extracto de contrato que se
                        expida para la ejecución de cada contrato. Se debe
                        expedir un nuevo extracto por vencimiento del plazo
                        inicial del mismo o por cambio del vehículo.
                    </div>
                </div>
                <span> EJEMPLO: </span>
                <div class="section">
                    <div class="text">
                        Empresa habilitada por la Dirección Territorial
                        Cundinamarca en el año 2012 con resolución de
                        habilitación No. 0155, que expide el primer extracto del
                        contrato en el año 2015, del contrato 255. El número del
                        Formato Unico de Extracto del Contrato "FUEC" será :
                        425015512201502550001.
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        <span>Nota:</span> El vehiculo se encuentra en perfecto
                        estado Mecánico y de aseo
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        Para verificar la autenticidad de esta planilla
                        consultar la pagina web
                        https://monitoringinnovation.com/fuec/{{ fuec_series }}
                    </div>
                    <div class="content-table">
                        <table>
                            <tr>
                                <td>Distancia recorrida estimada</td>
                                <td>Sin informacion</td>
                            </tr>
                            <tr>
                                <td>Duracion total estimada</td>
                                <td>Sin informacion</td>
                            </tr>
                            <tr>
                                <td>Peajes</td>
                                <td>Sin informacion</td>
                            </tr>
                            <tr>
                                <td>Consumo de combustible estimado</td>
                                <td>Sin informacion</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        <span
                            >Resol. 315/2013 Art. PROTOCOLO DE ALISTAMIENTO
                            DIARIO</span
                        >
                        Se deja constancia en este documento que participaron
                        del proceso de alistamiento diaro el(los) conductor(es)
                        mencionado(s) en este FUEC bajo la supervicion de la
                        empresa a travéz deusos tecnológicos amparados por la
                        ley 1450, art. 230 y del decreto ley 019 de 2012, art.
                        4.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
