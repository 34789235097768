<ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>

<ng-template #tableTemplate>
    <div *ngIf="!removeCard" class="card card-custom">
        <div *ngIf="model.title" class="card-header-table flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
                <h3 *ngIf="model.title" class="card-label">
                    {{ model ? model.title : 'Cargando...' }}
                    <span *ngIf="model.description" class="d-block text-muted pt-2 font-size-sm">{{ model ?
                        model.description : '' }}</span>
                </h3>
            </div>
        </div>

        <div class="card-body">
            <ng-container [ngTemplateOutlet]="datatableTemplate"></ng-container>
        </div>
    </div>

    <ng-container *ngIf="removeCard" [ngTemplateOutlet]="datatableTemplate"></ng-container>
</ng-template>

<ng-template #datatableTemplate>
    <section class="content">
        <div class="container-fluid">
            <div class="main-card mb-3 mt-3 card">
                <div class="card-header-table">
                    <ng-container>
                        <app-pagination [model]="model" [pagination]="model.pagination" [title]="model.title"
                            (clickedPage)="clickedPage($event)" (clickedPageSize)="clickedPageSize($event)"
                            (changeSearch)="changeSearch($event)" (getTotalRecords)="getTotalRecords($event)"
                            [isReset]="isReset"></app-pagination>
                    </ng-container>
                </div>

                <ng-container>
                    <div>
                        <div class="grid table-container" [ngClass]="{'table-container-custom': isEdit}"
                            id="kt_datatable">
                            <table>
                                <thead>
                                    <tr class="header">
                                        <th *ngIf="!hideIndex">
                                            <span></span>
                                        </th>

                                        <th *ngIf="!hideIndex" data-field="RecordID" data-sort="asc">
                                            <span>#</span>
                                        </th>

                                        <th *ngIf="
                                                model.enableActions &&
                                                isEdit === true
                                            " data-field="Actions" data-autohide-disabled="false" class="acciones">
                                            <span>Acciones</span>
                                        </th>

                                        <th *ngFor="let field of model.fields" data-field="Actions"
                                            data-autohide-disabled="false">
                                            <span>
                                                <ng-container *ngIf="!field.filter">
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                            </span>
                                        </th>
                                        <th *ngIf="isContractorSelect == true" data-field="Actions" data-autohide-disabled="false" class="acciones">
                                            <span>Asignación</span>
                                        </th>
                                        <th *ngIf="
                                                model.enableActions &&
                                                isEdit === false
                                            " data-field="Actions" data-autohide-disabled="false" class="acciones">
                                            <span>Acciones</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: #fbfbfb" *ngIf="model?.records?.length > 0">
                                    <ng-container *ngFor="
                                            let record of model.records;
                                            let i = index
                                        ">
                                        <tr data-row="10" class="tr-table" [ngStyle]="{
                                                background:
                                                    selectBackroundColor(i)
                                            }" [style.cursor]="
                                                actionRow
                                                    ? 'pointer'
                                                    : 'default'
                                            ">
                                            <td *ngIf="!hideIndex" [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                " (click)="clickedRow(i)" [style.color]="selectColor(i)">
                                                <a>
                                                    <i class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}" [style.color]="
                                                            selectColor(i)
                                                        "></i>
                                                </a>
                                            </td>

                                            <td *ngIf="!hideIndex" (click)="clickedRow(i)" data-field="RecordID"
                                                aria-label="11" [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                " [style.color]="selectColor(i)">
                                                <span [style.color]="
                                                        selectColor(i)
                                                    "><span>{{
                                                        i +
                                                        1 +
                                                        (model.pagination
                                                        .currentPage -
                                                        1) *
                                                        model.pagination
                                                        .pageSize
                                                        }}</span></span>
                                            </td>

                                            <td *ngIf="model.enableActions && isEdit === true">
                                                <ng-container>
                                                    <ng-container *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "></ng-container>
                                                </ng-container>
                                            </td>

                                            <td [style.cursor]="actionRow ? 'pointer' : 'default'"
                                                (click)="clickedRow(i)" *ngFor="let field of model.fields"
                                                aria-label="Littel and Sons" [style.color]="selectColor(i)">

                                                <!-- Implementation when row is selected -->
                                                <ng-container *ngIf="selectedRowEdit === i">
                                                    <ng-container
                                                        *ngIf="isLastThreeFields(field, model.fields); else textInput">
                                                        <input class="inputRecords" type="date"
                                                            [(ngModel)]="record[field.key]" />
                                                    </ng-container>
                                                    <ng-template #textInput>
                                                        <input class="inputRecords" type="text"
                                                            [(ngModel)]="record[field.key]" />
                                                    </ng-template>
                                                </ng-container>

                                                <!-- Application when no row is selected -->
                                                <ng-container *ngIf="selectedRowEdit !== i">
                                                    <ng-container *ngIf="!field.type">
                                                        <ng-container *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        ">
                                                            <ng-container *ngIf="!field.date && !field.label"
                                                                [style.color]="selectColor(i)">
                                                                {{record[field.key][field.subkey]}}
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        ">
                                                            <ng-container *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool
                                                            " [style.color]="
                                                                selectColor(
                                                                    i
                                                                )
            ">
                                                                <ng-container
                                                                    *ngIf="field.key !== 'soat' && field.key !== 'tecnomecanica' && field.key !== 'voperacion'">
                                                                    {{record[field.key]}}
                                                                </ng-container>

                                                                <!-- SOAT -->
                                                                <ng-container *ngIf="field.key === 'soat'">
                                                                    <ng-container *ngIf="getDates(record[field.key]) === 'invalid'">
                                                                        <svg class="alertIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                                            <title>Fecha incorrecta. Por favor, verifique.</title>
                                                                            <path d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="getDates(record[field.key]) === 'expired'">
                                                                        <svg class="alertIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                                            <title>SOAT vencido. Renueva para evitar sanciones.</title>
                                                                            <path d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="getDates(record[field.key]) === 'soon'">
                                                                        <svg class="alertIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                                            <title>SOAT próximo a vencer. Renueva para evitar sanciones.</title>
                                                                            <path d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="getDates(record[field.key]) === 'valid'">
                                                                        {{ record[field.key] }}
                                                                    </ng-container>
                                                                </ng-container>

                                                                <!-- Tecnomecanica -->
                                                                <ng-container *ngIf="field.key === 'tecnomecanica'">
                                                                    <ng-container *ngIf="getDates(record[field.key]) === 'invalid'">
                                                                        <svg class="alertIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                                            <title>Fecha incorrecta. Por favor, verifique.</title>
                                                                            <path d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getDates(record[field.key]) === 'expired'">
                                                                        <svg class="alertIcon"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 100 100">
                                                                            <title>Tecnomecánica vencida. Renueva para evitar sanciones.</title>
                                                                            <path class="st0"
                                                                                d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getDates(record[field.key]) === 'soon'">
                                                                        <svg class="alertIcon"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 100 100">
                                                                            <title>Tecnomecánica próxima a vencer. Renueva para evitar sanciones.</title>
                                                                            <path class="st0"
                                                                                d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getDates(record[field.key]) === 'valid'">
                                                                        {{ record[field.key] }}
                                                                    </ng-container>
                                                                </ng-container>

                                                                <!-- Voperacion -->
                                                                <ng-container *ngIf="field.key === 'voperacion'">
                                                                    <ng-container *ngIf="getDates(record[field.key]) === 'invalid'">
                                                                        <svg class="alertIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                                            <title>Fecha incorrecta. Por favor, verifique.</title>
                                                                            <path d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getDates(record[field.key]) === 'expired'">
                                                                        <svg class="alertIcon"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 100 100">
                                                                            <title>Voperación vencido. Renueva para evitar sanciones.</title>
                                                                            <path
                                                                                d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getDates(record[field.key]) === 'soon'">
                                                                        <svg class="alertIcon"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 100 100">
                                                                            <title>Voperación próxima a vencer. Renueva para evitar sanciones.</title>
                                                                            <path
                                                                                d="M58,53.9H42v-32h16V53.9z M58,62.4H42v16h16V62.4z M50,11.9c-21,0-38,17-38,38s17,38,38,38s38-17,38-38 S70.9,11.9,50,11.9 M50-0.1c27.6,0,50,22.4,50,50s-22.4,50-50,50S0,77.5,0,49.9S22.3-0.1,50-0.1L50-0.1z" />
                                                                        </svg>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="getDates(record[field.key]) === 'valid'">
                                                                        {{ record[field.key] }}
                                                                    </ng-container>
                                                                </ng-container>

                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </td>

                                            <td *ngIf="isContractorSelect" class="contractorSelect">
                                                <ng-container>
                                                    <select (click)="onSelectClick(record)" (change)="onContractorSelect(record, $event.target.value)" (blur)="resetContractors()">
                                                        <option *ngIf="record.contractor_id" selected="{{record.contractor_id}}" value={{record.contractor_id}}>{{record.contrator_name}}</option>
                                                        <option value="">Ningún contratista</option>
                                                        <ng-container>
                                                            <option *ngFor="let contractor of contractorsView" [value]="contractor.id" [selected]="contractor.id === record.contractor_id">
                                                                {{ contractor.fullname }}
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                </ng-container>
                                            </td>
                                            
                                            <td *ngIf="model.enableActions && isEdit === false">
                                                <ng-container>
                                                    <ng-container *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "></ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
</ng-template>