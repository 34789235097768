import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ResponsibleService {
    constructor(private http: HttpClient) {}

    async getResponsiblesFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let responsibles = await this.http
            .get<any>(
                `/api/fuec/responsibles/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!responsibles.error) {
            return responsibles;
        } else {
            return null;
        }
    }

    async createResponsible(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/responsibles/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async updateResponsible(params) {
        let post = await this.http
            .post<any>('/api/fuec/responsibles/update', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async deleteResponsible(id) {
        let fuecs = await this.http
            .get<any>(`/api/fuec/responsibles/delete/id=${id}`)
            .toPromise();
        if (!fuecs.error) {
            return fuecs;
        } else {
            return null;
        }
    }

    async updateFuecBackupResponsible(params) {
        try {
            let post = await this.http
                .post<any>('/api/fuec/update-fuec-backup-responsible', {
                    jsonrpc: '2.0',
                    params: params
                })
                .toPromise();
            let response;
            if (!post.error) {
                response = {
                    msg: 'Backup actualizado correctamente'
                };
                return response;
            } else {
                response = {
                    error: post.error
                };
                return response;
            }
        } catch (error) {
            console.error('Error al actualizar el backup', error);
            return {
                error: 'Ocurrió un error al actualizar el backup'
            };
        }
    }
}
