import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RouteService {
    constructor(private http: HttpClient) {}

    async getRoutesFuecs(page?: number, pageSize?: number, searchby?: string) {
        let routes = await this.http
            .get<any>(
                `/api/fuec/routes/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!routes.error) {
            return routes;
        } else {
            return null;
        }
    }

    async createRoutes(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/routes/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async updateRoute(params) {
        let post = await this.http
            .post<any>('/api/fuec/routes/update', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async deleteRoute(id) {
        let fuecs = await this.http
            .get<any>(`/api/fuec/routes/delete/id=${id}`)
            .toPromise();
        if (!fuecs.error) {
            return fuecs;
        } else {
            return null;
        }
    }

    async updateFuecBackupRoute(params) {
        try {
            let post = await this.http
                .post<any>('/api/fuec/update-fuec-backup-route', {
                    jsonrpc: '2.0',
                    params: params
                })
                .toPromise();

            let response;
            if (!post.error) {
                response = {
                    msg: 'Backup actualizado correctamente'
                };
                return response;
            } else {
                response = {
                    error: post.error
                };
                return response;
            }
        } catch (error) {
            console.error('Error al actualizar el backup:', error);
            return {
                error: 'Ocurrió un error al actualizar el backup'
            };
        }
    }
}
