<form [formGroup]="contractorsForm" (ngSubmit)="createContract()" class="form-container">
    <div class="grid-2col inputs-container">
        <div class="input-div">
            <div class="add-input">
                <label for="company_name">
                    <div class="label-title gray1">Nombre de compañia</div>
                </label>
                <input
                    id="company_name"
                    type="text"
                    placeholder="Empresa"
                    class="label-title gray1 i-select"
                    formControlName="company_name"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="nit">
                    <div class="label-title gray1">Número de identificación</div>
                </label>
                <input
                    id="nit"
                    type="text"
                    placeholder="Identificacion"
                    class="label-title gray1 i-select"
                    formControlName="nit"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="nombre">
                    <div class="label-title gray1">Nombre de contratista</div>
                </label>
                <input
                    id="nombre"
                    type="text"
                    placeholder="Nombre"
                    class="label-title gray1 i-select"
                    formControlName="name"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="email">
                    <div class="label-title gray1">Email</div>
                </label>
                <input
                    id="email"
                    type="text"
                    placeholder="email"
                    class="label-title gray1 i-select"
                    formControlName="email"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="address">
                    <div class="label-title gray1">Dirección</div>
                </label>
                <input
                    id="address"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="address"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="city">
                    <div class="label-title gray1">Ciudad</div>
                </label>
                <input
                    id="city"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="city"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="phone">
                    <div class="label-title gray1">Celular</div>
                </label>
                <input
                    id="phone"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="phone"
                />
            </div>
        </div>
    </div>
    <div class="center">
        <button class="btn3" type="submit">Crear contratista</button>
    </div>
</form>
