<div class="content-app-my">
  
  <app-header></app-header>
  <app-menu-sidebar></app-menu-sidebar>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
  <app-footer class="main-footer"></app-footer>

</div>
