import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    TemplateRef,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef
} from '@angular/core';
import {TableViewModel} from '../models/table';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
    @Input()
    minHeight = 290;

    @Input()
    editIndexExternalUnit: number = null;

    @Input()
    pageSize = 5;

    @Input()
    hideIndex: boolean = false;

    @Input()
    actionRow: boolean = false;

    @Input()
    selectedRow: [] = [];

    @Input()
    isEdit: boolean = false;

    @Input()
    isContractorSelect: boolean = false;

    @Input()
    contractors: any[] = [];
    contractorsLoaded: boolean = false;

    @Input()
    model: TableViewModel;

    @Input()
    removeCard: boolean;

    @Input()
    isReset: boolean;
    @Output() onEditEvent = new EventEmitter<any>();

    @Output()
    rowEvent = new EventEmitter<any>();

    @Output()
    detailInfoEvent = new EventEmitter<any>();

    @Output()
    getRecords = new EventEmitter<any>();

    @Output()
    paginatorEvent = new EventEmitter<number>();

    @Output()
    pageSizeEvent = new EventEmitter<number>();

    @Output()
    searchEvent = new EventEmitter<string>();

    @Input()
    actionsTemplate: TemplateRef<any>;

    @Output()
    contractorSelectEvent = new EventEmitter<{
        record: any;
        contractorId: number;
    }>();

    index: any;
    selectedRowEdit: number;
    isSelectClicked: boolean = false;
    contractorsView: any[] = [];

    ngOnInit(): void {}

    constructor(private cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['editIndexExternalUnit'] &&
            !changes['editIndexExternalUnit'].isFirstChange()
        ) {
            if (changes['editIndexExternalUnit'].currentValue < 0) {
                this.selectedRowEdit = null;
                return;
            }
            if (this.isEdit) {
                this.selectedRowEdit =
                    changes['editIndexExternalUnit'].currentValue;
            }
        }
    }

    clickedRow(index: number) {
        if (this.actionRow) {
            this.rowEvent.emit(this.model.records[index]);
        }
    }

    clickedPage(page: number) {
        this.paginatorEvent.emit(page);
    }

    clickedPageSize(pageSize: number) {
        this.pageSizeEvent.emit(pageSize);
    }

    getTotalRecords(header: any) {
        this.getRecords.emit(header);
    }

    changeSearch(searchby: string) {
        this.searchEvent.emit(searchby);
    }

    selectColor(i: number) {
        try {
            if (this.actionRow) {
                var selected = this.selectedRow.find((index) => index === i);
                if (!selected && selected !== 0) {
                    return '';
                } else {
                    return '#ffffff';
                }
            }
        } catch {
            return '';
        }
    }

    selectBackroundColor(i: number) {
        try {
            var selected = this.selectedRow.find(
                (driverId) => driverId === this.model.records[i].id
            );
            if (!selected && selected !== 0) {
                return '';
            } else {
                return '#c6007e';
            }
        } catch {
            return '';
        }
    }

    isLastThreeFields(field: any, fields: any[]): boolean {
        const lastThreeIndices = fields.slice(-3).map((f) => f.key);
        return lastThreeIndices.includes(field.key);
    }

    getDates(date: string): string {
        const today = new Date();
        const fieldDate = new Date(date);

        const proximityDate = new Date(today);
        proximityDate.setDate(today.getDate() + 5);

        const formattedToday = today
            .toISOString()
            .split('T')[0]
            .replace(/-/g, '/');
        const formattedFieldDate = fieldDate
            .toISOString()
            .split('T')[0]
            .replace(/-/g, '/');

        if (formattedFieldDate === '0001/01/01') {
            return 'invalid';
        } else if (formattedFieldDate < formattedToday) {
            return 'expired';
        } else if (
            formattedFieldDate <=
            proximityDate.toISOString().split('T')[0].replace(/-/g, '/')
        ) {
            return 'soon';
        }
        return 'valid';
    }

    onContractorSelect(record: any, contractorId: number) {
        record.contrator_name = "Loading...";
        this.contractorSelectEvent.emit({record, contractorId});
        setTimeout(() => {
            this.resetContractors();
        }, 200);
    }

    onSelectClick(record: any) {
        this.contractorsView = this.contractors.filter(contractor => contractor.id !== record.contractor_id);
    }

    resetContractors() {
        this.contractorsView = [];
    }


}
