import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MinTransporte} from 'assets/images/MinTransporte';
import {vigiladoTransporte} from 'assets/images/vigiladoTransporte';
import {ActivatedRoute, Router} from '@angular/router';
import {FuecService} from '@services/fuec.service';
import {superIT} from 'assets/images/superIT';
import pdfMake from 'pdfmake/build/pdfmake';
import {Vehicle} from '@/models/vehicle';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-fuec-detail',
    templateUrl: './fuec-detail.component.html',
    styleUrls: ['./fuec-detail.component.scss']
})
export class FuecDetailComponent implements OnInit {
    isHovered: boolean = false;
    account: any;
    responsibles: any;
    routes: any;
    contractors: any;
    drivers: any;
    units: any;
    drivers_count: number;
    vehicle: Vehicle;
    route_name: string;
    contractor_name: string;
    contractor_nit: string;
    contract_object: string;

    public fuecForm: FormGroup;
    public myAngularxQrCode: string = null;
    public currentYear: string = DateTime.now().toFormat('y');
    logo: string = 'assets/images/vigilado_supertransporte.png';

    vehiculo = new FormControl();
    responsables = new FormControl();

    date_hab: string;
    current_year: string = new Date(Date.now()).getFullYear().toString();
    fuec_contract_number: string;
    contract_number: string;
    end_date: {
        year: string;
        month: string;
        day: string;
    };

    start_date: {
        year: string;
        month: string;
        day: string;
    };

    fuec: any;
    notFound: boolean = false;
    fuec_series_format: string = '';

    @ViewChild('FuectPrint') pdfTable: ElementRef;

    insertLineBreaks(text, maxLength) {
        let result = '';
        for (let i = 0; i < text.length; i += maxLength) {
            result += text.slice(i, i + maxLength) + '\n';
        }
        return result.trim();
    }

    parseBackup(routeBackup, type) {
        try {
            const conv = JSON.parse(routeBackup);
            if (conv[type] && conv[type] !== '') {
                return conv[type];
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fuecService: FuecService
    ) {}

    ngOnInit(): void {
        this.fuecForm = new FormGroup({
            fuec_series: new FormControl(null, Validators.required)
        });
        this.getFuec();
    }

    onMouseEnter() {
        this.isHovered = true;
    }

    onMouseLeave() {
        this.isHovered = false;
    }

    navigateToRoute() {
        this.router.navigate(['/history']);
    }

    getFuec() {
        const id = this.route.snapshot.paramMap.get('id');
        this.fuecService.getFuecDetail(id).then((fuec) => {
            if (fuec) {
                this.notFound = false;
                this.fuec = fuec.result;
                this.fuecForm.controls['fuec_series'].setValue(
                    this.fuec.fuec_series
                );
                const dateStart = this.fuec.date_start.split('-');
                this.start_date = {
                    year: dateStart[0],
                    month: dateStart[1],
                    day: dateStart[2]
                };

                const dateEnd = this.fuec.date_end.split('-');
                this.end_date = {
                    year: dateEnd[0],
                    month: dateEnd[1],
                    day: dateEnd[2]
                };
            } else {
                this.notFound = true;
            }
        });
    }

    searchFuec() {
        this.router
            .navigate([`/fuec/${this.fuecForm.value.fuec_series}`])
            .then(() => {
                this.getFuec();
            });
    }

    formatFuecSeries(): string {
        const separate = this.fuec.fuec_series.split('');
        let num1 = '';
        for (var i = 0; i <= 2; i++) {
            num1 = `${num1}${separate[i]}`;
        }
        let num2 = '';
        for (var i = 3; i <= 6; i++) {
            num2 = `${num2}${separate[i]}`;
        }
        let num3 = '';
        for (var i = 7; i <= 8; i++) {
            num3 = `${num3}${separate[i]}`;
        }
        let num4 = '';
        for (var i = 9; i <= 12; i++) {
            num4 = `${num4}${separate[i]}`;
        }
        let num5 = '';
        for (var i = 13; i <= 16; i++) {
            num5 = `${num5}${separate[i]}`;
        }
        let num6 = '';
        for (var i = 17; i <= 20; i++) {
            num6 = `${num6}${separate[i]}`;
        }
        return (this.fuec_series_format = `${num1} ${num2} ${num3} ${num4} ${num5} ${num6}`);
    }

    openPDF() {
        const emailText = this.insertLineBreaks(this.fuec.account.email, 25);
        const getFontSize = (text, maxLength, defaultSize, minSize) => {
            return text.length > maxLength ? minSize : defaultSize;
        };

        var json_drivers = [];
        json_drivers = this.fuec.drivers.map((driver, index) => {
            const data = [
                {
                    text: `DATOS DEL CONDUCTOR ${index + 1}`,
                    bold: true,
                    fontSize: 9,
                    alignment: 'center',
                    margin: [0, 5]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'NOMBRES Y APELLIDOS',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text: driver.name,
                                    alignment: 'center',
                                    fontSize: getFontSize(
                                        driver.name,
                                        20,
                                        10,
                                        6
                                    )
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'No. CEDULA',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text: driver.cc,
                                    alignment: 'center'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'No. LICENCIA',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text: driver.licencia,
                                    alignment: 'center'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'VIGENCIA',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text: driver.vencimiento,
                                    alignment: 'center'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                }
            ];
            return data;
        });

        let json_responsibles = [];

        json_responsibles = this.fuec.responsibles.map((responsible) => {
            const data = [
                {
                    text: 'RESPONSABLE DEL CONTRATANTE',
                    bold: true,
                    fontSize: 9,
                    alignment: 'center',
                    margin: [0, 5]
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'NOMBRES Y APELLIDOS',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text:
                                        this.parseBackup(
                                            this.fuec.responsible_ids_backup,
                                            'name'
                                        ) || responsible.fullname,
                                    alignment: 'center',
                                    fontSize: getFontSize(
                                        this.parseBackup(
                                            this.fuec.responsible_ids_backup,
                                            'name'
                                        ) || responsible.fullname,
                                        20,
                                        10,
                                        6
                                    )
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'No. CEDULA',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text:
                                        this.parseBackup(
                                            this.fuec.responsible_ids_backup,
                                            'nit'
                                        ) || responsible.doc_number,
                                    alignment: 'center'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'DIRECCIÓN',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text:
                                        this.parseBackup(
                                            this.fuec.responsible_ids_backup,
                                            'address'
                                        ) || responsible.address,
                                    alignment: 'center'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: 'TELÉFONO',
                                    bold: true,
                                    alignment: 'center',
                                    fontSize: 8
                                }
                            ],
                            [
                                {
                                    text:
                                        this.parseBackup(
                                            this.fuec.responsible_ids_backup,
                                            'phone'
                                        ) || responsible.phone,
                                    alignment: 'center'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                }
            ];
            return data;
        });

        var docDefinition = {
            pageMargins: [30, 90, 40, 60],
            header: {
                columns: [
                    {
                        image: MinTransporte,
                        fit: [110, 80],
                        alignment: 'center',
                        margin: [0, 30, 0, 5]
                    },
                    {
                        image: vigiladoTransporte,
                        fit: [110, 80],
                        alignment: 'center',
                        margin: [0, 40, 0, 5]
                    },
                    {
                        image: `data:image/jpeg;base64,${this.fuec.account.logo}`,
                        fit: [100, 70],
                        alignment: 'center',
                        margin: [0, 20, 0, 5]
                    }
                ],
                widths: ['33.33%', '33.33%', '33.33%']
            },
            content: [
                {
                    text: [
                        `FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO PÚBLICO DE \n TRANSPORTE TERRESTRE AUTOMOTOR ESPECIAL \n No `,
                        {
                            text: this.formatFuecSeries(),
                            color: '#FE0000'
                        }
                    ],
                    alignment: 'center',
                    margin: [0, 0, 0, 10],
                    fontSize: 13.5,
                    bold: true
                },
                {
                    columns: [
                        {
                            text: [
                                {text: 'RAZÓN SOCIAL: ', bold: true},
                                {text: this.fuec.account.razonsocial}
                            ],
                            fontSize: 11,
                            alignment: 'left'
                        },
                        {
                            text: [
                                {text: ' NIT: ', bold: true},
                                {text: this.fuec.account.id_document}
                            ],
                            fontSize: 11,
                            alignment: 'right'
                        }
                    ],
                    margin: [0, 3]
                },
                {
                    columns: [
                        {
                            text: [
                                {text: 'CONTRATANTE: ', bold: true},
                                {
                                    text: this.parseBackup(
                                        this.fuec.contractor_backup,
                                        'company_name'
                                    )
                                        ? this.parseBackup(
                                              this.fuec.contractor_backup,
                                              'company_name'
                                          )
                                        : this.fuec.contractor.company_name
                                }
                            ],
                            fontSize: 11,
                            alignment: 'left'
                        },
                        {
                            text: [
                                {text: 'NIT/CC: ', bold: true},
                                {
                                    text:
                                        this.parseBackup(
                                            this.fuec.contractor_backup,
                                            'nit'
                                        ) || this.fuec.contractor.nit
                                }
                            ],
                            fontSize: 11,
                            alignment: 'right'
                        }
                    ],
                    margin: [0, 3]
                },
                {
                    columns: [
                        {
                            text: [
                                {text: 'OBJETO CONTRATO: ', bold: true},
                                {text: this.fuec.contract_object}
                            ],
                            fontSize: 11,
                            width: '70%', 
                            alignment: 'left'
                        },
                        {
                            text: [
                                {text: 'CONTRATO No: ', bold: true},
                                this.fuec.contract_number
                            ],
                            fontSize: 11,
                            width: '30%', 
                            alignment: 'right'
                        }
                    ]
                },
                {
                    text: [
                        {text: 'RUTA: ', bold: true},
                        {
                            text: this.parseBackup(
                                this.fuec.route_backup,
                                'name'
                            )
                                ? this.parseBackup(
                                      this.fuec.route_backup,
                                      'name'
                                  )
                                : this.fuec.route.name
                        }
                    ],
                    fontSize: 11,
                    margin: [0, 3]
                },
                {
                    text: [
                        {text: 'DESCRIPCIÓN RUTA: ', bold: true},
                        {
                            text: this.parseBackup(
                                this.fuec.route_backup,
                                'detail'
                            )
                                ? this.parseBackup(
                                      this.fuec.route_backup,
                                      'detail'
                                  )
                                : this.fuec.route.description
                        }
                    ],
                    fontSize: 11,
                    margin: [0, 3]
                },
                {
                    text: [
                        {text: 'ORIGEN - DESTINO: ', bold: true},
                        {
                            text:
                                this.parseBackup(
                                    this.fuec.route_backup,
                                    'origin'
                                ) ||
                                this.parseBackup(
                                    this.fuec.route_backup,
                                    'destination'
                                )
                                    ? `${
                                          this.parseBackup(
                                              this.fuec.route_backup,
                                              'origin'
                                          ) ||
                                          this.fuec.route.origin ||
                                          this.fuec.route.name
                                      } - ${
                                          this.parseBackup(
                                              this.fuec.route_backup,
                                              'destination'
                                          ) ||
                                          this.fuec.route.destination ||
                                          this.fuec.route.name
                                      }`
                                    : this.fuec.route.origin ||
                                      this.fuec.route.destination
                                    ? `${
                                          this.fuec.route.origin ||
                                          this.fuec.route.name
                                      } - ${
                                          this.fuec.route.destination ||
                                          this.fuec.route.name
                                      }`
                                    : this.fuec.route.name
                        }
                    ],
                    fontSize: 11,
                    margin: [0, 3]
                },

                {
                    text: [{text: 'TIPO DE CONVENIO: ', bold: true}, ''],
                    fontSize: 11,
                    margin: [0, 5, 0, 0]
                },

                {
                    style: 'tableExample',
                    table: {
                        widths: ['25%', '25%', '25%', '25%'],
                        body: [
                            [
                                {
                                    text: [
                                        {text: 'Convenio \n', bold: true},
                                        this.fuec.agreement === 'cv'
                                            ? this.fuec.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: [
                                        {text: 'Consorcio \n', bold: true},
                                        this.fuec.agreement === 'cs'
                                            ? this.fuec.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: [
                                        {text: 'Unión temporal \n', bold: true},
                                        this.fuec.agreement === 'ut'
                                            ? this.fuec.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center'
                                },
                                {
                                    text: [
                                        {text: 'Otro \n', bold: true},
                                        this.fuec.agreement === 'no-aplica'
                                            ? 'no-aplica'
                                            : ''
                                    ],
                                    alignment: 'center'
                                }
                            ]
                        ]
                    }
                },
                {
                    text: 'VIGENCIA DEL CONTRATO',
                    alignment: 'center',
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 0]
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['40%', '20%', '20%', '20%'],
                        body: [
                            [
                                {text: 'FECHA INICIAL', bold: true},
                                {text: this.start_date.day},
                                {text: this.start_date.month},
                                {text: this.start_date.year}
                            ],
                            [
                                {text: 'FECHA VENCIMIENTO', bold: true},
                                {text: this.end_date.day},
                                {text: this.end_date.month},
                                {text: this.end_date.year}
                            ]
                        ]
                    }
                },
                {
                    text: 'CARACTERÍSTICAS DEL VEHÍCULO',
                    alignment: 'center',
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 0]
                },
                [
                    {
                        style: 'tableExample',
                        margin: [0, 0, 0, 15],
                        table: {
                            widths: [
                                '16.6%',
                                '16.6%',
                                '16.6%',
                                '16.6%',
                                '16.6%',
                                '16.6%'
                            ],
                            body: [
                                [
                                    {
                                        text: 'Placa',
                                        bold: true,
                                        alignment: 'center',
                                        fontSize: 11
                                    },
                                    {
                                        text: 'Modelo',
                                        bold: true,
                                        alignment: 'center',
                                        fontSize: 11
                                    },
                                    {
                                        text: 'Marca',
                                        bold: true,
                                        alignment: 'center',
                                        fontSize: 11
                                    },
                                    {
                                        text: 'Clase',
                                        bold: true,
                                        alignment: 'center',
                                        fontSize: 11
                                    },
                                    {
                                        text: 'Número Interno',
                                        bold: true,
                                        alignment: 'center',
                                        fontSize: 11
                                    },
                                    {
                                        text: 'Número Operación',
                                        bold: true,
                                        alignment: 'center',
                                        fontSize: 9
                                    }
                                ],
                                [
                                    {
                                        text:
                                            this.fuec.vehicle
                                                .registration_plate || '',
                                        alignment: 'center'
                                    },
                                    {
                                        text: this.fuec.vehicle.year || '',
                                        alignment: 'center'
                                    },
                                    {
                                        text: this.fuec.vehicle.brand || '',
                                        alignment: 'center'
                                    },
                                    {
                                        text: this.fuec.vehicle.model || '',
                                        alignment: 'center'
                                    },
                                    {
                                        text: this.fuec.vehicle.vin || '',
                                        alignment: 'center'
                                    },
                                    {
                                        text:
                                            this.fuec.vehicle.noperacion || '',
                                        alignment: 'center'
                                    }
                                ]
                            ]
                        }
                    },
                    {
                        style: 'tableExample',
                        margin: [0, 0, 0, 15],
                        table: {
                            widths: ['20%', '20%', '20%', '20%', '20%'],
                            body: [...json_drivers, ...json_responsibles]
                        }
                    }
                ],
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                {
                                    qr: `https://fuec.monitoringinnovation.com/fuec/${this.fuec.fuec_series}`,
                                    alignment: 'center',
                                    fit: 80,
                                    margin: [0, 20, 0, 20]
                                },

                                [
                                    {
                                        text: `${this.fuec.account.razonsocial} \n ${this.fuec.account.address} \n ${this.fuec.account.mobile} \n ${emailText}`,
                                        alignment: 'center',
                                        link: `mailto:${this.fuec.account.email}`,
                                        fontSize: emailText.length > 50 ? 8 : 10
                                    },
                                    {
                                        image: superIT,
                                        width: 70,
                                        alignment: 'center',
                                        colSpan: 6,
                                        margin: [0, 5]
                                    }
                                ],

                                {
                                    image: `data:image/jpeg;base64,${this.fuec.account.signature}`,
                                    alignment: 'center',
                                    width: 100,
                                    margin: [0, 45, 0, 25]
                                }
                            ]
                        ]
                    }
                },
                {
                    text: 'INSTRUCTIVO PARA LA DETERMINACIÓN DEL NÚMERO CONSECUTIVO DEL FUEC',
                    pageBreak: 'before',
                    alignment: 'center',
                    fontSize: 14,
                    bold: true
                },
                {
                    margin: [25, 10],
                    columns: [
                        [
                            {
                                text: 'El Formato Único  de Extracto del Contrato "FUEC" estará constituido por los siguientes números:',
                                fontSize: 10,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'a) Los tres primeros dígitos de izquierda a derecha correcponderán al código de la Dirección Territorial que otorgó la habilitación o de aquella a la cual se hubiese trasladado la Empresa de Servicio Público de Transporte Terrestre Automotor Especial;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Antioquia-Choco',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '305'
                                    },
                                    {
                                        width: '*',
                                        text: 'Huila-Caquetá ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '441'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Atlántico ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '208'
                                    },
                                    {
                                        width: '*',
                                        text: 'Magdalena',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '247'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Bolívar-San Andrés y Providencia',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '213'
                                    },
                                    {
                                        width: '*',
                                        text: 'Meta-Vaupés-Vichada ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '550'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Boyacá-Casanare',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '415'
                                    },
                                    {
                                        width: '*',
                                        text: 'Nariño-Putumayo',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '352'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Caldas',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '317'
                                    },
                                    {
                                        width: '*',
                                        text: 'N/Santander-Arauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '454'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '319'
                                    },
                                    {
                                        width: '*',
                                        text: 'Quindio',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '363'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'César',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '220'
                                    },
                                    {
                                        width: '*',
                                        text: 'Risaralda',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '366'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Córdoba-Sucre',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '223'
                                    },
                                    {
                                        width: '*',
                                        text: 'Santander',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '468'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cundinamarca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '425'
                                    },
                                    {
                                        width: '*',
                                        text: 'Tolima',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '473'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Guajira',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '241'
                                    },
                                    {
                                        width: '*',
                                        text: 'Valle del Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '376'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                ul: [
                                    'b) Los cuatro dígitos siguientes señalarán el número de resolución mediante la cual se otorgó la habilitación de la Empresa. En caso que la resolución no tenga estos dígitos, los faltantes serán completados con ceros a la izquierda;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'c) Los dos siguientes dígitos corresponderán a los dos últimos del año en que la empresa fue habilitada;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'd) A continuación, cuatro dígitos que corresponderán al año en el que se expide el extracto del contrato;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `e) Posteriormente, cuatro dígitos que identifican el número del contrato. La numeración debe ser consecutiva, establecida por cada empresa y continuará con la numeración dada a los contratos de prestación de los servicios celebrados para el transporte de estudiantes, empleados, turistas, usuarios del servicio de salud, grupos específicos de usuarios, en vigencia de la resolución 3068 de 2014.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `f) Finalmente, los cuatro últimos dígitos corresponderán al número consecutivo del extracto de contrato que se expida para la ejecución de cada contrato. Se debe expedir un nuevo extracto por vencimiento del plazo inicial del mismo o por cambio del vehículo.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: 'EJEMPLO:',
                                fontSize: 10,
                                bold: true
                            },
                            {
                                text: `Empresa habilitada por la Dirección Territorial Cundinamarca en el año 2012 con resolución de habilitación No. 0155, que expide el primer extracto del contrato en el año 2015, del contrato 255. El número del Formato Unico de Extracto del Contrato "FUEC" será : 425015512201502550001.
                                `,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                fontSize: 7,
                                columns: [
                                    {
                                        text: `Nota:`,
                                        width: 25,
                                        bold: true
                                    },
                                    {
                                        text: `El vehiculo se encuentra en perfecto estado Mecánico y de aseo.`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Para verificar la autenticidad de esta planilla consultar la pagina web https://fuec.monitoringinnovation.com/fuec/${this.fuec.fuec_series}.`,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: `INFORMACIÓN POR RUTA ESTIMADA`,
                                fontSize: 7,
                                bold: true,
                                alignment: 'center',
                                margin: [0, 7]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Distancia recorrida estimada`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin información`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Duración total estimada `,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin información`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Peajes`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin información`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Consumo de combustible estimado`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin información`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Resol. 315/2013 Art. PROTOCOLO DE ALISTAMIENTO DIARIO, Se deja constancia en este documento que participaron del proceso de alistamiento diario él(los) conductor(es) mencionado(s) en este FUEC bajo la supervisión de la empresa a través de usos tecnológicos amparados por la ley 1450, art. 230 y del decreto ley 019 de 2012, art. 4`,
                                fontSize: 7
                            }
                        ]
                    ]
                }
            ]
        };
        pdfMake.createPdf(docDefinition).open();
    }
}
