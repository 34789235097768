import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-fuec-format',
    templateUrl: './fuec-format.component.html',
    styleUrls: ['./fuec-format.component.scss']
})
export class FuecFormatComponent implements OnInit {
    @Input()
    logoAccount: string;

    @Input()
    logoSignature: string;

    @Input()
    fuec_series: string;

    @Input()
    razonsocial: string;

    @Input()
    address: string;

    @Input()
    mobile: string;

    @Input()
    email: string;

    @Input()
    id_document: string;

    @Input()
    contractor_name: string;

    @Input()
    contract_number: string;

    @Input()
    contractor_nit: string;

    @Input()
    contract_object: string;

    @Input()
    route_name: string;

    @Input()
    agreement: string;

    @Input()
    agreement_desc: string;

    @Input()
    date_start: string;

    @Input()
    date_end: string;

    @Input()
    width: string;

    @Input()
    height: string;

    @Input()
    sizeQr: string;

    @Input()
    fontSize: string = '0.1rem';

    @Input()
    vehicle: any;

    @Input()
    drivers_fuec: [] = [];

    @Input()
    responsables: [] = [];

    fuec_series_format: string = '';
    date_start_format = {
        year: '',
        month: '',
        day: ''
    };
    date_end_format = {
        year: '',
        month: '',
        day: ''
    }

    constructor() {}

    ngOnInit(): void {
        
        const split = this.date_start.split('-');
        this.date_start_format = {
            year: split[0],
            month: split[1],
            day: split[2]
        };
        const split2 = this.date_end.split('-');
        this.date_end_format = {
            year: split2[0],
            month: split2[1],
            day: split2[2]
        };
    
        const separate = this.fuec_series.split('');
        let num1 = '';
        for (var i = 0; i <= 2; i++) {
            num1 = `${num1}${separate[i]}`;
        }
        let num2 = '';
        for (var i = 3; i <= 6; i++) {
            num2 = `${num2}${separate[i]}`;
        }
        let num3 = '';
        for (var i = 7; i <= 8; i++) {
            num3 = `${num3}${separate[i]}`;
        }
        let num4 = '';
        for (var i = 9; i <= 12; i++) {
            num4 = `${num4}${separate[i]}`;
        }
        let num5 = '';
        for (var i = 13; i <= 16; i++) {
            num5 = `${num5}${separate[i]}`;
        }
        let num6 = '';
        for (var i = 17; i <= 20; i++) {
            num6 = `${num6}${separate[i]}`;
        }
        this.fuec_series_format = `${num1} ${num2} ${num3} ${num4} ${num5} ${num6}`;
    }
}
