import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    public units: any = null;
    public units_count: any = null;

    constructor(private http: HttpClient) {}

    async getUnitsExternalFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let units = await this.http
            .get<any>(
                `/api/fuec/unitsExternals/${page ? `page=${page}` : ``}${
                    pageSize ? `&pagesize=${pageSize}` : ``
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!units.error) {
            this.units = units.result;
            this.units_count = units.count;
            return units;
        } else {
            return null;
        }
    }

    async createUnit(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/unit/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (post.result != null) {
            var driver_id = post.result;
            return driver_id;
        } else {
            return false;
        }
    }

    async updateUnit(params: any) {
        ['soat', 'tecnomecanica', 'voperacion'].forEach((key) => {
            var date = new Date(params[key]);
            date.setUTCHours(5, 0, 0, 0);
            params[key] = date.toISOString();
        });

        let post = await this.http
            .post<any>('/api/fuec/unit/update', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();

        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async getUnits() {
        let post = await this.http
            .get<any>('/api/fuec/units_external')
            .toPromise();
        if (!post.error) {
            return post;
        } else {
            return false;
        }
    }

    async deleteUnit(id) {
        let post = await this.http
            .get<any>(`/api/fuec/unit/delete/id=${id}`)
            .toPromise();
        if (!post.error) {
            return post;
        } else {
            return null;
        }
    }

    async updateFuecBackupUnit(params) {
        try {
            let post = await this.http
                .post<any>('/api/fuec/update-fuec-backup-unit', {
                    jsonrpc: '2.0',
                    params: params
                })
                .toPromise();
            let response;
            if (!post.error) {
                response = {
                    msg: 'Backup actualizado correctamente'
                };
                return response;
            } else {
                response = {
                    error: post.error
                };
                return response;
            }
        } catch (error) {
            console.error('Error al actualizar el backup:', error);
            return {
                error: 'Ocurrió un error al actualizar el backup'
            };
        }
    }

    async loadUnits(file: FormData): Promise<any> {
        try {
            const response = await this.http
                .post<any>('/api/fuec/unit/loadData', file)
                .toPromise();
            if (response) {
                return response;
            } else {
                console.error('Error:', response.error);
                return null;
            }
        } catch (error) {
            console.error('Error al enviar el archivo:', error);
            return null;
        }
    }

    getExtension(archivo) {
        const partes = archivo.split('.');
        if (partes.length > 1) {
            var ext = partes.pop();
            return ext;
        }
        return '';
    }
}
