<div class="flag-indicator"></div>
<p class="gra1" style="text-align: center; margin-bottom: 0">
    FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO PÚBLICO DE TRANSPORTE
    TERRESTRE AUTOMOTOR ESPECIAL
</p>

<form class="crear-fuec-form" [formGroup]="fuecForm" (ngSubmit)="createFuec()">
    <input
        type="hidden"
        class="label-title gray1 i-select"
        id="token"
        formControlName="token"
        value="{{ super_token }}"
    />
    <input
        type="hidden"
        class="label-title gray1 i-select"
        id="w_uname"
        formControlName="w_uname"
        value="{{ wialon_user_name }}"
    />
    <fieldset class="grid-2col grid inputs-container">
        <div class="input-div">
            <label for="numero-contrato">
                <h3 class="label-title gray1">Nº de contrato</h3>
            </label>
            <input
                id="numero-contrato"
                type="text"
                pattern="\d*"
                placeholder="Número de contrato"
                formControlName="contract_number"
                (input)="validateNumber($event)"
                title="Por favor ingrese solo números."
            />
        </div>

        <div class="input-div grid-2col">
            <div class="add-input">
                <label for="contractors-list">
                    <h3 class="label-title gray1">Contratista</h3>
                </label>
                <input
                    type="text"
                    list="contractors_list"
                    class="label-title gray1 i-select"
                    placeholder="Contratistas"
                    formControlName="contractor_id"
                    (change)="onChangeContractorName()"
                    autocomplete="off"
                />
                <datalist
                    class="content-input"
                    id="contractors_list"
                    style="box-sizing: border-box"
                >
                    <option
                        *ngFor="let contractor of contractors"
                        [value]="contractor.id"
                    >
                        {{ contractor.fullname }}
                    </option>
                </datalist>
            </div>
            <button
                type="button"
                value="agregar-contratista"
                name="agregar-contratista"
                class="btn4 s-btn"
                (click)="openModalCreateContractor()"
            >
                Agregar
            </button>
        </div>

        <div class="input-div grid-2col">
            <div class="add-input">
                <label for="responsables-list">
                    <h3 class="label-title gray1">Responsable</h3>
                </label>
                <mat-select
                    type="text"
                    multiple
                    class="i-select i-select-material"
                    [formControl]="responsables"
                    (selectionChange)="onChangeResponsable()"
                >
                    <mat-option
                        *ngFor="let responsibles of responsibles"
                        [value]="responsibles"
                        >{{ responsibles.fullname }}</mat-option
                    >
                </mat-select>
            </div>
            <button
                type="button"
                value="agregar-responsable"
                name="agregar-responsable"
                class="btn4 s-btn"
                (click)="openModalCreateResponsible()"
            >
                Agregar
            </button>
        </div>

        <div class="input-div">
            <label for="objeto-contrato-list">
                <h3 class="label-title gray1">Objeto de contrato</h3>
            </label>
            <select
                id="objeto-contrato-list"
                type="text"
                formControlName="contract_object"
                (ngModelChange)="onChangeObjContract()"
            >
                <option value="1">
                    Contrato para transporte de estudiantes
                </option>
                <option value="2">
                    Contrato para transporte de empleados y/o contratistas
                </option>
                <option value="3">Contrato para transporte de turistas</option>
                <option value="4">
                    Contrato para un grupo específico de usuarios (Transporte de
                    particulares)
                </option>
                <option value="5">
                    Contrato para transporte de usuarios del servicio de salud
                </option>
                <option value="6">
                    Contrato para transporte empresarial para prensa, cine y
                    television
                </option>
            </select>
        </div>

        <div class="input-div grid-2col" *ngIf="routes">
            <div class="add-input">
                <label for="rutas-list">
                    <h3 class="label-title gray1">Ruta</h3>
                </label>
                <input
                    type="text"
                    list="routes_list"
                    class="label-title gray1 i-select"
                    placeholder="Rutas"
                    id="rutas-list"
                    formControlName="route_id"
                    (change)="onChangeRoute()"
                    autocomplete="off"
                />
                <datalist
                    class="content-input"
                    id="routes_list"
                    style="box-sizing: border-box"
                >
                    <option *ngFor="let route of routes" [value]="route.id">
                        {{ route.name }}
                    </option>
                </datalist>
            </div>
            <button
                type="button"
                value="agregar-ruta"
                name="agregar-ruta"
                class="btn4 s-btn"
                (click)="openModalCreateRoute()"
            >
                Agregar
            </button>
        </div>

        <div class="input-div">
            <label for="fecha-inicial">
                <h3 class="label-title gray1">Fecha inicial del contrato</h3>
            </label>
            <input
                id="fecha-inicial"
                type="date"
                placeholder="dd/mm/aaaa"
                [min]="minDate"
                [max]="maxDate"
                formControlName="date_start"
            />
        </div>

        <div class="input-div">
            <label for="fecha-vencimiento">
                <h3 class="label-title gray1">
                    Fecha de vencimiento del contrato
                </h3>
            </label>
            <input
                id="fecha-vencimiento"
                type="date"
                placeholder="dd/mm/aaaa"
                [min]="minDate"
                [max]="maxDate"
                formControlName="date_end"
                (change)="onChangeDateEnd()"
            />
        </div>

        <div class="input-div">
            <h3 class="label-title gray1">Tipo de convenio</h3>
            <div class="input-radio-div">
                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="convenio"
                        value="cv"
                        formControlName="agreement"
                    />
                    <label for="convenio">Convenio</label>
                </div>

                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="consorcio"
                        value="cs"
                        formControlName="agreement"
                    />
                    <label for="consorcio">Consorcio</label>
                </div>

                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="union-temporal"
                        value="ut"
                        formControlName="agreement"
                    />
                    <label for="union-temporal">Unión temporal</label>
                </div>

                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="no-aplica"
                        value="no-aplica"
                        formControlName="agreement"
                    />
                    <label for="no-aplica">No aplica</label>
                </div>
            </div>
        </div>
        <div class="input-div pt-4 hidden"></div>
        <ng-container *ngIf="agreement !== 'no-aplica'">
            <div class="input-div pt-4">
                <label for="union-temporal">Descripcion convenio</label>
                <input
                    type="text"
                    name="agreement_desc"
                    id="agreement_desc"
                    formControlName="agreement_desc"
                />
            </div>
        </ng-container>
    </fieldset>

    <fieldset>
        <div
            class="
                grid-2col
                inputs-container
                table-container
                vehiculo-conductor-container
            "
            style="grid-template-columns: 0.5fr 1.5fr"
        >
            <div class="vehiculo-container">
                <div>
                    <h2 class="s-subtitle red1">Vehículo</h2>
                </div>
                <ng-container *ngIf="!wiaUnitsLoaded">
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </ng-container>
                <ng-container>
                    <div class="input-div">
                        <input
                            list="vehiculos-list"
                            class="
                                selectpickerVehiculos
                                label-title
                                gray1
                                i-select
                            "
                            placeholder="Vehiculos"
                            formControlName="vehicle_id"
                            (change)="onChangeVehicle()"
                            autocomplete="off"
                        />
                        <datalist
                            class="content-input"
                            id="vehiculos-list"
                            style="box-sizing: border-box"
                        >
                            <option
                                *ngFor="let unit of units"
                                [value]="unit.name"
                            >
                                {{ unit.name }}
                            </option>
                        </datalist>
                    </div>
                </ng-container>
                <div>
                    <h3 class="xs-subtitle gray3">
                        Placa
                        <br />
                        <span>{{
                            vehicle && vehicle.registration_plate
                                ? vehicle.registration_plate
                                : '---'
                        }}</span>
                    </h3>
                </div>
                <div>
                    <h3 class="xs-subtitle gray3">
                        Modelo (Línea)
                        <br />
                        <span>{{
                            vehicle && vehicle.year ? vehicle.year : '---'
                        }}</span>
                    </h3>
                </div>
                <div>
                    <h3 class="xs-subtitle gray3">
                        Marca
                        <br />
                        <span>{{
                            vehicle && vehicle.brand ? vehicle.brand : '---'
                        }}</span>
                    </h3>
                </div>
                <h3 class="xs-subtitle gray3">
                    Número Interno
                    <br />
                    <span>{{
                        vehicle && vehicle.vin ? vehicle.vin : '---'
                    }}</span>
                </h3>
                <h3 class="xs-subtitle gray3">
                    Clase de Vehículo
                    <br />
                    <span>{{
                        vehicle && vehicle.model ? vehicle.model : '---'
                    }}</span>
                </h3>
                <h3 class="xs-subtitle gray3">
                    SOAT
                    <br />
                    <span
                        [ngStyle]="{
                            color:
                                vehicle && vehicle.soat
                                    ? this.soatDate > this.endDate
                                        ? ''
                                        : '#C6007E'
                                    : ''
                        }"
                        >{{
                            vehicle && vehicle.soat
                                ? this.soatDate > this.endDate
                                    ? this.formatDate(this.soatDate)
                                    : this.formatDate(this.soatDate) +
                                      ' no cumple'
                                : '---'
                        }}</span
                    >
                </h3>
                <h3 class="xs-subtitle gray3">
                    Revisión técnico mecánica
                    <br />
                    <span
                        [ngStyle]="{
                            color:
                                vehicle && vehicle.tecnomecanica
                                    ? this.tecnomecanicaDate > this.endDate
                                        ? ''
                                        : '#C6007E'
                                    : ''
                        }"
                        >{{
                            vehicle && vehicle.tecnomecanica
                                ? this.tecnomecanicaDate > this.endDate
                                    ? this.formatDate(this.tecnomecanicaDate)
                                    : this.formatDate(this.tecnomecanicaDate) +
                                      ' no cumple'
                                : '---'
                        }}</span
                    >
                </h3>
                <h3 class="xs-subtitle gray3">
                    Tarjeta de operación
                    <br />
                    <span
                        [ngStyle]="{
                            color:
                                vehicle && vehicle.voperacion
                                    ? this.voDate > this.endDate
                                        ? ''
                                        : '#C6007E'
                                    : ''
                        }"
                        >{{
                            vehicle && vehicle.voperacion
                                ? this.voDate > this.endDate
                                    ? this.formatDate(this.voDate)
                                    : this.formatDate(this.voDate) +
                                      ' no cumple'
                                : '---'
                        }}</span
                    >
                </h3>
            </div>

            <div class="grid inputs-container conductor-container">
                <div class="conductor-section">
                    <h2 class="s-subtitle red1">
                        Conductor
                        <br />
                    </h2>
                    <app-table
                        [model]="driversModel"
                        [removeCard]="false"
                        (paginatorEvent)="paginatorEvent($event)"
                        (pageSizeEvent)="pageSizeEvent($event)"
                        (searchEvent)="searchEvent($event)"
                        [hideIndex]="true"
                        [actionRow]="true"
                        (rowEvent)="selectDriver($event)"
                        [selectedRow]="drivers_selected"
                    ></app-table>
                </div>
                <div class="grid input-archivo firma-div">
                    <div>
                        <img
                            *ngIf="account"
                            src="{{
                                account.signature
                                    ? 'data:image/jpg;base64,' +
                                      account.signature
                                    : 'assets/img/signature.png'
                            }}"
                            style="width: auto; height: 100px"
                        />
                        <div class="input-div">
                            <button
                                type="submit"
                                class="btn3"
                                [type]="'submit'"
                            >
                                Crear FUEC
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </fieldset>
</form>

<div style="display: none">
    <div class="PDF-FUEC" id="pdfTable" #pdfTable *ngIf="account">
        <app-fuec-format
            [logoAccount]="account.logo"
            [logoSignature]="account.signature"
            [fuec_series]="fuec_contract_number"
            [razonsocial]="account.razonsocial"
            [address]="account.address"
            [mobile]="account.mobile"
            [email]="account.email"
            [id_document]="account.id_document"
            [contract_number]="contract_number"
            [contractor_name]="contractor_name"
            [contractor_nit]="contractor_nit"
            [contract_object]="contract_object"
            [route_name]="route_name"
            [agreement]="agreement"
            [agreement_desc]="agreement_desc"
            [date_start]="date_start"
            [date_end]="date_end"
            [vehicle]="vehicle"
            [drivers_fuec]="drivers_fuec"
            [responsables]="responsables.value"
            [width]="'612px'"
            [height]="'1580px'"
            [fontSize]="'0.5rem'"
            [sizeQr]="'100'"
        ></app-fuec-format>
    </div>
</div>

<ng-template [ngIf]="show_modal === true && show_view_contractor === true">
    <app-modal [closeModal]="true" (closeFunction)="closeAllModals()">
        <app-contractor (emitEvent)="getContractors()"></app-contractor>
    </app-modal>
</ng-template>

<ng-template [ngIf]="show_modal === true && show_view_route === true">
    <app-modal [closeModal]="true" (closeFunction)="closeAllModals()">
        <app-route (emitEvent)="getRoutes()"></app-route>
    </app-modal>
</ng-template>

<ng-template [ngIf]="show_modal === true && show_view_responsible === true">
    <app-modal [closeModal]="true" (closeFunction)="closeAllModals()">
        <app-responsible (emitEvent)="getResponsibles()"></app-responsible>
    </app-modal>
</ng-template>
