import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private toastr: ToastrService,
        private appService: AppService
    ) {}

    ngOnInit() {
        this.loginForm = new FormGroup({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required)
        });
    }

    async loginByAuth() {
        if (this.loginForm.valid) {
            await this.appService.loginByAuth(this.loginForm.value);
        } else {
            this.toastr.error('Formulario invalido');
        }
    }
}
