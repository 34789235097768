<form [formGroup]="responsiblesForm" (ngSubmit)="createResponsible()" class="form-container">
    <div class="grid-2col inputs-container">
        <div class="input-div">
            <div class="add-input">
                <label for="nombre">
                    <div class="label-title gray1">Nombre de responsable</div>
                </label>
                <input
                    id="nombre"
                    type="text"
                    placeholder="Nombre"
                    class="label-title gray1 i-select"
                    formControlName="name"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="id_type">
                    <div class="label-title gray1">Tipo de documento</div>
                </label>
                <select id="id_type" type="text" formControlName="id_type" class="i-select">
                    <option value="CC">CEDULA DE CIUDADANÍA</option>
                    <option value="CE">CEDULA DE EXTRANJERÍA</option>
                    <option value="PA">PASAPORTE</option>
                    <option value="SC">SALVO CONDUCTO</option>
                    <option value="RC">REGISTRO CIVIL</option>
                    <option value="PE">PERMISO ESPECIAL DE PERMANENCIA</option>
                    <option value="TI">TARJETA DE IDENTIDAD</option>
                    <option value="AS">ADULTO SIN IDENTIFICAR</option>
                    <option value="MS">MENOR SIN IDENTIFICAR</option>
                </select>
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="nit">
                    <div class="label-title gray1">Número de identificación</div>
                </label>
                <input
                    id="nit"
                    type="text"
                    placeholder="Identificacion"
                    class="label-title gray1 i-select"
                    formControlName="nit"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="email">
                    <div class="label-title gray1">Email</div>
                </label>
                <input
                    id="email"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="email"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="city">
                    <div class="label-title gray1">Ciudad</div>
                </label>
                <input
                    id="city"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="city"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="address">
                    <div class="label-title gray1">Dirección</div>
                </label>
                <input
                    id="address"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="address"
                />
            </div>
        </div>
        <div class="input-div">
            <div class="add-input">
                <label for="phone">
                    <div class="label-title gray1">Celular</div>
                </label>
                <input
                    id="phone"
                    type="text"
                    class="label-title gray1 i-select"
                    formControlName="phone"
                />
            </div>
        </div>
    </div>
    <div class="center">
        <button type="submit" class="btn3">Crear responsable</button>
    </div>
</form>
