import {ContextService} from '@services/context.service';
import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {Account} from '@/models/account';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-manage-account',
    templateUrl: './manage-account.component.html',
    styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
    account: Account = null;
    signatureFile: File;
    signatureName: string;
    logoUploading: boolean = false;
    signatureUploading: boolean = false;
    missingFields: string[] = [];

    constructor(
        private appService: AppService,
        private contextService: ContextService,
        private router: Router,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state) {
            this.missingFields = navigation.extras.state['missingFields'];
        }
    }

    ngOnInit(): void {
        this.contextService.initialStateDrivers();
        this.appService
            .getAccountInfo()
            .then((account) =>
                account ? (this.account = account) : this.logout()
            );
    }

    logout() {
        this.appService.logout();
    }

    removeError(field: string, inputId: string): void {
        const inputElement = document.getElementById(inputId);
        if (inputElement) {

            inputElement.style.borderColor = '#8c8c8c40';
    
            setTimeout(() => {
                this.missingFields = this.missingFields.filter(item => item !== field);
                inputElement.classList.remove('input-error');
                inputElement.style.borderColor = ''; 
            }, 1000); 
        }
    }

    handleUploadLogo(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.appService.uploadLogo(reader.result.toString()).then((res) => {
                this.appService
                    .getAccountInfo()
                    .then((account) =>
                        account ? (this.account = account) : this.logout()
                    );
            });
        };
    }

    async handleUploadSignature(event: Event) {
        try {
            const file = (event.target as HTMLInputElement).files?.[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                try {
                    await this.appService.uploadSignature(
                        reader.result.toString()
                    );
                    const account = await this.appService.getAccountInfo();
                    if (account) {
                        this.account = account;
                    } else {
                        this.logout();
                    }
                } catch (error) {
                    console.error(
                        'Error while uploading signature or fetching account info:',
                        error
                    );
                }
            };
        } catch (error) {
            console.error('Error handling file upload:', error);
        }
    }

    onChangeResolution(event) {
        var value = event;
        this.account.fuec_resolucion = this.appService.padLeadingZeros(
            value,
            4
        );
    }

    updateAccount() {
        Swal.fire({
            title: 'Estas seguro?',
            text: 'Se actualizara la informacion de la cuenta',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar!',
            cancelButtonText: 'No, Cancelar.'
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService.updateAccountInfo(this.account).then((data) => {
                    this.appService.getAccountInfo().then((account) => {
                        account ? (this.account = account) : this.logout();
                        Swal.fire(
                            'Cuenta Actualizada',
                            'Se actualizaron los datos de su cuenta correctamente!',
                            'success'
                        );
                    });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelado',
                    'Los datos de la cuenta no han sido actualizados',
                    'error'
                );
            }
        });
    }
}
