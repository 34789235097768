import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    TemplateRef
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TableViewModel} from '../models/table';
import {Select2OptionData} from 'ng-select2';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit {

    @Input()
    minHeight = 290;

    @Input()
    pageSize = 5;

    @Input()
    hideIndex: boolean = false;

    @Input()
    actionRow: boolean = false;

    @Input()
    selectedRow: [] = [];

    @Input()
    model: TableViewModel;

    @Input()
    removeCard: boolean;

    @Input()
    isReset: boolean;

    @Output() 
    onEditEvent = new EventEmitter<any>();

    @Output()
    rowEvent = new EventEmitter<any>();

    @Output()
    getRecords = new EventEmitter<any>();

    @Output()
    paginatorEvent = new EventEmitter<number>();

    @Output()
    pageSizeEvent = new EventEmitter<number>();

    @Output()
    searchEvent = new EventEmitter<string>();

    @Input()
    actionsTemplate: TemplateRef<any>;

    index: any;

    constructor(
        private modalService: NgbModal,
    ) {}

    public exampleData: Array<Select2OptionData>;
    public value: string[];

    ngOnInit(): void {}

    clickedRow(index: number) {
        if (this.actionRow) {
            this.rowEvent.emit(this.model.records[index]);
        }
    }

    clickedPage(page: number) {
        this.paginatorEvent.emit(page);
    }

    
    clickedPageSize(pageSize: number) {
        this.pageSizeEvent.emit(pageSize);
    }

    getTotalRecords(header: any) {
        this.getRecords.emit(header);
    }
    
    changeSearch(searchby: string) {
        this.searchEvent.emit(searchby);
    }
    
    public valueChanged(event: string) {}
    
    public modelChanged(event: string) {
        this.paginatorEvent.emit();
    }
    
    open(content) {
        this.modalService.open(content, {centered: true, size: 'xl'});
        this.paginatorEvent.emit(1);
    }

    selectColor(i: number) {
        try {
            if (this.actionRow) {
                var selected = this.selectedRow.find((index) => index === i);
                if (!selected && selected !== 0) {
                    return '';
                } else {
                    return '#ffffff';
                }
            }
        } catch {
            return '';
        }
    }
    
    selectBackroundColor(i: number) {
        try {
            var selected = this.selectedRow.find((driverId) => driverId === this.model.records[i].id);
            if (!selected && selected !== 0) {
                return '';
            } else {
                return '#c6007e';
            }
        } catch {
            return '';
        }
    }
}
