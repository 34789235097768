import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {ApiService} from '@services/api.service';
import {Account} from '@/models/account';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-manage-account',
    templateUrl: './manage-account.component.html',
    styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
    account: Account = null;
    signatureFile: File;
    signatureName: string;
    logoUploading: boolean = false;
    signatureUploading: boolean = false;

    constructor(
        private appService: AppService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
      console.log("onInit manageAccount log 1");
        this.appService
            .getAccountInfo()
            .then((account) =>
                account ? (this.account = account) : this.logout()
            );
    }

    logout() {
        this.appService.logout();
    }

    onChangeResolution(event) {
        var value = event;
        this.account.fuec_resolucion = this.padLeadingZeros(value, 4);
    }

    padLeadingZeros(num, size: number) {
        var s = num + '';
        while (s.length < size) s = '0' + s;
        return s;
    }

    updateAccount() {
        Swal.fire({
            title: 'Estas seguro?',
            text: 'Se actualizara la informacion de la cuenta',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar!',
            cancelButtonText: 'No, Cancelar.'
        }).then((result) => {
            if (result.isConfirmed) {
              console.log("logout update getaccountinfo manage");
                this.appService.updateAccountInfo(this.account).then((data) => {
                    this.appService.getAccountInfo().then((account) => {
                        account ? (this.account = account) : this.logout();
                        Swal.fire(
                            'Cuenta Actualizada',
                            'Se actualizaron los datos de su cuenta correctamente!',
                            'success'
                        );
                    });
                });
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                );
            }
        });
    }

    handleUploadLogo(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.appService.uploadLogo(reader.result.toString()).then((res) => {
                this.appService
                    .getAccountInfo()
                    .then((account) =>
                        account ? (this.account = account) : this.logout()
                    );
            });
        };
    }
    handleUploadSignature(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.appService
                .uploadSignature(reader.result.toString())
                .then((res) => {
                    this.appService
                        .getAccountInfo()
                        .then((account) =>
                            account ? (this.account = account) : this.logout()
                        );
                });
        };
    }

    ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }
}
