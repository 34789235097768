import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContractorService {
    constructor(private http: HttpClient) {}

    async getContractorsFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let contractors = await this.http
            .get<any>(
                `/api/fuec/contractors/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!contractors.error) {
            return contractors;
        } else {
            return null;
        }
    }

    async createContractor(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/contractors/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async updateContractor(params) {
        let post = await this.http
            .post<any>('/api/fuec/contractors/update', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async deleteContractor(id) {
        let fuecs = await this.http
            .get<any>(`/api/fuec/contractors/delete/id=${id}`)
            .toPromise();
        if (!fuecs.error) {
            return fuecs;
        } else {
            return null;
        }
    }

    async updateFuecBackupContractor(params) {
        try {
            let post = await this.http
                .post<any>('/api/fuec/update-fuec-backup-contractor', {
                    jsonrpc: '2.0',
                    params: params
                })
                .toPromise();
            let response;
            if (!post.error) {
                response = {
                    msg: 'Backup actualizado correctamente'
                };
                return response;
            } else {
                response = {
                    error: post.error
                };
                return response;
            }
        } catch (error) {
            console.error('Error al actualizar el backup', error);
            return {
                error: 'Ocurrió un error al actualizar el backup'
            };
        }
    }

    async relationShipResponsible(responsible_id, contractor_id) {
        let post = await this.http
            .post<any>('/api/fuec/contractors/relation-ship', {
                jsonrpc: '2.0',
                params: {
                    responsible_id: responsible_id,
                    contractor_id: contractor_id
                }
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

}
