import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-route',
    templateUrl: './route.component.html',
    styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {
    @Output()
    emitEvent = new EventEmitter();

    public routesForm: FormGroup;

    constructor(private appService: ApiService, private ts: ToastrService) {}

    ngOnInit(): void {
        this.routesForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            orig: new FormControl(null, Validators.required),
            dest: new FormControl(null, Validators.required),
            desc: new FormControl(null, Validators.required),

        });
    }

    createRoutes() {
        if (this.routesForm.valid) {
            var params = {
                name: this.routesForm.value.name,
                origin: this.routesForm.value.orig,
                destination: this.routesForm.value.dest,
                detail: this.routesForm.value.desc,
            };
            this.appService.createRoutes(params).then((response) => {
                if (response.msg) {
                    this.ts.success('La ruta se creó con éxito');
                    this.emitEvent.emit();
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la creacion de la ruta'
                    );
                }
            });
        }
    }
}
