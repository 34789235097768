export class Vehicle {
  brand: string | boolean;
  id: number;
  model: string | boolean;
  name: string | boolean;
  noperacion: string | boolean;
  registration_plate: string | boolean;
  soat: Date;
  tecnomecanica: Date;
  voperacion: Date;
  year: string | boolean;
  vin: string | boolean;
  id_wialon: number | boolean;
  type: string;
}
