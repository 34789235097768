import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
// inicio servicios
import {WialonService} from '@services/wialon.service';
// fin servicios
import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from '@modules/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {NotificationsDropdownMenuComponent} from '@modules/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import {AppButtonComponent} from './components/app-button/app-button.component';

import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {UserDropdownMenuComponent} from '@modules/main/header/user-dropdown-menu/user-dropdown-menu.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageDropdownComponent} from '@modules/main/header/language-dropdown/language-dropdown.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {QRCodeModule} from 'angular2-qrcode';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TableComponent} from './components/table/table.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {PopupSelectComponent} from './components/popup-select/popup-select.component';
import {ManageAccountComponent} from './pages/manage-account/manage-account.component';
import {ModalComponent} from './components/modal/modal.component';
import {CreateFuecComponent} from './pages/create-fuec/create-fuec.component';
import {ManageFuecComponent} from './pages/manage-fuec/manage-fuec.component';
import {ManageResponsiblesComponent} from './pages/manage-responsibles/manage-responsibles.component';
import {ManageRoutesComponent} from './pages/manage-routes/manage-routes.component';
import {ManageContractorsComponent} from './pages/manage-contractors/manage-contractors.component';
import {FuecComponent} from './components/fuec/fuec.component';
import {FuecDetailComponent} from './pages/fuec-detail/fuec-detail.component';
import {RouteComponent} from './components/route/route.component';
import {ContractorComponent} from './components/contractor/contractor.component';
import {ResponsibleComponent} from './components/responsible/responsible.component';
import {FuecFormatComponent} from './components/fuec-format/fuec-format.component';

registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        MessagesDropdownMenuComponent,
        NotificationsDropdownMenuComponent,
        AppButtonComponent,
        UserDropdownMenuComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageDropdownComponent,
        PrivacyPolicyComponent,
        TableComponent,
        PaginationComponent,
        PopupSelectComponent,
        CreateFuecComponent,
        ManageAccountComponent,
        ManageFuecComponent,
        ManageResponsiblesComponent,
        ManageRoutesComponent,
        ManageContractorsComponent,
        ModalComponent,
        FuecComponent,
        FuecDetailComponent,
        RouteComponent,
        ContractorComponent,
        ResponsibleComponent,
        FuecFormatComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSelectModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        MatFormFieldModule,
        MatDatepickerModule,
        MatTableModule,
        MatPaginatorModule,
        QRCodeModule,
        InlineSVGModule
    ],
    providers: [WialonService],
    bootstrap: [AppComponent]
})
export class AppModule {}
