<ng-template #contentPdf>
    <app-fuec-format
        [logoAccount]="fuec.account.logo"
        [logoSignature]="fuec.account.signature"
        [fuec_series]="fuec.fuec_series"
        [razonsocial]="fuec.account.razonsocial"
        [address]="fuec.account.address"
        [mobile]="fuec.account.mobile"
        [email]="fuec.account.email"
        [id_document]="fuec.account.id_document"
        [contract_number]="fuec.contract_number"
        [contractor_name]="fuec.contractor.company_name"
        [contractor_nit]="fuec.contractor.nit"
        [contract_object]="fuec.contract_object"
        [route_name]="fuec.route.name"
        [agreement]="fuec.agreement"
        [date_start]="fuec.date_start"
        [date_end]="fuec.date_end"
        [vehicle]="fuec.vehicle"
        [drivers_fuec]="fuec.drivers"
        [responsables]="fuec.responsibles"
        [width]="'216px'"
        [height]="'279px'"
    ></app-fuec-format>
</ng-template>

<div style="display: none">
    <div id="FuectPrint" #FuectPrint>
        <app-fuec-format
            [logoAccount]="fuec.account.logo"
            [logoSignature]="fuec.account.signature"
            [fuec_series]="fuec.fuec_series"
            [razonsocial]="fuec.account.razonsocial"
            [address]="fuec.account.address"
            [mobile]="fuec.account.mobile"
            [email]="fuec.account.email"
            [id_document]="fuec.account.id_document"
            [contract_number]="fuec.contract_number"
            [contractor_name]="fuec.contractor.company_name"
            [contractor_nit]="fuec.contractor.nit"
            [contract_object]="fuec.contract_object"
            [route_name]="fuec.route.name"
            [agreement]="fuec.agreement"
            [date_start]="fuec.date_start"
            [date_end]="fuec.date_end"
            [vehicle]="fuec.vehicle"
            [drivers_fuec]="fuec.drivers"
            [responsables]="fuec.responsibles"
            [width]="'100pt'"
            [height]="'280pt'"
            [fontSize]="'0.5rem'"
            [sizeQr]="'100'"
        ></app-fuec-format>
    </div>
</div>

<div class="bg-gradient">
    <div class="bg-photo">
        <!--Esta es la fotografía de fondo con transparencia-->
    </div>

    <div class="bg-gradient-2">
        <!--Esta es la gradación vetical para dar legibilidad al logo-->
    </div>

    <div class="logo-motion">
        <!--Este es el logo-->
        <a
            href="https://www.monitoringinnovation.com"
            target="_blank"
            class="brand-image"
            style="opacity: 0.8"
        >
            <span
                [inlineSVG]="'assets/images/motion-fuec-icon_negative.svg'"
                class="svg-icon svg-icon-md svg-icon-warning"
            ></span>
        </a>
    </div>

    <div>
        <form
            method="post"
            class="form grid-2col"
            [ngClass]="{hovered: isHovered}"
            style="display: grid"
            [formGroup]="fuecForm"
            (ngSubmit)="searchFuec()"
        >


            <div class="box-pdf" *ngIf="!notFound">
                <div
                    class="customBtn4"
                    (mouseenter)="onMouseEnter()"
                    (mouseleave)="onMouseLeave()"
                    (click)="navigateToRoute()"
                >
                    <img
                        src="../../../assets/icons/Navigation/Arrow-from-right Two.svg"
                        alt=""
                    />
                    <p>Volver</p>
                </div>
                <ng-container [ngTemplateOutlet]="contentPdf"></ng-container>
            </div>

            <div class="inputs-container grid-1col">
                <div class="input-div">
                    <div class="add-input">
                        <label for="nombre">
                            <h3 class="label-title gray1">
                                Numero de serie fuec
                            </h3>
                        </label>
                        <input
                            id="nombre"
                            type="text"
                            placeholder="Serie"
                            class="label-title gray1 i-select"
                            formControlName="fuec_series"
                        />
                    </div>
                </div>

                <div class="form-selections grid-2col">
                    <button class="btn4" type="submit" name="Ingresar">
                        Buscar
                    </button>
                    <button
                        class="btn3"
                        name="Ingresar"
                        *ngIf="!notFound"
                        (click)="openPDF()"
                    >
                        Descargar
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="copyright">
        <a class="dark white" href="#"
            ><span class="white90">&copy; {{ currentYear }} Copyright / </span>
            A.B Comercial LTDA</a
        >
    </div>
</div>
