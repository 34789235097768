<nav class="content-wrapper">
    <div class="grid-7col navbar-container">
        <a
            [routerLink]="['/']"
            routerLinkActive="selected-nav-item"
            [routerLinkActiveOptions]="{exact: true}"
            class="nav-item"
        >
            <svg
                version="1.1"
                id="listos"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <path
                    class="st0"
                    d="M19,48.2V100h-8V53.3L19,48.2z M81,48.2V100h8V53.3L81,48.2z M32,64v36h8V72h20v28h8V64H32z M50,0L0,31.8v19
                                    L50,19l50,31.8v-19L50,0z"
                />
            </svg>
            <h3>Inicio</h3>
        </a>
        <a
            class="nav-item"
            routerLinkActive="selected-nav-item"
            [routerLink]="['/fuec']"
        >
            <svg
                version="1.1"
                id="crear-fuec"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <path
                    class="st0"
                    d="M74,84H26v-8h48V84z M74,56H26v8h48V56z M58,36v8h16v-8H58z M58,16v8h16v-8H58z M58,0v8h24v84H18V48h-8v52h80V0
                                    H58z M42,19.3L29.3,32H42V19.3 M50,0v40H10L50,0L50,0z"
                />
            </svg>
            <h3>Crear FUEC</h3>
        </a>
        <a
            class="nav-item"
            routerLinkActive="selected-nav-item"
            [routerLink]="['/history']"
        >
            <svg
                version="1.1"
                id="historial"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <g>
                    <path
                        class="st0"
                        d="M16,8c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S16,3.6,16,8z M8,28c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8
                                        S12.4,28,8,28z M8,56c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S12.4,56,8,56z M8,84c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8
                                        S12.4,84,8,84z M100,4H24v8h76V4z M92,32H24v8h68V32z M84,60H24v8h60V60z M76,88H24v8h52V88z"
                    />
                </g>
            </svg>
            <h3>Ver Historial</h3>
        </a>
        <a
            class="nav-item"
            routerLinkActive="selected-nav-item"
            [routerLink]="['/responsibles']"
        >
            <svg
                version="1.1"
                id="grupo-personas"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <path
                    class="st0"
                    d="M24,8c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S19.6,8,24,8 M24,0C15.2,0,8,7.2,8,16s7.2,16,16,16
                                    c8.8,0,16-7.2,16-16S32.8,0,24,0L24,0z M72,22c4.4,0,8,3.6,8,8s-3.6,8-8,8c-4.4,0-8-3.6-8-8S67.6,22,72,22 M72,10c-11,0-20,9-20,20
                                    s9,20,20,20c11,0,20-9,20-20S83,10,72,10L72,10z M72,58c-23.2,0-42,18.8-42,42h12c0-16.5,13.5-30,30-30c12.8,0,23.7,8,28,19.3V68.7
                                    C92.6,62,82.8,58,72,58z M24,40c-9.1,0-17.5,3.2-24,8.5V60c5.5-7.3,14.2-12,24-12c8.6,0,16.3,3.6,21.8,9.4c2.3-1.4,4.8-2.7,7.4-3.8
                                    C46.2,45.3,35.7,40,24,40z"
                />
            </svg>
            <h3>Responsables</h3>
        </a>
        <a
            class="nav-item"
            routerLinkActive="selected-nav-item"
            [routerLink]="['/contractors']"
        >
            <svg
                version="1.1"
                id="persona"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <path
                    class="st0"
                    d="M50,12c6.6,0,12,5.4,12,12s-5.4,12-12,12s-12-5.4-12-12S43.4,12,50,12 M50,0C36.7,0,26,10.7,26,24
                                    s10.7,24,24,24s24-10.7,24-24S63.3,0,50,0L50,0z M50,56C24.4,56,3.3,75.2,0.4,100h12.1C15.4,81.9,31.1,68,50,68s34.6,13.9,37.5,32
                                    h12.1C96.7,75.2,75.6,56,50,56z"
                />
            </svg>
            <h3>Contratistas</h3>
        </a>
        <a
            class="nav-item"
            routerLinkActive="selected-nav-item"
            [routerLink]="['/routes']"
        >
            <svg
                version="1.1"
                id="rutas"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <path
                    class="st0"
                    d="M56,30H44v-8h12V30z M98,36c-1.2-4.3-3-8.3-5.3-12H75.9C74.9,10.6,63.7,0,50,0S25.1,10.6,24.1,24H7.3
                                    C5,27.7,3.2,31.7,2,36c-1.3,4.4-2,9.1-2,14c0,27.6,22.4,50,50,50s50-22.4,50-50C100,45.1,99.3,40.4,98,36z M50,92
                                    C26.8,92,8,73.2,8,50c0-4.9,0.8-9.6,2.4-14H35c-1.9-2.9-3-6.3-3-10c0-0.7,0-1.3,0.1-2c1-9,8.6-16,17.9-16s16.9,7,17.9,16
                                    c0.1,0.7,0.1,1.3,0.1,2c0,3.7-1.1,7.1-3,10h24.6c1.6,4.4,2.4,9.1,2.4,14C92,73.2,73.2,92,50,92z M80,49H20v8h60V49z M68,67H32v8h36
                                    V67z"
                />
            </svg>
            <h3>Ver Rutas</h3>
        </a>
        <a class="nav-item" (click)="logout()">
            <svg
                version="1.1"
                id="cerrar"
                class="nav-icon"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
            >
                <polygon
                    class="st0"
                    points="100.2,11.2 88.8,-0.2 50,38.7 11.2,-0.2 -0.2,11.2 38.7,50 -0.2,88.8 11.2,100.2 50,61.3 88.8,100.2
                                    100.2,88.8 61.3,50 "
                />
            </svg>
            <h3>Cerrar Sesión</h3>
        </a>
    </div>
</nav>
