<div class="flag-indicator"></div>
<p class="gra1" style="text-align: center; margin-bottom: 0">
    FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO PÚBLICO DE TRANSPORTE
    TERRESTRE AUTOMOTOR ESPECIAL
</p>

<form class="crear-fuec-form" [formGroup]="fuecForm" (ngSubmit)="createFuec()">
    <input
        type="hidden"
        class="label-title gray1 i-select"
        id="token"
        formControlName="token"
        value="{{ accountState.super_token }}"
    />
    <input
        type="hidden"
        class="label-title gray1 i-select"
        id="w_uname"
        formControlName="w_uname"
        value="{{ accountState.wialon_user_name }}"
    />
    <fieldset class="grid-2col grid inputs-container">
        <div class="input-div">
            <label for="numero-contrato">
                <h3 class="label-title gray1">Nº de contrato</h3>
            </label>
            <input
                id="numero-contrato"
                type="text"
                pattern="\d*"
                placeholder="Número de contrato"
                formControlName="contract_number"
                (input)="validateNumber($event)"
                title="Por favor ingrese solo números."
            />
        </div>

        <div class="input-div">
            <div class="add-input">
                <label for="contractors-list">
                    <h3 class="label-title gray1">Contratista</h3>
                </label>
                <div class="containerResp">
                    <input
                        type="text"
                        list="contractors_list"
                        class="label-title gray1 i-select"
                        placeholder="Contratistas"
                        formControlName="contractor_id"
                        (change)="onChangeContractorName()"
                        autocomplete="off"
                    />
                    <datalist
                        class="content-input"
                        id="contractors_list"
                        style="box-sizing: border-box"
                    >
                        <option
                            *ngFor="
                                let contractor of contractorState.contractors
                            "
                            [value]="contractor.id"
                        >
                            {{ contractor.fullname }}
                        </option>
                    </datalist>
                    <button
                        type="button"
                        value="agregar-contratista"
                        name="agregar-contratista"
                        class="btn4 s-btn"
                        (click)="openModalCreateContractor()"
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>

        <div class="input-div">
            <div class="add-input">
                <label for="responsables-list">
                    <h3 class="label-title gray1">Responsable</h3>
                </label>
                <div class="containerResp">
                    <mat-select
                        type="text"
                        multiple
                        class="i-select i-select-material"
                        [formControl]="responsibleState.responsables"
                        (selectionChange)="onChangeResponsable()"
                    >
                        <mat-option
                            *ngFor="
                                let responsibles of responsibleState.responsibles
                            "
                            [value]="responsibles"
                            >{{ responsibles.fullname }}</mat-option
                        >
                    </mat-select>
                    <button
                        type="button"
                        value="agregar-responsable"
                        name="agregar-responsable"
                        class="btn4 s-btn"
                        (click)="openModalCreateResponsible()"
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>

        <div class="input-div">
            <label for="objeto-contrato-list">
                <h3 class="label-title gray1">Objeto de contrato</h3>
            </label>
            <select
                id="objeto-contrato-list"
                type="text"
                formControlName="contract_object"
                (ngModelChange)="onChangeObjContract()"
            >
                <option value="1">
                    Contrato para transporte de estudiantes
                </option>
                <option value="2">
                    Contrato para transporte de empleados y/o contratistas
                </option>
                <option value="3">Contrato para transporte de turistas</option>
                <option value="4">
                    Contrato para un grupo específico de usuarios (Transporte de
                    particulares)
                </option>
                <option value="5">
                    Contrato para transporte de usuarios del servicio de salud
                </option>
                <option value="6">
                    Contrato para transporte empresarial para prensa, cine y
                    television
                </option>
            </select>
        </div>

        <div class="input-div" *ngIf="routeState.routes">
            <div class="add-input">
                <label for="rutas-list">
                    <h3 class="label-title gray1">Ruta</h3>
                </label>
                <div class="containerResp">
                    <input
                        type="text"
                        list="routes_list"
                        class="label-title gray1 i-select"
                        placeholder="Rutas"
                        id="rutas-list"
                        formControlName="route_id"
                        (change)="onChangeRoute()"
                        autocomplete="off"
                    />
                    <datalist
                        class="content-input"
                        id="routes_list"
                        style="box-sizing: border-box"
                    >
                        <option
                            *ngFor="let route of routeState.routes"
                            [value]="route.id"
                        >
                            {{ route.name }}
                        </option>
                    </datalist>
                    <button
                        type="button"
                        value="agregar-ruta"
                        name="agregar-ruta"
                        class="btn4 s-btn"
                        (click)="openModalCreateRoute()"
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>

        <div class="input-div">
            <label for="fecha-inicial">
                <h3 class="label-title gray1">Fecha inicial del contrato</h3>
            </label>
            <input
                id="fecha-inicial"
                type="date"
                placeholder="dd/mm/aaaa"
                [min]="minDate"
                [max]="maxDate"
                formControlName="date_start"
            />
        </div>

        <div class="input-div">
            <label for="fecha-vencimiento">
                <h3 class="label-title gray1">
                    Fecha de vencimiento del contrato
                </h3>
            </label>
            <input
                id="fecha-vencimiento"
                type="date"
                placeholder="dd/mm/aaaa"
                [min]="minDate"
                [max]="maxDate"
                formControlName="date_end"
                (change)="onChangeDateEnd()"
            />
        </div>

        <div class="input-div">
            <h3 class="label-title gray1">Tipo de convenio</h3>
            <div class="input-radio-div">
                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="convenio"
                        value="cv"
                        formControlName="agreement"
                    />
                    <label for="convenio">Convenio</label>
                </div>

                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="consorcio"
                        value="cs"
                        formControlName="agreement"
                    />
                    <label for="consorcio">Consorcio</label>
                </div>

                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="union-temporal"
                        value="ut"
                        formControlName="agreement"
                    />
                    <label for="union-temporal">Unión temporal</label>
                </div>

                <div class="input-radio">
                    <input
                        type="radio"
                        name="agreement"
                        id="no-aplica"
                        value="no-aplica"
                        formControlName="agreement"
                    />
                    <label for="no-aplica">No aplica</label>
                </div>
            </div>
        </div>
        <div class="input-div pt-4 hidden"></div>
        <ng-container *ngIf="agreement !== 'no-aplica'">
            <div class="input-div pt-4">
                <label for="union-temporal">Descripcion convenio</label>
                <input
                    type="text"
                    name="agreement_desc"
                    id="agreement_desc"
                    formControlName="agreement_desc"
                />
            </div>
        </ng-container>
    </fieldset>

    <fieldset>
        <div
            class="
                inputs-container
                table-container
                vehiculo-conductor-container
            "
            style="grid-template-columns: 1.5fr 0.5fr"
        >
            <div class="vehiculo-container">
                <div>
                    <h2 class="s-subtitle red1">Vehículo</h2>
                </div>
                <ng-container *ngIf="!unitState.wiaUnitsLoaded">
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </ng-container>
                <ng-container>
                    <div class="input-div">
                        <div class="inputVeh">
                            <input
                                list="vehiculos-list"
                                class="
                                    selectpickerVehiculos
                                    label-title
                                    gray1
                                    i-select
                                "
                                placeholder="Vehiculos"
                                formControlName="vehicle_id"
                                (change)="onChangeVehicle()"
                            />
                            <button
                                type="button"
                                class="s-btn btn-vehicle"
                                [ngClass]="{
                                    btn4: !viewState.isTemporaryVehicle,
                                    btn3: viewState.isTemporaryVehicle
                                }"
                                (click)="onCreateVehicle()"
                            >
                                {{
                                    viewState.isTemporaryVehicle
                                        ? 'Cancelar'
                                        : 'Añadir'
                                }}
                            </button>
                        </div>
                        <datalist
                            class="content-input"
                            id="vehiculos-list"
                            style="box-sizing: border-box"
                        >
                            <option
                                *ngFor="let unit of unitState.units"
                                [value]="unit.name"
                            >
                                {{ unit.name }}
                            </option>
                        </datalist>
                    </div>
                </ng-container>

                <div *ngIf="viewState.isTemporaryVehicle; else vehicleDetails">
                    <form
                        [formGroup]="vehicleForm"
                        (ngSubmit)="saveTemporaryCar()"
                    >
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Placa
                                <br />
                                <input
                                    type="text"
                                    placeholder="Ingrese la placa"
                                    formControlName="registration_plate"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Modelo (Línea)
                                <br />
                                <input
                                    type="text"
                                    placeholder="Ingrese el modelo"
                                    formControlName="year"
                                />
                            </h3>
                        </div>
                        <div
                            *ngIf="
                                vehicleForm.get('year')?.touched &&
                                vehicleForm.get('year')?.invalid
                            "
                            class="error"
                        >
                            <small
                                class="s-subtitle red1"
                                *ngIf="vehicleForm.get('year')?.errors?.['required']"
                                >El año es obligatorio.</small
                            >
                            <small
                                class="s-subtitle red1"
                                *ngIf="vehicleForm.get('year')?.errors?.['pattern']"
                                >Ingrese un año válido (1900-2099).</small
                            >
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Marca
                                <br />
                                <input
                                    type="text"
                                    placeholder="Ingrese la marca"
                                    formControlName="brand"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Número interno
                                <br />
                                <input
                                    type="text"
                                    placeholder="Ingrese el número interno"
                                    formControlName="vin"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Clase de vehículo
                                <br />
                                <input
                                    type="text"
                                    placeholder="Ingrese la clase del vehículo"
                                    formControlName="model"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Número operacion
                                <br />
                                <input
                                    type="text"
                                    placeholder="Ingrese el número de operación"
                                    formControlName="noperacion"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                SOAT
                                <br />
                                <input
                                    type="date"
                                    placeholder="Ingresa la fecha"
                                    formControlName="soat"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Revisión técnico mecánica
                                <br />
                                <input
                                    type="date"
                                    placeholder="Ingresa la fecha"
                                    formControlName="tecnomecanica"
                                />
                            </h3>
                        </div>
                        <div>
                            <h3 class="xs-subtitle gray3">
                                Tarjeta de operación
                                <br />
                                <input
                                    type="date"
                                    placeholder="Ingresa la fecha"
                                    formControlName="voperacion"
                                />
                            </h3>
                        </div>

                        <button type="submit" class="btn4 s-btn">Añadir</button>
                    </form>
                </div>

                <ng-template #vehicleDetails>
                    <div>
                        <h3 class="xs-subtitle gray3">
                            Placa
                            <br />
                            <span>{{
                                unitState.vehicle &&
                                unitState.vehicle.registration_plate
                                    ? unitState.vehicle.registration_plate
                                    : '---'
                            }}</span>
                        </h3>
                    </div>
                    <div>
                        <h3 class="xs-subtitle gray3">
                            Modelo (Línea)
                            <br />
                            <span>{{
                                unitState.vehicle && unitState.vehicle.year
                                    ? unitState.vehicle.year
                                    : '---'
                            }}</span>
                        </h3>
                    </div>
                    <div>
                        <h3 class="xs-subtitle gray3">
                            Marca
                            <br />
                            <span>{{
                                unitState.vehicle && unitState.vehicle.brand
                                    ? unitState.vehicle.brand
                                    : '---'
                            }}</span>
                        </h3>
                    </div>
                    <h3 class="xs-subtitle gray3">
                        Número Interno
                        <br />
                        <span>{{
                            unitState.vehicle && unitState.vehicle.vin
                                ? unitState.vehicle.vin
                                : '---'
                        }}</span>
                    </h3>
                    <h3 class="xs-subtitle gray3">
                        Clase de Vehículo
                        <br />
                        <span>{{
                            unitState.vehicle && unitState.vehicle.model
                                ? unitState.vehicle.model
                                : '---'
                        }}</span>
                    </h3>
                    <h3 class="xs-subtitle gray3">
                        SOAT
                        <br />
                        <span
                            [ngStyle]="{
                                color:
                                    unitState.vehicle && unitState.vehicle.soat
                                        ? this.unitState.soatDate > this.endDate
                                            ? ''
                                            : '#C6007E'
                                        : ''
                            }"
                            >{{
                                unitState.vehicle && unitState.vehicle.soat
                                    ? this.unitState.soatDate > this.endDate
                                        ? this.formatDate(
                                              this.unitState.soatDate
                                          )
                                        : this.formatDate(
                                              this.unitState.soatDate
                                          ) + ' no cumple'
                                    : '---'
                            }}</span
                        >
                    </h3>
                    <h3 class="xs-subtitle gray3">
                        Revisión técnico mecánica
                        <br />
                        <span
                            [ngStyle]="{
                                color:
                                    unitState.vehicle &&
                                    unitState.vehicle.tecnomecanica
                                        ? this.unitState.tecnomecanicaDate >
                                          this.endDate
                                            ? ''
                                            : '#C6007E'
                                        : ''
                            }"
                            >{{
                                unitState.vehicle &&
                                unitState.vehicle.tecnomecanica
                                    ? this.unitState.tecnomecanicaDate >
                                      this.endDate
                                        ? this.formatDate(
                                              this.unitState.tecnomecanicaDate
                                          )
                                        : this.formatDate(
                                              this.unitState.tecnomecanicaDate
                                          ) + ' no cumple'
                                    : '---'
                            }}</span
                        >
                    </h3>
                    <h3 class="xs-subtitle gray3">
                        Tarjeta de operación
                        <br />
                        <span
                            [ngStyle]="{
                                color:
                                    unitState.vehicle &&
                                    unitState.vehicle.voperacion
                                        ? this.unitState.voDate > this.endDate
                                            ? ''
                                            : '#C6007E'
                                        : ''
                            }"
                            >{{
                                unitState.vehicle &&
                                unitState.vehicle.voperacion
                                    ? this.unitState.voDate > this.endDate
                                        ? this.formatDate(this.unitState.voDate)
                                        : this.formatDate(
                                              this.unitState.voDate
                                          ) + ' no cumple'
                                    : '---'
                            }}</span
                        >
                    </h3>
                </ng-template>
            </div>
            <div class="grid inputs-container conductor-container">
                <div class="conductor-section">
                    <h2 class="s-subtitle red1">
                        Conductor
                        <br />
                    </h2>
                    <app-table
                        [model]="driversModel"
                        [removeCard]="false"
                        (paginatorEvent)="paginatorEvent($event)"
                        (pageSizeEvent)="pageSizeEvent($event)"
                        (searchEvent)="searchEvent($event)"
                        [hideIndex]="true"
                        [actionRow]="true"
                        (rowEvent)="selectDriver($event)"
                        [selectedRow]="driverState.drivers_selected"
                    ></app-table>
                </div>
                <div class="grid input-archivo firma-div">
                    <img
                        *ngIf="accountState.account"
                        src="{{
                            accountState.account.signature
                                ? 'data:image/jpg;base64,' +
                                  accountState.account.signature
                                : 'assets/img/signature.png'
                        }}"
                    />
                    <div>
                        <div class="input-div">
                            <button
                                type="submit"
                                class="btn3"
                                [type]="'submit'"
                            >
                                Crear FUEC
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<div style="display: none">
    <div class="PDF-FUEC" id="pdfTable" #pdfTable *ngIf="accountState.account">
        <app-fuec-format
            [logoAccount]="accountState.account.logo"
            [logoSignature]="accountState.account.signature"
            [fuec_series]="fuec_contract_number"
            [razonsocial]="accountState.account.razonsocial"
            [address]="accountState.account.address"
            [mobile]="accountState.account.mobile"
            [email]="accountState.account.email"
            [id_document]="accountState.account.id_document"
            [contract_number]="contract_number"
            [contractor_name]="contractorState.contractor_name"
            [contractor_nit]="contractorState.contractor_nit"
            [contract_object]="contract_object"
            [route_name]="routeState.route_name"
            [agreement]="agreement"
            [agreement_desc]="agreement_desc"
            [date_start]="date_start"
            [date_end]="date_end"
            [vehicle]="unitState.vehicle"
            [drivers_fuec]="driverState.drivers_fuec"
            [responsables]="responsibleState.responsables.value"
            [width]="'612px'"
            [height]="'1580px'"
            [fontSize]="'0.5rem'"
            [sizeQr]="'100'"
        ></app-fuec-format>
    </div>
</div>

<ng-template
    [ngIf]="
        viewState.show_modal === true && viewState.show_view_contractor === true
    "
>
    <app-modal [closeModal]="true" (closeFunction)="closeAllModals()">
        <app-contractor (emitEvent)="getContractors()"></app-contractor>
    </app-modal>
</ng-template>

<ng-template
    [ngIf]="viewState.show_modal === true && viewState.show_view_route === true"
>
    <app-modal [closeModal]="true" (closeFunction)="closeAllModals()">
        <app-route (emitEvent)="getRoutes()"></app-route>
    </app-modal>
</ng-template>

<ng-template
    [ngIf]="
        viewState.show_modal === true &&
        viewState.show_view_responsible === true
    "
>
    <app-modal [closeModal]="true" (closeFunction)="closeAllModals()">
        <app-responsible (emitEvent)="getResponsibles()"></app-responsible>
    </app-modal>
</ng-template>
